<template>
  <p class="editable"
    ref="editable"
    contenteditable
    v-on="listeners"
    @focus="onFocus"
  />
</template>

<script>
export default { 
  name: 'Editable',
  props: {
    value: {
      type: String,
      default: '',
    },
    shudSelected:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners() {
      return { ...this.$listeners, input: this.onInput };
    },    
  },
  mounted() {
    this.$refs.editable.innerText = this.value;
    this.$nextTick(() => this.$refs["editable"].focus())
    this.$nextTick(() => this.$refs["editable"].textContent)
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.innerText);
    },
    onFocus: function(){
      if (this.$props.shudSelected) {document.execCommand('selectAll',false,null)} 
    }
  },
};
</script>
<style scoped>
.editable {
  margin: 1px 1px;
  padding: 3px 3px;
  width: 100%;
  display: block;
}
.editable:hover {
  border-bottom: 1px solid #999;  
}
.editable:focus-visible {
  outline: none;
   border-bottom: 1px solid rgb(5, 177, 250);
}
</style>