<template>
  <div class="container-fluid">
       <b-form-group
            id="duration-lable"
            label="newText"
            label-for="duration"
            description=""
            >
            <b-form-input
            id="duration" 
            type="text"
            v-model="newMessages"
            >
            </b-form-input>
                                                
        </b-form-group> 
       <button class="ml-2" @click="sendMessage()">Надіслати</button>
       <ul v-if="messages.length">
           <li v-for="item, index in messages"  :key="index">{{item.time}}:{{item.text}}</li>
       </ul>
<br>
<br>
<br>
<button @click="$modal.show('task-card')">modal</button>
<modal name="task-card" :adaptive="true"  :resizable="true"  height="90%" width="80%" >
  </modal>
       <Mentionable
        :keys="['@']"
        :items="tributeOptions"
        offset="20"
        insert-space
        :omitKey="false"
        :limit="20"
        placement="top-start"
        filtering-disabled
        @open="getDefaultMetUser"        
        @search="getMetUser($event)"
        @apply="aplyMention"
        
    
    >
  <input type="text" class="write_msg w-100 mt-4" v-on:keyup.enter="send()" v-model="newMessages"  placeholder="Введіть повідомлення" />
        <template #item-@="{ item }">
          <div class="taget-user d-flex">
              <div class="taget-user-avatar">
                  <img class="option__image" :src="item.avatar" alt="No photo">
              </div>
              <div class="taget-user-desc">   
                <span class="taget-user-name">{{ item.name }}</span>
                <div class="desc">                           
                <span class="taget-user-department">{{item.department}}<span class="taget-user-position ml-2">({{item.position}})</span></span><br>
                <span class="danger missingStatus" v-if="item.missing">{{item.missingStatus}}</span>
                </div>
              </div>
          </div>
        </template>
        </Mentionable>





      <!-- <div>
        <tree-table
          class="table"
          :columns="columns"
          :table-data="data" 
          id="tree-table"
        >
        <template #headerTemplate="headerProps">
          <table-header v-bind="headerProps" />
        </template>

        <template #nodeTemplate="nodeProps">
            <node v-bind="nodeProps"
                :is-key-editable="isKeyEditable(nodeProps.depth)"                              
                :columns="columns"/>
        </template>
        <template #leafTemplate="leafProps">
          <leaf v-bind="leafProps"              
              :is-key-editable="isKeyEditable(leafProps.depth)" 
              :columns="columns"/>
      </template>

        </tree-table>
      </div> -->

  
  </div>
</template>

<script>
import TreeTable from 'vue-tree-table-component'
import TableHeader from '../components/tree-table/TableHeader.vue'
import Node from '../components/tree-table/Node.vue'
import Leaf from '../components/tree-table/Leaf.vue'
import { Mentionable } from 'vue-mention'
export default {
  name: 'testWS',
  components: {
       TreeTable, TableHeader, Node, Leaf,Mentionable
    },
  data () {
    return {
      mentionableLinks:[],
      amplyMention:false,
        loading:false,
      tributeOptions:[
        // {
        //   value: 'akryum',
        //   name: 'Guillaume',
        //   missing:'',
        //   avatar:"https://dfdfd"

        // },
        // {
        //   value: 'Eduardo778',
        //   firstName: 'Eduardo',
        //   missing:'20.02.2022'
        // },
        // {
        //   value: 'atinux',
        //   firstName: 'Sébastien',
        //   missing:''
        // },
      ],
      newMessages:"",     
      messages:[],
       editableFields: {
                    0: ['duration', 'active', 'owner', 'start_date'], 
                    1: ['duration', 'active', 'owner', 'start_date'],              
                },
      columns: [
        {label: 'Назва', id: 'name', width: '1.6', type:"text" }, {label: 'Тривалість', id: 'duration', width: '0.1', type:"number"}, {label: 'Дата початку', id: 'start_date', width: '0.4', type:"date"}, {label: 'Відповідальний', id: 'owner', width: '0.8', type:"selectloader"},  {label: '', id: 'active', width: '0.3', type:"swicher"}
        ],
      // columns: ['name', 'owner', 'start_date', 'duration', 'active'],
      data: [
       
      
      ],
      options: {
        headings: {
          name: 'Назва',         
          start_date: 'Дата початку',
          duration: 'Тривалість',
          active:'',
          owner:''
       },
      editableColumns:['name'],
      groupBy:'parrent',
      // sortable: ['name'],
      // filterable: ['name']
    }




    
    }
  },
  filters: {  
    
  },
  sockets: {
    connect() {
      console.log('socket connected')
    },
    customEmit(val) {
      console.log(`custom emit ${val}`)
    },
    new_messages(val) {
       this.messages.push(val);
       this.newMessages = "";
    }
  },
  methods: {
      send(){
      if(this.amplyMention){
          this.amplyMention = false;
          return
        }     
      let mention_arr = this.newMessages.match(/@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+/g);
      if (mention_arr && mention_arr.length) {
        mention_arr.forEach(element => {
        let  link = this.mentionableLinks.find(item => item.id == element.slice(1));
        this.newMessages = this.newMessages.replace(element, link.str)          
        });      
        }      
      this.newMessages = "";
    },
    sends(item){
      let user = this.newMessages.match(/@\w+/g);
      console.log(item)
    },
    aplyMention(e){
      this.amplyMention = true;      
      let str = {
        id: e.value,
        str: `[USER=${e.id}]${e.name}[/USER]`
      };
      this.mentionableLinks.push(str);  
      
    },
    getMetUser(event){
        //console.log(event);
        let search = event;
        let taskid = "5084";
        if (search.length){                          
            let url = 'getmentionableuser';
            this.search(search, url, taskid, this)                          
        }
              
    },
    search: _.debounce((search, url, taskid, vm) => {
        let DataRequest = {
            "name": search,
            "taskid": taskid                                      
          }          
               
        vm.makeRequest("/"+url, {"DataRequest": DataRequest})           
          .then(res => {
            vm.tributeOptions = res.data.users
            });
      }, 300),
     
    getDefaultMetUser(){
      let DataRequest = {
        taskId:"5084",
        name:"",       
      }
      this.makeRequest("/getmentionableuser", {DataRequest: DataRequest}, true)       
          .then(res => {  
            this.tributeOptions = res.data.users                 
          })
    },
    sendMessage(){
      this.$socket.client.emit('new_messages', this.newMessages);
    },
    editMessage(req){
      console.log(req)
    },
     isKeyEditable(depth){

        const editableFields = this.editableFields[depth] || []
        // console.log(editableFields)
        return (key) => editableFields.includes(key)
    },
   
   
  },
  created(){
    
  },
  computed: {
  

  },
  // watch:{              
  //      dispalayTask:function (val) {
  //     this.$refs.gnt.toogteFilter();
  //   },
  //   },
  mounted () { 

    
  }

}
</script>

<style>
.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
}
.taget-user-department {
    font-size: 11px;
    color: #aaa;
}
.taget-user-name {
    display: block;
    font-weight: bold;
}
.missingStatus {
    font-size: 11px;
}
.taget-user-avatar .option__image {
    display: inline-block;
    vertical-align: middle;
    max-height:45px;
    margin-right: 10px;
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 2;
    border-radius: 50%;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    cursor: pointer;
} 
</style>