import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import _ from 'lodash';
import VueI18n from 'vue-i18n'; Vue.use(VueI18n);
import Notifications from 'vue-notification'; Vue.use(Notifications);
import VueGoodTablePlugin from 'vue-good-table';
import vmodal from 'vue-js-modal';
import Multiselect from 'vue-multiselect'
import VueFormGenerator from "./components/vue-form-generator";
import fieldVueMultiSelect from "./components/vue-form-generator/fields/optional/fieldVueMultiSelect.vue";
import VueImg from 'v-img';
Vue.use(VueImg);
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
Vue.component('vue-form-generator', VueFormGenerator);
Vue.component('fieldVueMultiSelect', fieldVueMultiSelect);
Vue.component('multiselect', Multiselect);
import { BootstrapVue } from 'bootstrap-vue'

import FloatingVue from 'floating-vue'
Vue.use(FloatingVue)

const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)

import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyA4p_N6W_WpZiIyhHW-9NMgbH3Ip8XVF4c",
  authDomain: "request-f716a.firebaseapp.com",
  projectId: "request-f716a",
  storageBucket: "request-f716a.appspot.com",
  messagingSenderId: "775119149669",
  appId: "1:775119149669:web:9f8df9ed9fb8287b18848c"
};
firebase.initializeApp(firebaseConfig);

// import Popper from 'popper.js'
// Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false


// import VueSocketIOExt from 'vue-socket.io-extended';
// import { io } from 'socket.io-client';
// const socket = io('http://127.0.0.1:3000');
// Vue.use(VueSocketIOExt, socket);

Vue.use(BootstrapVue)
Vue.use(vmodal, { dialog: true })
Vue.use(VueGoodTablePlugin);
Vue.config.productionTip = false
import jQuery from "jquery";
const $ = jQuery; window.$ = $;
import VueModal from "vue2-modal"; 
Vue.use(VueModal);
import './assets/css/colors.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-good-table/dist/vue-good-table.css'
import './assets/css/bootstrap-datetimepicker.min.css'
import './assets/fonts/icomoon.css'//icons
import './assets/css/style.css'//custom styles of dashboards
import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'floating-vue/dist/style.css'

window.axios = require('axios');
window.axios.defaults.baseURL = 'https://bitrixerp.modern-expo.com/bitrixErp/hs/Bitrix'
//window.axios.defaults.baseURL = 'http://srv11/bitrixErp/hs/Bitrix'
//window.axios.defaults.headers.common['Cache-Control'] = 'no-cache'
window.axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
const names = require('../src/locales/locales.json');
const i18n = new VueI18n({
  locale: 'ua',
  messages: names
});

Vue.mixin({
  methods:{
    logout(){
      this.$store.commit('setUser',null);
      this.$store.commit('setToken',null);
      this.$store.commit('setGoogleAuth',false);
      this.$store.commit('setUserEmail',null);      
      this.$store.commit('setTokenERP', false);
      let dev = localStorage.getItem('dev');      
      let path = sessionStorage.getItem('redirectPath');           
       localStorage.clear();
      // localStorage.setItem('bitrix',Boolean(bitrix));
      // localStorage.setItem('bitrix', bitrix);
      localStorage.setItem('dev',dev);
      if (!_.isNull(path)){sessionStorage.setItem('redirectPath',path);}      
      this.$router.push({ name: 'auth'});
    },
    tokenError(){
      if (localStorage.getItem('loginError')) {
        let c = parseInt(localStorage.getItem('loginError'),10)
        localStorage.setItem('loginError', c++)                
      }
      else {localStorage.setItem('loginError', 1)}
    },
    gLogin(){
      if (parseInt(localStorage.getItem('loginError'),10) > 2) {
        return             
      }
      let data = {
        "user":this.$store.getters.getUser,
        "token":this.$store.getters.getToken,
        "email":this.$store.getters.getUserEmail,
        'is_google_auth':this.$store.getters.isGoogleAuth,
        'bitrix':this.$store.getters.getIsBitrix
      }
      if (this.$store.getters.getIsDev) {
        window.axios.defaults.baseURL = `https://erp-dev${this.$store.getters.getIsDev}.​modern-expo.​com/erp-dev${this.$store.getters.getIsDev}/hs/Bitrix`
      }; 
      return new Promise((resolve, reject) => {
        axios.post('/glogin', data)
        .then(response => {
          this.$store.commit('setLoading',false);
          if (response.status == 200){
            if(typeof response.data == '' || response.data.tokenERP == false ){
              // console.log(response.data);
              if (localStorage.getItem('loginError')) {
                let c = parseInt(localStorage.getItem('loginError'),10)
                localStorage.setItem('loginError', c++)                
              }
              else {localStorage.setItem('loginError', 1)}
              this.showNotification('custom-error','Error','Contact IT(1C) department');
            } else {
              this.$store.commit('setTokenERP', response.data.tokenERP);
              this.$store.commit('setCurrentUser', response.data.currentUser);
              return resolve(response);
            }
          } else {
            if (localStorage.getItem('loginError')) {
              let c = parseInt(localStorage.getItem('loginError'),10)
              localStorage.setItem('loginError', c++)                
            }
            else {localStorage.setItem('loginError', 1)}
            this.showNotification('custom-error','Error','Contact IT department');
            console.log('Something wrong but there is no error, status != 200');
          }
            
        })
        .catch(error => {
          this.$store.commit('setLoading',false);
          if ( (error.status != undefined) && ((error.response.status == 500)||(error.response.status == 401)) ){
            this.$router.push({ name: 'auth'});
            this.showNotification('custom-error','Error',this.$t('errors.500'));
          } else {
            this.showNotification('custom-error','Error','Contact IT department, server response failed');
            console.log(error);
          }
        });
      });

    },
    checkToken(token){     
      if (token){
        // console.log(token)
        this.$store.commit('setTokenERP', token); 
        localStorage.setItem('tokenERP',token);
      }
      else {
        this.tokenError()
        // console.log(token)
        this.logout();
      }
    },
    showNotification(gr, title, message, classCSS){
      this.$notify({
        group: gr,
        title: title,
        text: message,
        type: classCSS,
        duration: 10000
      })
    },
    isValid(some){
      if((some != null) && (some != undefined)&&(some != '') && (typeof(some) != "undefined")){
          return true
      } else {
          return false
      }
    },
    changeLanguage(lang){
      //this.$$refs.lang_btn.      
      localStorage.setItem('lang', lang);
      this.$i18n.locale = lang;
      //console.log(lang);
    },
    makeQuery: function(stringQuery,jsonData, loader = false){      
      if (this.$store.getters.getIsDev) {
        window.axios.defaults.baseURL = `https://erp-dev${this.$store.getters.getIsDev}.​modern-expo.​com/erp-dev${this.$store.getters.getIsDev}/hs/Bitrix`
      }; 
      if (parseInt(localStorage.getItem('loginError'),10) > 3) {
        return             
      }
      if (!this.$store.getters.getTokenERP || this.$store.getters.getTokenERP == "false"){
        this.logout();
      }
      if (loader) this.$store.commit('setLoading',true);
      
      jsonData['tokenERP'] = this.$store.getters.getTokenERP;      
      jsonData['user_email'] = store.getters.getUserEmail;
      jsonData['is_google_auth'] = store.getters.isGoogleAuth;
      return new Promise((resolve, reject) => {
        axios.post(stringQuery, jsonData)
        .then(response => {
          // console.log(response)
          this.$store.commit('setLoading',false);
          this.checkToken(response.data.tokenERP);
          if (response.status == 200){
            if(typeof response.data == 'string'){             
              this.showNotification('custom-error','Error','Contact IT(1C) department');
            }
            else if (!response.data.status && response.data.status == false){
              // console.log("status false")
                if (response.data.errorDescription){
                  this.showNotification('custom-error','Error', response.data.errorDescription);
                }
            } 
            else {
              return resolve(response);
            }
          } else {
            this.showNotification('custom-error','Error','Contact IT department');
            console.log('Something wrong but there is no error, status != 200');
          }
            
        })
        .catch(error => {
          console.log(error);
          this.$store.commit('setLoading',false);
          if ( (error.status != undefined) && ((error.response.status == 500)||(error.response.status == 401)) ){
            this.$router.push({ name: 'auth'});
            this.showNotification('custom-error','Error',this.$t('errors.500'));
          } else {
            this.showNotification('custom-error','Error','Contact IT department, server response failed');
            console.log(error);
          }
        });
      });
    },
    makeRequest: function(stringQuery,jsonData, loader = false){
      // console.log(`loader= ${loader}`)
      if (this.$store.getters.getIsDev) {
        window.axios.defaults.baseURL = `https://erp-dev${this.$store.getters.getIsDev}.​modern-expo.​com/erp-dev${this.$store.getters.getIsDev}/hs/Bitrix`
      };
      if (parseInt(localStorage.getItem('loginError'),10) > 3) {
        return             
      }
      if (!this.$store.getters.getTokenERP || this.$store.getters.getTokenERP == "false"){
        this.logout();
      }
      if (loader) this.$store.commit('setLoading',true);   
      jsonData['tokenERP'] = this.$store.getters.getTokenERP;      
      jsonData['user'] = store.getters.getUser;
      jsonData['token'] = store.getters.getToken;
      jsonData['entity_type'] = store.getters.getEntityType;
      jsonData['entity_id'] = store.getters.getEntityID;
      jsonData['lang'] = store.getters.getLang;
      jsonData['user_email'] = store.getters.getUserEmail;
      jsonData['is_google_auth'] = store.getters.isGoogleAuth;      
      // console.dir("jsonData = " + jsonData)
      return new Promise((resolve, reject) => {
        axios.post(stringQuery, jsonData)
        .then(response => {
          this.$store.commit('setLoading',false);
          this.checkToken(response.data.tokenERP);
          // console.log(response.data);
          if (response.status == 200){            
            if(typeof response.data == 'string'){
              console.log(response.data);
              this.showNotification('custom-error','Error','Contact IT(1C) department');
            } 
            else if (!response.data.status && response.data.status == false){
              if (response.data.errorDescription){
                this.showNotification('custom-error','Error', response.data.errorDescription);
              }
          } 
            else {
              return resolve(response);
            }
          } else {
            this.showNotification('custom-error','Error','Contact IT department');
            console.log('Something wrong but there is no error, status != 200');
          }
            
        })
        .catch(error => {
          // this.$store.commit('setLoading',false);
          if( !_.isNil(error.response) ){
            if ((error.response.status == 500)||(error.response.status == 401)){
              this.$router.push({ name: 'auth'});
              this.showNotification('custom-error','Error','Contact IT , server response failed');
            }
          } else {
            this.showNotification('custom-error','Error','Contact IT department, server response failed');
            console.log(error);
          }
        });
      });
    },
    getFielfOptions: function(stringQuery,jsonData){      
      // this.$store.commit('setLoading',true);
      if (this.$store.getters.getIsDev) {
        window.axios.defaults.baseURL = `https://erp-dev${this.$store.getters.getIsDev}.​modern-expo.​com/erp-dev${this.$store.getters.getIsDev}/hs/Bitrix`
      };  
      if (!this.$store.getters.getTokenERP){
        this.logout();
      }    
      jsonData['tokenERP'] = this.$store.getters.getTokenERP;     
      jsonData['user'] = store.getters.getUser;
      jsonData['token'] = store.getters.getToken;
      jsonData['entity_type'] = store.getters.getEntityType;
      jsonData['entity_id'] = store.getters.getEntityID;
      jsonData['lang'] = store.getters.getLang;
      jsonData['user_email'] = store.getters.getUserEmail;
      jsonData['is_google_auth'] = store.getters.isGoogleAuth;
      return new Promise((resolve, reject) => {
        axios.post(stringQuery, jsonData)
        .then(response => {
          // this.$store.commit('setLoading',false);
          this.checkToken(response.data.tokenERP);
          if (response.status == 200){
            if(typeof response.data == 'string'){
              console.log(response.data);
              this.showNotification('custom-error','Error','Contact IT(1C) department');
            }
            else if (!response.data.status && response.data.status == false){
              if (response.data.errorDescription){
                this.showNotification('custom-error','Error', response.data.errorDescription);
              }
          }  
            
            else {
              return resolve(response);
            }
          } else {
            this.showNotification('custom-error','Error','Contact IT department');
            console.log('Something wrong but there is no error, status != 200');
          }
            
        })
        .catch(error => {
          // this.$store.commit('setLoading',false);
          if ((error.response.status == 500)||(error.response.status == 401)){ 
            this.$router.push({ name: 'auth'});           
            this.showNotification('custom-error','Error','Contact IT , server response failed');            
          } else {
            this.showNotification('custom-error','Error','Contact IT department, server response failed');
            console.log(error);
          }
        });
      });
    },
    expandWidg(widget){
      if(widget.Char != undefined){
        $(document.getElementById(widget.Char)).find('[data-action="expand"] i').toggleClass('icon-expand2 icon-contract');
        $(document.getElementById(widget.Char)).closest('.card').toggleClass('card-fullscreen');
      }
      
      widget.dataIs = false;
      setTimeout(function(){
        widget.dataIs = true;
      },500);
    },      
    getValStatus(objGraf, string){
      let res = null;
      for (let i = 0; i < objGraf.status.length; i++) {
        if(string == objGraf.status[i].title){
          res = objGraf.status[i].value;
          break
        }
      }
      return res;
    },
    
   
    getValStatus(objGraf, string){
      let res = null;
      for (let i = 0; i < objGraf.status.length; i++) {
        if(string == objGraf.status[i].title){
          res = objGraf.status[i].value;
          break
        }
      }
      return res;
    },
    dtForFilters(dt){
      let d = new Date(dt);
      let year = d.getFullYear();
      let month = d.getMonth()+1;
      let day = d.getDate();
      if (day<10) { day = '0' + day }
      if (month<10) { month = '0' + month }
      
      return year + '' + month + '' + day;
    },
    
  }
});

//new version
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  // PerfectScrollbar,
  render: h => h(App),
  mounted(){
    if (sessionStorage.getItem('lang')){
      this.$i18n.locale = sessionStorage.getItem('lang');
    }
  },
})