<template>
<div class="tablesfields">
	<div class="tables" v-if="tData.length != 0">
		<div class="mobileTbl table-responsive">
			<table class="table table-hover mb-0" cellspacing="0">
				<thead>
					<tr>                                               
						<th v-for="(item,index) in tSchema.fields" :key="index" style="vertical-align: middle; font-weight:500">{{item.label}}</th>
						<th></th>																				
					</tr>
				</thead>                     
				<tbody>                              
					<tr class="marginTR" v-for="(item,index) in tData" :key="index" > 
						<td v-for="(itemR,indexR) in tSchema.fields" :key="indexR"> 
							{{chkValue(item[itemR.model])}}
						</td>
						<!-- <td v-for="(sitem,sindex) in item" :id="sindex" :key="sindex" :contenteditable="checkContentEditable(sindex)" v-on:keyup.enter.prevent="closeEditMode" v-on:keyup.esc.prevent="closeEditMode" v-on:blur="updateTableValue(sindex, $event, index)"  >
							{{chkValue(sitem)}}
						</td>						 -->
						<td><button class="btn btn-danger" v-on:click="cliarTablesRow(index)"><i style="color: #FFFFFF" class="icon-bin"></i></button></td>						
					</tr>                                                                                                           
				</tbody>
			</table>
		</div>
	</div>
	<div>
		<vue-form :schema="tSchema" :model="tModel" :options="tFormOptions" :isNewModel="true" :parrentModel="this.model" :parrentShema="this.model" ></vue-form>
		<button class="btn btn-success" style="float:right;" v-if="allFieldsInTables" v-on:click="addValueToTables()" ><i style="color: #FFFFFF" class="icon-plus2"></i> </button>
	</div>
		
	
</div>
	
</template>
<script>
import VueFormGenerator from "../components/../../../vue-form-generator";
import abstractField from "../abstractField";
export default {
	name: "tables",
    components: {     
      "vue-form": VueFormGenerator.component    
    },
	mixins: [abstractField],
	data() {
		return{					
				tData:[],
                tModel: {  
									   
       			},				
				tSchema: {
          			 fields: this.schema.item,
				},
				tFormOptions: {
					hastablesView:true,
					validateAfterLoad: false,
					validateAfterChanged: true,
					validateAsync: false,
					validateBeforeSubmit: true
				}		
        	}
		
	},	
	// provide: function () {
	// 	return {
	// 		test: this.testData
	// 	}
	// },	
	computed: {	
		allFieldsInTables: function () {
			return _.size(this.tModel) >= this.tSchema.fields.length ? true : false ;						
		}
	},
	methods: {
		generateCastomError(id){
			let el = this.searchElement(id, this)
			let o = {};
			o.error = "Це поле є обов'язковим!";
			o.field = el;
			this.vfg.errors[0] = o;               
			this.validate();	
		},
		searchElement(id,vm){   
            let el;        
              _.forEach(vm.vfg.fields, function(value) {
                        if (value.model == id) {
                            el = value;
                        }
                      }) 
            return el;                
        },		
		addFieldToModel(){
			let vm = this;
			_.forEach(this.schema.item, function(value, key) {
				if (value.type == "selectloader"){
					vm.$set(vm.tModel, value.model, [])
				}				
			});
			
		},
		chkValue(value){				
			if (_.isNumber(value) && value > 10000){			
 				return this.$moment(value).format("DD.MM.YYYY")
				}		
			if (_.isString(value) || _.isNumber(value)) return value
			else if (_.isArray(value)) return value[0].Title;
			else return value.Title;
				
		},
		updateTableValue(id, event, index){			
			let nv = _.trim(event.target.innerText) ;		
			this.tData[index][id] = nv;
			this.value = this.tData
		},
		closeEditMode(event){
			event.target.setAttribute("contenteditable", false);
			event.target.setAttribute("contenteditable", true);
		},
		checkContentEditable(id){
			let el = _.find(this.tSchema.fields, function(o) { return o.model == id;});
			if (el.editable) {return true}		
		},
		parsJson(){
			this.tSchema.fields = this.inputJson
		},		
		addValueToTables(){
			// this.validate();
			let rez = {}; 			
			_.forEach(this.tModel, function(value, key) {
				rez[key] = value;
			});
			this.tData.push(rez)
			this.cliarValue()
			this.value = this.tData
		},	
		cliarValue(){			
			// for (const item in this.tModel) {
			// 	this.tModel[item] = null
			// }	
			this.tModel = {};			
		},
		cliarTablesRow(id){
			this.tData.splice(id, 1)		
		}
	},
	created() {
		this.addFieldToModel();
	},
	
	mounted(){
		this.$root.$on('ErrorInChild', request => this.generateCastomError(request));
	}
};
</script>
