<template>
    <div>
    <modal :name="modalPropsid" :draggable="true" :adaptive="true" :scrollable="true" :minWidth="modalProps.minWidth" :resizable="true" :height="modalProps.hide">
        <div class="window-header p-2">
            <h2>{{schema.label}}</h2>
            <a  @click="closeModal" class="btn" style="position: absolute; right: 30px; top:20px;"><i class="icon icon-cross" style="font-size:18px"></i></a>
        </div>
        <div class="container" id="modalSelect">
            <v-select :options="options" label="Title" :multiple="schema.selectOptions.multiple" v-model="value"  @search="onSearch"   class="loader " >
                <template v-slot:no-options="{searching }">
                    <template v-if="searching">
                        Пошук не дав результату.
                    </template>
                    <em style="opacity: 0.5;" v-else>Введіть текст для пошуку</em>
                </template>
                <template #option="">
                    <h5 style="margin: 0"></h5>            
            </template>                     
            
        </v-select> 
        </div>
            <vue-good-table
            :columns="columns"
            :rows="options"
            :fixed-header="true"
            compactMode
            @on-row-click="onRowClick"
            @on-row-dblclick="onRowDoubleClick"
            :search-options="{
                enabled: true,
                placeholder: 'Пошук по таблиці',
            }"
            :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: false,
                nextLabel: 'наступна',
                prevLabel: 'попередня'
            }"
            
            class="p-2"
            >   
            </vue-good-table>
    </modal>
	<v-select :options="options" label="Title" :multiple="schema.selectOptions.multiple" v-model="value"  @search="onSearch"   class="loader " >
        <template v-slot:no-options="{searching }">
            <template v-if="searching">
                Пошук не дав результату.
            </template>
            <em style="opacity: 0.5;" v-else>Введіть текст для пошуку</em>
        </template>
        <template #option="options">
            <h5 style="margin: 0">{{options.Title}}</h5>
            <em v-if="options.description">{{options.description}}</em><br/>
            <em v-if="options.description2">{{options.description2}}</em>
     </template>
      <template slot="selected-option" slot-scope="option">
      <div class="selected d-center">
       <h5 style="margin: 0">{{option.Title}}</h5>
       <em v-if="option.description">{{option.description}}</em>
      </div>
    </template>
    <template #open-indicator="{ attributes }" v-if="schema.selectOptions.showTables">
      <span v-bind="attributes">
          <a @click="showModal" class="btn"><i class="icon icon-books" style="font-size:18px"></i></a>
      </span>
    </template>
        
    </v-select> 
    </div>
   
</template>

<script>
import abstractField from "../abstractField";

export default {
    props: {        
    },
	mixins: [
        abstractField
    ],    
    data() {
        return {             
             options:[ ],
            // functions: this.schema.function,
            modalProps:{
                hide:"auto",
                minWidth:850,
                id:this.schema.id                
            }, 
            selecteadRowInTables:null,           
            onSearchParams: this.schema.onSearchParams ,
            attributes: {
                'ref': 'openIndicators',
                'role': 'presentation',
                'class': 'vs__open-indicator',
                },           
            columns: [                    
                    {
                    label: 'Назва',
                    field: 'Title',          
                    }
                    
                ],
            
            }
        },
    methods: {
        onRowClick(params){
            // params.row - row object 
            // params.pageIndex - index of this row on the current page.
            // params.selected - if selection is enabled this argument 
            // indicates selected or not
            // params.event - click event
            // this.selecteadRowInTables = params.row
            // console.log("clik ")
        },
        onRowDoubleClick(params){
            if (!this.schema.selectOptions.multiple){
             this.value = params.row;          
            this.$modal.hide(this.modalPropsid)
            }
            else {
                let v = this.value
                let m = []
               //this.$set(this.model[params.row.id], params.row, {})
                v.push(params.row);
                this.value = v; 
               console.log("add")
            } 
        },
        showModal(){
            if (this.options.length <= 0  ){
                let url = this.schema.onSearch;                            
                let DataRequest = {
                    "ValueField": "#DATA",
                    "ID_Field": this.schema.model                    
                 }
                 var p = {}
                  _.forEach(this.schema.onSearchParams, function(value) {
                        _.forIn(value,function(el, key){
                        p[key] = el
                    })
                      })               
                this.$store.commit('setLoading',true);
                this.getFielfOptions("/"+url, {"DataRequest": DataRequest, "parametrs": p})
                .then(res => { 
               this.options = res.data.Data   
               this.$modal.show(this.modalPropsid) 
                this.$store.commit('setLoading',false);     
            });
            }
            else {this.$modal.show(this.modalPropsid)}
        },
        closeModal(){
            this.$modal.hide(this.modalPropsid)
        },

        getData(){
            function send() {
                if (!_.isEmpty(this.functions)  ){
                console.log("function  " + this.functions +"  colead vith values  " + this.values)
            }
            _.debounce(send, 300)()
            }
            
        },
        onSearch(search, loading) {
            if (!_.isNil(this.schema.onSearch) & search.length){
                let url = this.schema.onSearch
                let params = this.schema.onSearchParams
                loading(true);
                // console.log(url)
                // console.log("start searching")  
                 this.search(loading, search, url, params, this)                          
            }         
           },            
            search: _.debounce((loading, search, url, params, vm) => {
                let DataRequest = {
                    "ValueField": search,
                    "ID_Field": vm.schema.model                    
                 } 
                 var p = {}
                  _.forEach(params, function(value) {
                        _.forIn(value,function(el, key){
                        p[key] = el
                    })
                      })                 
                
                // let params = vm.onSearchParams;
               console.log("params = " + p)
                vm.getFielfOptions("/"+url, {"DataRequest": DataRequest, "parametrs": p})
           .then(res => { 
               vm.options = res.data.Data   
               loading(false);        
            //    console.log(res.data.Data)
            });
            }, 2000)

    },
    watch: {
      
    },
    computed: {
        modalPropsid(){
            return _.toString(this.schema.id)
        }
        
        // options() {
		// 	let values = this.schema.values;
		// 	if (typeof values == "function") {
		// 		return values.apply(this, [this.model, this.schema]);
		// 	} else {
		// 		return values;
		// 	}
		// },
    },
    mounted(){
        
    }


};
</script>

<style lang="scss">
.loader {
    padding-bottom: 20px;
    min-width: 100%;
}
 #modalSelect .vs__dropdown-menu{
    display: none;
}
</style>