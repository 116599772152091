import { _ } from 'core-js';
import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
// import firebase from "../firebase";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    state: {
        currentUser:null,
        token: null,
        tokenERP: null,
        user: null,
        userEmail:null,
        userRole:{},
        admin: 3,
        bitrix: false,
        dev: null,
        lang: null,
        entity_type:null,
        entity_id:null,
        googleAuth:false,
        isLoading: false,
        path:null,       
        nodataImg: require('./../assets/pict/noDataChart.svg'),
        groupBy:null,
        displayTask:null
               
    },
    modules: {
    },
    strict: false,
    // plugins: debug ? [createLogger()] : [],
    actions:{

    },
    getters:{
        isAdmin: state =>{
            if (state.userRole.admin) {return true}
            else if (!state.userRole.admin && localStorage.getItem('admin')){ 
                state.userRole.admin = localStorage.getItem('admin');
                return true       
            }
            else return false; 

        },
        isProductManager: state =>{
            if (state.userRole.product_manager) {return true}
            else if (!state.userRole.product_manager && localStorage.getItem('product_manager')){ 
                state.userRole.product_manager = localStorage.getItem('product_manager');
                return true       
            }
            else return false; 

        },
        getLoading: state => {
            return state.isLoading;
        },
        displayTask: state => {
            if (state.displayTask) {return state.displayTask}
            else if (!state.displayTask && localStorage.getItem('displayTask')){ 
                state.displayTask = localStorage.getItem('displayTask');       
            } 
            return state.displayTask;
        },
        groupBy: state => {
            if (state.groupBy) {return state.groupBy}
            else if (!state.groupBy && localStorage.getItem('groupBy')){ 
                state.groupBy = localStorage.getItem('groupBy');       
            } 
            return state.groupBy;
        },
        getUser: state => {
            if (state.user) {return state.user}
            else if (!state.user && localStorage.getItem('user_id')){ 
                state.user = localStorage.getItem('user_id');       
            } 
            return state.user;
        },
        getCurrentUser: state => {
            if (state.currentUser) {return state.currentUser}
            else if (!state.currentUser && localStorage.getItem('currentUser')){ 
                // state.currentUser = localStorage.getItem('currentUser');       
                state.currentUser = JSON.parse(localStorage.getItem('currentUser') )    
            } 
            return state.currentUser;
        },
        getToken: state => {         
            if (state.token) {return state.token}
            else if (!state.token && localStorage.getItem('token')){ 
                state.token = localStorage.getItem('token');       
            } 
            return state.token;
        },
        getTokenERP: state => {      
            if (!state.tokenERP && localStorage.getItem('tokenERP')){ 
                    if(localStorage.getItem('tokenERP') == "false" || localStorage.getItem('tokenERP') == "null" || localStorage.getItem('tokenERP') == "undefined"){
                        state.tokenERP = null;
                    }
                    else {state.tokenERP = localStorage.getItem('tokenERP'); }                                  
            } 
            return state.tokenERP;
        },
        getEntityType: state => {           
            if (state.entity_type) {return state.entity_type}
            else if (!state.entity_type && localStorage.getItem('entity_type')){ 
                state.entity_type = localStorage.getItem('entity_type');       
            } 
            return state.entity_type;
        },
        getEntityID: state => {            
            if (state.entity_id) {return state.entity_id}
            else if (!state.entity_id && localStorage.getItem('entity_id')){ 
                state.entity_id = localStorage.getItem('entity_id');       
            } 
            return state.entity_id;
        },
        getLang: state => {           
            if (state.lang) {return state.lang}
            else if (!state.lang && localStorage.getItem('lang')){ 
                state.lang = localStorage.getItem('lang');       
            } 
            return state.lang;
        },
        getIsDev: state => {          
            if (!state.dev && localStorage.getItem('dev')){ 
                if (localStorage.getItem('dev') == "null"){state.dev == null}
                else {state.dev = localStorage.getItem('dev')}                       
            } 
            return state.dev;
        },
        getIsBitrix: state => {
            if (state.bitrix) {return state.bitrix}
            else if (!state.bitrix && localStorage.getItem('bitrix')){
                if (localStorage.getItem('bitrix') == "true")  {state.bitrix = true}
                else {state.bitrix = false}      
            } 
            return state.bitrix  
            
        },
        isGoogleAuth: state => {            
            if (state.googleAuth) {return state.googleAuth}
            else if (!state.googleAuth && localStorage.getItem('is_google_auth')){
                if (localStorage.getItem('is_google_auth') == "true")  {state.googleAuth = true}
                else {state.googleAuth = false}      
            } 
            return state.googleAuth        
        },
        getUserEmail: state => {            
            if (state.userEmail) {return state.userEmail}
            else if (!state.userEmail && localStorage.getItem('user_email')){         
                state.userEmail = localStorage.getItem('user_email')
            } 
            return state.userEmail        
        },
    },
    mutations: {
        setLoading(state, load) {
            state.isLoading = load;
        },
        setUser(state, usr){
            state.user = usr;
            localStorage.setItem('user_id',usr);
        },
        setUserRole(state, data){            
            state.userRole[data.role] = data.value;
            localStorage.setItem(data.role, data.value);
        },
        displayTask(state, data){
            state.displayTask = data;
            localStorage.setItem('displayTask',data);
        },
        groupBy(state, data){
            state.groupBy = data;
            localStorage.setItem('groupBy',data);
        },
        setToken(state, tokens) {
            state.token = tokens;
            localStorage.setItem('token',tokens);
        },
        setTokenERP(state, tokens) {
            if (tokens == "false") {state.tokenERP = null}
            if (tokens == "null") {state.tokenERP = null}
            if (tokens == "undefined") {state.tokenERP = null}
            else {state.tokenERP = tokens;}
            localStorage.setItem('tokenERP',state.tokenERP);
        },
        setCurrentUser(state, currentUser) {
            state.currentUser = currentUser;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
        },
        setEntityType(state, entity_type) {
            state.entity_type = entity_type;
            localStorage.setItem('entity_type',entity_type);
        },
        setEntityID(state, entity_id) {
            state.entity_id = entity_id;
            localStorage.setItem('entity_id',entity_id);
        },
        setLang(state, lang) {
            state.lang = lang;
            localStorage.setItem('lang',lang);
        },
        setIsDev(state, val) {
            state.dev = val;
            localStorage.setItem('dev',val);
        },
        setGoogleAuth(state, val) {
            state.googleAuth = val;
            localStorage.setItem('is_google_auth',val);
        },
        setUserEmail(state, val) {
            state.userEmail = val;
            localStorage.setItem('user_email',val);
        },
        setPathTo(state, val) {
            state.path = val;
        },
        setIsBitrix(state, val) {
            state.bitrix = val;
            localStorage.setItem('bitrix',val);
        },
    },
    actions: {
        setUserRole(context, data) {            
            if (_.isArray(data.roles)) {
                data.roles.forEach(role => {
                    context.commit('setUserRole', {
                        'role':role.Title,
                        'value': true
                    })
                });
            }   
        }
      }

})