var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal',{attrs:{"name":_vm.modalPropsid,"draggable":true,"adaptive":true,"scrollable":true,"minWidth":_vm.modalProps.minWidth,"resizable":true,"height":_vm.modalProps.hide}},[_c('div',{staticClass:"window-header p-2"},[_c('h2',[_vm._v(_vm._s(_vm.schema.label))]),_c('a',{staticClass:"btn",staticStyle:{"position":"absolute","right":"30px","top":"20px"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"icon icon-cross",staticStyle:{"font-size":"18px"}})])]),_c('div',{staticClass:"container",attrs:{"id":"modalSelect"}},[_c('v-select',{staticClass:"loader ",attrs:{"options":_vm.options,"label":"Title","multiple":_vm.schema.selectOptions.multiple},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Пошук не дав результату. ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Введіть текст для пошуку")])]}},{key:"option",fn:function(){return [_c('h5',{staticStyle:{"margin":"0"}})]},proxy:true}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('vue-good-table',{staticClass:"p-2",attrs:{"columns":_vm.columns,"rows":_vm.options,"fixed-header":true,"compactMode":"","search-options":{
                enabled: true,
                placeholder: 'Пошук по таблиці',
            },"pagination-options":{
                enabled: true,
                perPageDropdownEnabled: false,
                nextLabel: 'наступна',
                prevLabel: 'попередня'
            }},on:{"on-row-click":_vm.onRowClick,"on-row-dblclick":_vm.onRowDoubleClick}})],1),_c('v-select',{staticClass:"loader ",attrs:{"options":_vm.options,"label":"Title","multiple":_vm.schema.selectOptions.multiple},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
            var searching = ref.searching;
return [(searching)?[_vm._v(" Пошук не дав результату. ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Введіть текст для пошуку")])]}},{key:"option",fn:function(options){return [_c('h5',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(options.Title))]),(options.description)?_c('em',[_vm._v(_vm._s(options.description))]):_vm._e(),_c('br'),(options.description2)?_c('em',[_vm._v(_vm._s(options.description2))]):_vm._e()]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_c('h5',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(option.Title))]),(option.description)?_c('em',[_vm._v(_vm._s(option.description))]):_vm._e()])]}},(_vm.schema.selectOptions.showTables)?{key:"open-indicator",fn:function(ref){
            var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('a',{staticClass:"btn",on:{"click":_vm.showModal}},[_c('i',{staticClass:"icon icon-books",staticStyle:{"font-size":"18px"}})])])]}}:null],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }