<template>
  <div class="container-fluid">
    <nav class="">
      <div class="d-flex justify-content-between" >
        <div class="d-flex">
           <div class="t-menu">
              <span @click="$refs.gnt.expandTask();" class="ex-but">+</span>            
              <span type="button" @click="$refs.gnt.collapseTask();" class="ex-but">-</span>            
          </div>
           <div class="t-menu">
            <b-form-radio-group
              id="checkbox-group-1"
              v-model="displayOptionst.displayTask"
              :options="displayTaskOptions"              
              name="taskFilter"
              @change="filterTaskBy()"
            ></b-form-radio-group>
          </div>
           <div class="t-menu">
            <b-form-radio-group
              id="checkbox-group-1"
              v-model="displayOptionst.groupBy"
              :options="groupTaskOptions"              
              name="groupFilter"
              @change="groupTaskBy()"
            ></b-form-radio-group>
          </div>

        </div>
        <div class="d-flex">
          <div class="input-group" role="group" aria-label="Basic">
            <button type="button" @click="goTooday()" class="btn btn-outline-secondary btn-sm">Tooday</button>
            <select v-model="zoomLevel" @change="setZoomLevel()">
              <option v-for="el in zoomOptions" v-bind:value="el.value" :key="el.value">{{el.text}}</option>
           </select>           
            <button type="button" @click="zoomIn()" class="btn  btn-outline-secondary btn-sm">+</button>            
            <button type="button" @click="zoomOut()" class="btn  btn-outline-secondary btn-sm">-</button>
        </div>
        </div>
        <div  class="d-flex">
           <div class="mx-2 t-menu">
             <a  href="#" @click.prevent="showSettingsBar = !showSettingsBar">
              <svg data-v-e55d78e2="" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="spinner "><circle data-v-e55d78e2="" cx="12" cy="12" r="3"></circle><path data-v-e55d78e2="" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
            </a>
          </div>

        </div>    
      </div>
    </nav>
    
<vue-modal :on-close="closeModal" name="dell-task">
  <h2 slot="header">Підтвердіть видалення задачі</h2>
  <div class="d-flex flex-row-reverse">
      <button class="ml-2 btn-sm btn-outline-danger " @click="deleteTask(form.id)" data-toggle="button" aria-pressed="false">Видалити</button>
  </div>       
</vue-modal>

<!-- Start task edit form  -->
 <modal name="task-card" :adaptive="true"  :resizable="true"  height="90%" width="80%" @closed="onModalTaskFormClosed">
   <!-- start-dropdown-menu -->
   <transition name="task-edit-menu" >
     <div class="dropdown__menu" v-bind:class="{ active: showEditMenu }" v-on-clickaway="closeMenu" v-if="showEditMenu">
        <ul class="dropdown__menu-nav">
          <li class="dropdown__menu-item">
            <a href="#" class="dropdown__menu-link" title="Account" @click="showTaskTemplate(), showEditMenu = false">
              <input type="checkbox" id="checkbox" v-model="showTemplateBlock">
              <div class="checkItem">                
                <div class="dropdown__menu-text">Шаблони задач </div>                
              </div>
              <div class="dropdown__menu-svg">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"  width="21" height="21" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M982 4617 l-422 -422 0 -1503 c0 -1441 -1 -1502 -18 -1502 -36 0
                -143 -43 -202 -81 -242 -156 -326 -454 -200 -714 63 -132 168 -225 319 -284
                l66 -26 1826 -3 1827 -2 288 141 c159 77 336 163 392 191 110 54 148 90 173
                165 27 82 -17 204 -89 246 -21 12 -185 95 -367 183 l-330 161 -3 397 c-1 218
                0 396 3 396 3 0 47 -21 97 -46 l91 -46 209 210 210 209 -52 102 -51 103 39 90
                39 91 106 34 107 34 0 299 0 299 -107 34 -106 34 -39 91 -39 90 51 103 52 102
                -210 209 -209 210 -91 -46 c-50 -25 -94 -46 -97 -46 -3 0 -5 207 -5 460 l0
                460 -1418 0 -1417 0 -423 -423z m3098 -92 c0 -337 -1 -355 -18 -346 -11 6 -29
                48 -50 115 l-32 106 -300 0 -299 0 -33 -106 -34 -107 -91 -39 -92 -39 -102 52
                -101 52 -210 -210 -210 -210 52 -102 51 -103 -39 -91 -39 -91 -107 -34 -106
                -33 0 -299 0 -299 106 -33 107 -34 39 -91 39 -91 -51 -103 -52 -102 210 -209
                209 -210 102 52 103 51 91 -39 91 -39 34 -107 33 -106 299 0 300 0 32 106 c21
                67 39 109 50 115 17 9 18 -9 18 -346 l0 -355 -1680 0 -1680 0 0 1440 0 1440
                400 0 400 0 0 400 0 400 1280 0 1280 0 0 -355z m-2720 -25 l0 -260 -262 0
                -263 0 260 260 c143 143 261 260 262 260 2 0 3 -117 3 -260z m2532 -356 l28
                -90 73 -23 c39 -13 108 -41 151 -63 l80 -40 88 43 89 43 126 -127 127 -126
                -43 -89 -43 -88 40 -80 c22 -44 50 -112 62 -152 l22 -71 91 -28 92 -29 0 -184
                0 -184 -92 -29 -91 -28 -22 -71 c-12 -40 -40 -108 -62 -152 l-40 -80 43 -88
                43 -89 -127 -126 -126 -127 -89 43 -88 43 -80 -40 c-43 -22 -112 -50 -151 -63
                l-73 -23 -28 -90 -28 -91 -184 0 -184 0 -29 92 -28 91 -67 21 c-37 11 -105 39
                -152 61 l-85 42 -88 -43 -88 -43 -126 127 -127 126 43 89 43 88 -40 80 c-22
                43 -50 112 -63 152 l-23 72 -91 28 -90 28 0 184 0 184 90 28 91 28 23 72 c13
                40 41 109 63 152 l40 80 -43 88 -43 89 127 126 126 127 88 -43 88 -43 85 42
                c47 22 115 50 152 61 l68 21 21 71 c12 39 25 82 30 94 l9 23 182 -2 182 -3 28
                -91z m-3012 -3505 l0 -401 -167 4 c-164 3 -169 4 -235 35 -93 44 -151 100
                -196 191 -35 72 -37 81 -37 171 0 87 3 102 32 163 40 85 97 147 172 186 81 44
                115 50 284 51 l147 1 0 -401z m3200 241 l0 -160 -1520 0 -1520 0 0 160 0 160
                1520 0 1520 0 0 -160z m523 -64 c275 -135 293 -149 269 -200 -8 -19 -73 -55
                -269 -152 -142 -69 -282 -137 -310 -150 l-53 -24 0 350 0 350 53 -24 c28 -13
                168 -81 310 -150z m-523 -416 l0 -160 -1520 0 -1520 0 0 160 0 160 1520 0
                1520 0 0 -160z"/>
                <path d="M3120 4640 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                <path d="M3440 4640 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                <path d="M3760 4640 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                <path d="M1040 3907 c-19 -6 -58 -34 -85 -62 -71 -70 -91 -145 -63 -239 16
                -55 99 -138 154 -154 30 -9 163 -12 473 -12 l432 0 24 -25 c33 -32 33 -78 0
                -110 l-24 -25 -232 0 c-153 0 -246 -4 -273 -12 -55 -16 -138 -99 -154 -154
                -28 -94 -8 -169 63 -239 63 -64 100 -75 244 -75 105 0 113 -1 136 -25 33 -32
                33 -78 0 -110 l-24 -25 -312 0 c-215 0 -324 -4 -353 -12 -86 -26 -166 -136
                -166 -228 0 -92 80 -202 166 -228 30 -9 194 -12 618 -12 l576 0 0 80 0 80
                -575 0 -576 0 -24 25 c-33 32 -33 78 0 110 l24 25 312 0 c215 0 324 4 353 12
                55 16 138 99 154 154 28 94 8 169 -63 239 -63 64 -100 75 -244 75 -105 0 -113
                1 -136 25 -33 32 -33 78 0 110 l24 25 232 0 c153 0 246 4 273 12 86 26 166
                136 166 228 0 92 -80 202 -166 228 -30 9 -163 12 -473 12 l-432 0 -24 25 c-16
                15 -25 36 -25 55 0 19 9 40 25 55 l24 25 616 0 615 0 0 80 0 80 -622 -1 c-424
                -1 -634 -5 -658 -12z"/>
                <path d="M880 1760 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                <path d="M1200 1760 l0 -80 560 0 560 0 0 80 0 80 -560 0 -560 0 0 -80z"/>
                <path d="M880 1440 l0 -80 720 0 720 0 0 80 0 80 -720 0 -720 0 0 -80z"/>
                <path d="M2480 1440 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                <path d="M2800 1440 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                <path d="M3120 1440 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                <path d="M3516 3904 c-350 -63 -639 -356 -701 -709 -104 -600 417 -1125 1013
                -1021 479 84 801 540 718 1015 -85 484 -544 803 -1030 715z m379 -178 c113
                -36 198 -89 290 -181 91 -91 144 -175 182 -290 23 -71 26 -96 26 -215 0 -119
                -3 -144 -26 -215 -38 -115 -91 -199 -182 -290 -91 -91 -175 -144 -290 -182
                -71 -23 -96 -26 -215 -26 -119 0 -144 3 -215 26 -114 38 -198 90 -290 182 -91
                91 -144 175 -182 290 -23 71 -26 96 -26 215 -1 118 3 145 25 212 40 120 92
                204 182 293 114 113 237 179 380 205 89 16 251 5 341 -24z"/>
                <path d="M3586 3430 c-63 -16 -153 -70 -197 -117 -22 -24 -55 -74 -72 -111
                -29 -61 -32 -76 -32 -163 0 -90 2 -99 37 -171 45 -91 103 -147 196 -191 61
                -29 76 -32 162 -32 86 0 101 3 162 32 93 44 151 100 196 191 35 72 37 81 37
                172 0 91 -2 100 -37 172 -68 136 -188 217 -336 224 -42 2 -94 -1 -116 -6z
                m168 -162 c86 -26 166 -136 166 -228 0 -124 -116 -240 -240 -240 -63 0 -114
                23 -165 75 -102 101 -102 229 0 330 70 71 145 90 239 63z"/>
                </g>
                </svg>
              </div>
              
              
            </a>
          </li>
          <li class="dropdown__menu-item" v-if="canDeleteTask()">
            <a href="#" class="dropdown__menu-link" title="Personal info" @click="showDellDialog(), showEditMenu=false" >
              <div class="dropdown__menu-text " style="padding-left: 13px;">Видалити задачу</div>
              <div class="dropdown__menu-svg">
                <svg viewBox="0 0 576 512">
                  <path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path>
                </svg>
              </div>              
            </a>
          </li>                
          <li class="dropdown__menu-item">
            <a href="#" class="dropdown__menu-link" title="Personal info" @click="exitwisautSave()">
              <div class="dropdown__menu-text " style="padding-left: 13px;">Вийти без збереження</div>
              <div class="dropdown__menu-svg">
                <svg viewBox="0 0 576 512">
                  <path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path>
                </svg>
              </div>              
            </a>
          </li>                
        </ul>
      </div>   
   </transition>
 <!-- and-dropdown-menu -->
   <div class="task-view">
     <div class="header-wrapper ">
       <div class="task-view-header__container d-flex justify-content-between">
         <div class="task-title-wrapper">           
            <Editable v-model="form.text" :shudSelected="this.form.$new"/>            
         </div>
         <div class="active-action-bar ">
            <div class="task-menu-button">
              <button v-tooltip="'Додатково'" class="button-style-block" @click="showEditMenu = !showEditMenu" >
                <span class="button-icon button-icon--left"><svg icon="" wrike-icon-more="" class="" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" role="presentation"><path d="M2.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path></svg></span>
              </button>
            </div>
            <div class="task-menu-button">
              <button v-tooltip="'Вийти без збереження'"  class="button-style-block" @click="exitwisautSave()">
                <span class="button-icon button-icon--left"><svg class="task-menu-button__svg" viewBox="0 0 576 512">
                    <path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path>
                  </svg>
                </span>
              </button>
            </div>
            <div class="task-menu-button">
              <button v-tooltip="'Зберегти та вийти'"  class="button-style-block" @click="saveAndExit()">
                <span class="button-icon button-icon--left"><svg icon="" wrike-icon-close="" class="_ngcontent-ynj-90" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" role="presentation"><path d="M7.29293 8.00004L2.14648 2.85359L2.85359 2.14648L8.00004 7.29293L13.1465 2.14648L13.8536 2.85359L8.70714 8.00004L13.8536 13.1465L13.1465 13.8536L8.00004 8.70714L2.85359 13.8536L2.14648 13.1465L7.29293 8.00004Z"></path></svg></span>
              </button>
            </div>
          </div>

        </div>          
      </div>
       <!-- and header-wrapper -->
      <div class="task-main-content" id="task-main-content" >
          <div class="task-fields  d-flex justify-content-between">
            <div class="left-column d-flex ">
                <div class="task-current-status mr-2">
                  <multiselect
                    class="task-status" 
                    v-model="form.stage"                   
                    :options="taskAvailableStages" 
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="false"
                    label="title"
                    track-by="id"
                    :option-height="20"
                    :custom-label="customLabel" 
                    :show-labels="false"
                    >
                    <template slot="singleLabel" slot-scope="props" >
                      <span class="option__desc" >
                        <span class="option__title">{{ props.option.title }}</span>
                        </span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="option__desc"><span class="option__title">{{ props.option.title }}</span></div>
                    </template>
                  </multiselect>
            </div>    
            <div class="task-owner-wraper mr-2">
              <multiselect
                  class="task-owner " 
                  v-model="form.owner"
                  @input="onUserSelectead" 
                  :options="formUserOptions" 
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  label="text"
                  track-by="id"
                  :option-height="20"
                  :custom-label="customLabel" 
                  :show-labels="false"
                  @search-change="findUser"
                  >
                  <template slot="singleLabel" slot-scope="props"><span class="select-img-wraper"><img class="option__image" :src="props.option.avatar" alt="No photo"></span><span class="option__desc"><span class="option__title">{{ props.option.text }}</span></span></template>
                  <template slot="option" slot-scope="props"><img class="option__image" :src="props.option.avatar" alt="No photo">
                      <div class="option__desc"><span class="option__title">{{ props.option.text }}</span></div>
                  </template>
                </multiselect>
            </div>
            <div class="custom-calendar-wraper">
              <input class="match-worktime-input" type="checkbox" v-tooltip="'Враховувати робочий календар'" id="has-custom-calendar" value="true" v-model="form.matchWorkTime" @change="changeMatchWorkTime()">
              
            </div>
            <div class="inline-calendar mt-2">
                <vc-date-picker
                  mode="dateTime"
                  class="task-date"             
                  v-model="form.start_date"
                  :minute-increment="15" 
                  is-required 
                  :attributes='attributes' 
                  :firstDayOfWeek='calendarOptions.firstDayOfWeek'  
                  @dayclick='calendarDayClicked'
                  :disabled-dates='calendarDisabledDates'
                  ref='cl'
                  is24hr
                  >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="calendar-input-wraper d-flex">
                        <span class="calendar-input-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            class="fill-current"
                          >
                            <path
                              d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                            ></path>
                          </svg>
                        </span>               
                    <input              
                      class="bg-white  px-2 py-1 task-date-input "
                      :value="inputValue"
                      v-on="inputEvents"              
                    />
                    </div>
                  </template>
                  <template #day-popover="{attributes}">
                  <div>                                                                                  
                    <popover-row
                      v-for="attr, key in attributes[0].customData"
                      :key="key"
                      :attribute="attr">
                      {{ attr.text}} <b-badge pill variant="warning" class="ml-2">{{ attr.duration}}</b-badge>
                    </popover-row>
                    </div>
                  </template>
                </vc-date-picker>
            </div>            
            <div class="task-duration bg-white pt-2 ">
              <input type="number" v-model="form.duration" v-tooltip="'Тривалість'">
              <span>(год.)</span>
            </div>
            <div class="task-type-wraper">
               <multiselect 
                  class="task-type"
                  v-model="form.type"                                        
                  :options="formOption.type" 
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  >                                       
              </multiselect>
            </div>
            <div class="castom-field-swicher-container  ">
              <span class="castom-field-swicher mt-2" @click="showCastomFields = !showCastomFields">{{countCastomFields}} полів</span>
            </div>
            <div class="template-swicher-container">
              <div class="task-menu-button  ml-2">
                <button v-tooltip="'Шаблони задач'" class="button-style-block" @click="showTaskTemplate()" :disabled="!form.hasSubtaskTemplate" >
                  <span class="button-icon button-icon--left"><svg version="1.0" xmlns="http://www.w3.org/2000/svg"  width="21" height="21" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M982 4617 l-422 -422 0 -1503 c0 -1441 -1 -1502 -18 -1502 -36 0
                    -143 -43 -202 -81 -242 -156 -326 -454 -200 -714 63 -132 168 -225 319 -284
                    l66 -26 1826 -3 1827 -2 288 141 c159 77 336 163 392 191 110 54 148 90 173
                    165 27 82 -17 204 -89 246 -21 12 -185 95 -367 183 l-330 161 -3 397 c-1 218
                    0 396 3 396 3 0 47 -21 97 -46 l91 -46 209 210 210 209 -52 102 -51 103 39 90
                    39 91 106 34 107 34 0 299 0 299 -107 34 -106 34 -39 91 -39 90 51 103 52 102
                    -210 209 -209 210 -91 -46 c-50 -25 -94 -46 -97 -46 -3 0 -5 207 -5 460 l0
                    460 -1418 0 -1417 0 -423 -423z m3098 -92 c0 -337 -1 -355 -18 -346 -11 6 -29
                    48 -50 115 l-32 106 -300 0 -299 0 -33 -106 -34 -107 -91 -39 -92 -39 -102 52
                    -101 52 -210 -210 -210 -210 52 -102 51 -103 -39 -91 -39 -91 -107 -34 -106
                    -33 0 -299 0 -299 106 -33 107 -34 39 -91 39 -91 -51 -103 -52 -102 210 -209
                    209 -210 102 52 103 51 91 -39 91 -39 34 -107 33 -106 299 0 300 0 32 106 c21
                    67 39 109 50 115 17 9 18 -9 18 -346 l0 -355 -1680 0 -1680 0 0 1440 0 1440
                    400 0 400 0 0 400 0 400 1280 0 1280 0 0 -355z m-2720 -25 l0 -260 -262 0
                    -263 0 260 260 c143 143 261 260 262 260 2 0 3 -117 3 -260z m2532 -356 l28
                    -90 73 -23 c39 -13 108 -41 151 -63 l80 -40 88 43 89 43 126 -127 127 -126
                    -43 -89 -43 -88 40 -80 c22 -44 50 -112 62 -152 l22 -71 91 -28 92 -29 0 -184
                    0 -184 -92 -29 -91 -28 -22 -71 c-12 -40 -40 -108 -62 -152 l-40 -80 43 -88
                    43 -89 -127 -126 -126 -127 -89 43 -88 43 -80 -40 c-43 -22 -112 -50 -151 -63
                    l-73 -23 -28 -90 -28 -91 -184 0 -184 0 -29 92 -28 91 -67 21 c-37 11 -105 39
                    -152 61 l-85 42 -88 -43 -88 -43 -126 127 -127 126 43 89 43 88 -40 80 c-22
                    43 -50 112 -63 152 l-23 72 -91 28 -90 28 0 184 0 184 90 28 91 28 23 72 c13
                    40 41 109 63 152 l40 80 -43 88 -43 89 127 126 126 127 88 -43 88 -43 85 42
                    c47 22 115 50 152 61 l68 21 21 71 c12 39 25 82 30 94 l9 23 182 -2 182 -3 28
                    -91z m-3012 -3505 l0 -401 -167 4 c-164 3 -169 4 -235 35 -93 44 -151 100
                    -196 191 -35 72 -37 81 -37 171 0 87 3 102 32 163 40 85 97 147 172 186 81 44
                    115 50 284 51 l147 1 0 -401z m3200 241 l0 -160 -1520 0 -1520 0 0 160 0 160
                    1520 0 1520 0 0 -160z m523 -64 c275 -135 293 -149 269 -200 -8 -19 -73 -55
                    -269 -152 -142 -69 -282 -137 -310 -150 l-53 -24 0 350 0 350 53 -24 c28 -13
                    168 -81 310 -150z m-523 -416 l0 -160 -1520 0 -1520 0 0 160 0 160 1520 0
                    1520 0 0 -160z"/>
                    <path d="M3120 4640 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                    <path d="M3440 4640 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                    <path d="M3760 4640 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                    <path d="M1040 3907 c-19 -6 -58 -34 -85 -62 -71 -70 -91 -145 -63 -239 16
                    -55 99 -138 154 -154 30 -9 163 -12 473 -12 l432 0 24 -25 c33 -32 33 -78 0
                    -110 l-24 -25 -232 0 c-153 0 -246 -4 -273 -12 -55 -16 -138 -99 -154 -154
                    -28 -94 -8 -169 63 -239 63 -64 100 -75 244 -75 105 0 113 -1 136 -25 33 -32
                    33 -78 0 -110 l-24 -25 -312 0 c-215 0 -324 -4 -353 -12 -86 -26 -166 -136
                    -166 -228 0 -92 80 -202 166 -228 30 -9 194 -12 618 -12 l576 0 0 80 0 80
                    -575 0 -576 0 -24 25 c-33 32 -33 78 0 110 l24 25 312 0 c215 0 324 4 353 12
                    55 16 138 99 154 154 28 94 8 169 -63 239 -63 64 -100 75 -244 75 -105 0 -113
                    1 -136 25 -33 32 -33 78 0 110 l24 25 232 0 c153 0 246 4 273 12 86 26 166
                    136 166 228 0 92 -80 202 -166 228 -30 9 -163 12 -473 12 l-432 0 -24 25 c-16
                    15 -25 36 -25 55 0 19 9 40 25 55 l24 25 616 0 615 0 0 80 0 80 -622 -1 c-424
                    -1 -634 -5 -658 -12z"/>
                    <path d="M880 1760 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                    <path d="M1200 1760 l0 -80 560 0 560 0 0 80 0 80 -560 0 -560 0 0 -80z"/>
                    <path d="M880 1440 l0 -80 720 0 720 0 0 80 0 80 -720 0 -720 0 0 -80z"/>
                    <path d="M2480 1440 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                    <path d="M2800 1440 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                    <path d="M3120 1440 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
                    <path d="M3516 3904 c-350 -63 -639 -356 -701 -709 -104 -600 417 -1125 1013
                    -1021 479 84 801 540 718 1015 -85 484 -544 803 -1030 715z m379 -178 c113
                    -36 198 -89 290 -181 91 -91 144 -175 182 -290 23 -71 26 -96 26 -215 0 -119
                    -3 -144 -26 -215 -38 -115 -91 -199 -182 -290 -91 -91 -175 -144 -290 -182
                    -71 -23 -96 -26 -215 -26 -119 0 -144 3 -215 26 -114 38 -198 90 -290 182 -91
                    91 -144 175 -182 290 -23 71 -26 96 -26 215 -1 118 3 145 25 212 40 120 92
                    204 182 293 114 113 237 179 380 205 89 16 251 5 341 -24z"/>
                    <path d="M3586 3430 c-63 -16 -153 -70 -197 -117 -22 -24 -55 -74 -72 -111
                    -29 -61 -32 -76 -32 -163 0 -90 2 -99 37 -171 45 -91 103 -147 196 -191 61
                    -29 76 -32 162 -32 86 0 101 3 162 32 93 44 151 100 196 191 35 72 37 81 37
                    172 0 91 -2 100 -37 172 -68 136 -188 217 -336 224 -42 2 -94 -1 -116 -6z
                    m168 -162 c86 -26 166 -136 166 -228 0 -124 -116 -240 -240 -240 -63 0 -114
                    23 -165 75 -102 101 -102 229 0 330 70 71 145 90 239 63z"/>
                    </g>
                </svg></span>
                </button>
            </div>
            </div>
            </div>
            <!-- and .left-column  -->
            <div class="rigth-column d-flex mt-2 mr-2 ">
              <div class="pt-2 mr-2">
                <a class="task-id" target="_blank" :href="taskExternalLink(form.msp_id)">#{{form.taskId}}</a>
              </div>
              <div class="task-autor d-flex mr-2 pt-2 ">
                  <span class="mr-2">автор:</span>
                  <span>{{form.createdBy.name}}</span>
              </div>
              <div class="task-create-date pt-2">
                <span>{{form.createdDate}}</span>
              </div>
            </div>
            <!-- and .rigth-column  -->
            
          </div>
          <!-- start task-temlate-wraper -->     
      <div class="task-temlate-wraper" v-if="showTemplateBlock">
          <tree-table
              class="table"
              :columns="columns"
              :table-data="tableData" 
              id="tree-table"
            >
              <template #headerTemplate="headerProps">
                <table-header v-bind="headerProps" />
              </template>
              <template #nodeTemplate="nodeProps">
                  <node v-bind="nodeProps"
                      :is-key-editable="isKeyEditable(nodeProps.depth)"                              
                      :columns="columns"/>
              </template>
              <template #leafTemplate="leafProps">
                <leaf v-bind="leafProps"              
                    :is-key-editable="isKeyEditable(leafProps.depth)" 
                    :columns="columns"/>
            </template>
          </tree-table>
          <div class="d-flex flex-row-reverse mb-2">                         
              <b-button class="mr-2" variant="success" @click="applyTaskTemplates()"><i class="icon-checkmark22 font-large-2 white pr-2"></i>Створити задачі </b-button>
              <b-button class="mr-2" variant="warning" @click="saveTaskTemplates()"><i class="icon-floppy-disk font-large-2 white pr-2"></i>Зберегти шаблон</b-button>   
          </div>
      </div>
      <!-- and task-temlate-wraper -->
          <transition :duration="{ enter: 300, leave: 300 }" name="fade" tag="p"  v-if="showCastomFields"> 
              <vue-form v-if="vgSchema.fields"  :schema="vgSchema" :model="vgModel" :options="newFormOptions" :isNewModel="true" @model-updated="onFgModelUpdated" ></vue-form>
          </transition>
          <div class="task-description" v-if="!showTemplateBlock">
              <ckeditor :editor="editor" v-model="form.descriptionHtml" :config="editorConfig" :height="120" ></ckeditor>
          </div>
          <!-- --------- devider -------------- -->
          <div class="devider mt-2" v-if="form.mainTaskDescriptionHtml.length > 0 && !showTemplateBlock" >
              <div class="divider-section divider-section-left"></div>
              <div class="divider-section divider-section-center">
                <div class="divider-content">
                  <h6>Опис пов'язаних задач</h6>
                </div>                
              </div>
              <div class="divider-section divider-section-rigth"></div>
            </div>
          <!-- ---------and devider -------------- -->
          <div class="main-task-description mt-2" v-if="form.mainTaskDescriptionHtml.length > 0 && !showTemplateBlock">
            <div class="description-item mx-3 mt-2 " v-for="item, index in form.mainTaskDescriptionHtml" :key="index">
                <div class="">
                    <a class="decs-title" target="_blank" :href="parentTaskUrl(item)">{{item.Title}}</a>
                    <p v-html="parseBBcode(item.Description)"></p>
                </div>
            </div>            
          </div>
           <!-- --------- devider -------------- -->
          <div class="devider mt-2" v-if="form.files.length > 0 && !showTemplateBlock" >
              <div class="divider-section divider-section-left"></div>
              <div class="divider-section divider-section-center">
                <div class="divider-content">
                  <h6>Файли</h6>
                </div>                
              </div>
              <div class="divider-section divider-section-rigth"></div>
            </div>
          <!-- ---------and devider -------------- -->
          <div class="task-files mt-2 d-flex" v-if="taskFiles.length > 0 && !showTemplateBlock">
            <div class="file-item-container mx-1 mt-2 " v-for="item, index in taskFiles" :key="index">
                <div class="" v-if="item.format == 'png' || item.format == 'gpj' ">
                    <div class="action-container action-container-img">
                        <span class="icon-download3  download-link " @click="getFileData(form.taskId, item.file_id, $event)"></span>
                    </div>
                    <img v-img="{title:item.name, group:1}" :src="item.data" :alt="item.name" width="150px">
                   
                </div>                
                <div v-else class="image-vs-icon">
                     <img :src="getIcon(item.name)" :alt="item.name" class="icon">{{item.name}}<span @click="getFileData(form.taskId, item.file_id, $event)" class="icon-download3 download-link"> </span>
                     <!-- <p>{{item.name}}</p>             -->                    
                </div>
            </div>            
          </div>
           <!-- --------- devider -------------- -->
          <div class="devider mt-2" v-if="!showTemplateBlock">
              <div class="divider-section divider-section-left"></div>
              <div class="divider-section divider-section-center">
                <div class="divider-content">
                  <h6>Коментарі</h6>
                </div>                
              </div>
              <div class="divider-section divider-section-rigth"></div>
            </div>
          <!-- ---------and devider -------------- -->
          <div class="task-comment" v-if="!showTemplateBlock">
              <!-- <simpleChat :messages="chatMessages" :curentUser="currentUser.id" @newmessag="sendNewMessage"></simpleChat > -->
              <div class="chatmessages">
                <div class="mesasage-item d-flex px-3 my-2" v-for="item, index in chatMessages" :key="index">
                      <div class="message-user-image">
                          <img :src="item.AUTHOR_AVATAR" width="32px" height="32px" alt="sunil">
                      </div>
                      <div class="message-wraper ml-2">
                          <div class="message-title d-flex">
                              <span class="">{{item.AUTHOR_NAME}} </span><span class="message-date">{{item.POST_DATE}}</span>
                          </div>
                          <div class="message-text" v-html="parseBBcode(item.POST_MESSAGE)">
                              
                          </div>
                      </div>
                </div>
              </div>
          </div>
        <div id="main-content-footer"></div>
      </div>
      <!-- and task-main-content -->
      
    <div class="task-footer" v-if="!showTemplateBlock">
      <div class="input_msg_write px-3 pb-2">
        <Mentionable          
          :keys="['@']"
          :items="chatUser"
          offset="6"
          :limit="13"
          insert-space
           placement="top-start"
          filtering-disabled
          @open="getDefaultMetUser"
          @search="getMetUser($event)" 
          @apply="aplyMention"        
        >
        <input type="text" class="write_msg" ref="msgInput" v-model="newMessage" v-on:keyup.enter="sendNewMessage()" placeholder="Введіть повідомлення" />
        <template #item-@="{ item }">
          <div class="taget-user d-flex" @click="mentionOptionsClick">
              <div class="taget-user-avatar">
                  <img class="option__image" :src="item.avatar" alt="No photo">
              </div>
              <div class="taget-user-desc">   
                <span class="taget-user-name">{{ item.name }}</span>
                <div class="desc">                           
                <span class="taget-user-department">{{item.department}}<span class="taget-user-position ml-2">({{item.position}})</span></span><br>
                <span class="danger missingStatus" v-if="item.missing">{{item.missingStatus}}</span>
                </div>
              </div>
          </div>
        </template>
        </Mentionable>
        <!-- <button class="msg_send_btn" type="button"  v-on:click="sendMessage"><i class="icon-checkmark2" aria-hidden="true"></i></button> -->
      </div>
    </div>       
   </div> 
 </modal>

<!-- And task edit form  -->   
    <gantt v-if="tasks.data" ref="gnt" id="gantDiv" class="left-container" :tasks="tasks" :resources="resources" :setings="ganttSetings" :displaytask="displayOptionst.displayTask" :groupby="displayOptionst.groupBy" :menuitems="menuitems" @context-menu-click="contextMenuClick" @task-updated="taskUpdateRemote" @link-updated="linkUpdateRemote"  :showResorsPanel="displayOptionst.showResoursPanel" ></gantt>
    <!-- @task-selected="selectTask" -->
    <transition name="fade">
      <div class="settings-bar customizer" v-if="showSettingsBar">
        <div class="card ">
          <div class="card-header">
            <div class="d-flex flex-row-reverse">
              <div>
                <svg @click="showSettingsBar = !showSettingsBar" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="cursor-pointer feather feather-x"><line data-v-e55d78e2="" x1="18" y1="6" x2="6" y2="18"></line><line data-v-e55d78e2="" x1="6" y1="6" x2="18" y2="18"></line></svg>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="filters my-2" v-for="filter, key in filtersOptions" :key="key" >
              <dFilter :options="filter.options" :label="filter.label" :id="filter.id" :multiple="filter.multiple" :filterdefault="filter.default" :searchable="filter.searchable" ></dFilter>
            </div>
            <div class="d-flex justify-content-between mr-2">
              <div class="mr-2"><label for="">Панель ресурсів</label></div>
              <div><toggle-button v-model="displayOptionst.showResoursPanel" @change="onChangeshowResoursPanel"/></div>              
            </div>
            <div class="d-flex justify-content-between mr-2">
              <div class="mr-2"><label for="">Відображати пов'язані задачі</label></div>
              <div><toggle-button v-model="displayOptionst.displayRelatedTask" @change="getUserTasks()"/></div>              
            </div>
            <div class="d-flex justify-content-between mr-2">
              <div class="mr-2"><label for="">Змінювати дедлайн по даті закінчення</label></div>
              <div><toggle-button v-model="displayOptionst.deadlineBvEndDate" @change="getUserTasks()"/></div>              
            </div>
            <div class="d-flex justify-content-between mr-2">
              <div class="mr-2"><label for="">Показувати сповіщення</label></div>
              <div><toggle-button v-model="displayOptionst.showNotifications" @change="adduseroptions()" /></div>              
            </div>
               
          </div>
          <div class="card-footer text-muted">
           <!-- Button -->
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// import ToggleBtn from '../components/toogleBtn';
import TreeTable from 'vue-tree-table-component'
import TableHeader from '../components/tree-table/TableHeader.vue'
import Node from '../components/tree-table/Node.vue'
import Leaf from '../components/tree-table/Leaf.vue'
import Editable from '../components/editable.vue'
import VueFormGenerator from "../components/vue-form-generator";
import dayjs, { Dayjs } from 'dayjs'
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
import Gantt from '../components/gantt'
import BBCodeParser from 'js-bbcode-parser/src/index.js';
import dFilter from '../components/dinamicFilters'
import Task from '../../src/tasks.json'
import MenuItems from "../assets/menu.json"
import btchts  from '../components/chat.vue';
import PopoverRow from 'v-calendar/lib/components/popover-row.umd.min'
// import { VueEditor } from "vue2-editor";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToggleButton } from 'vue-js-toggle-button'
import { mixin as clickaway } from 'vue-clickaway';
import { Mentionable } from 'vue-mention'
import fileDownload from 'js-file-download';

export default {
  name: 'WorkPlaner',
  mixins: [ clickaway ],
  components: {
    PopoverRow,
    BBCodeParser,
    Gantt,
    ToggleButton,
    btchts,    
    dFilter,   
    ckeditor: CKEditor.component,
    ClassicEditor,
    TreeTable, 
    TableHeader, 
    Node, 
    Leaf,
    Editable,
    Mentionable,
    "vue-form": VueFormGenerator.component,    
    },
  data () {
    return {      
      showSettingsBar:false,
      showEditMenu:false,
      showCastomFields:false,
      showTemplateBlock:false,
      chatUser:[],
      ganttSetings:{
        from:"",
        to:"",
        globalDayOff:"",
        customCalendar:[
          // {id:"54545646848",
          //  days:[
          //    {
          //    day:'2021.01.01',
          //    hours : ['8:00-18:00']
          //    }            
          //  ]      
          // }
        ],
      },
      displayOptionst:{
        displayTask:"all",
        groupBy:"",
        showResoursPanel:true,
        displayRelatedTask:false,
        startDay:"",
        endDayL:"",
        userRoles:null,
        deadlineBvEndDate:true,
        showNotifications: false
      },
      zoomOptions:[
        { value: 0, text: 'Години' },
        { value: 1, text: 'Дні' },
        { value: 2, text: 'Тижні' },
        { value: 3, text: 'Місяці' },
        { value: 4, text: 'Квартали' },
        { value: 5, text: 'Роки' },
      ],
      zoomLevel:"1",     
     editableFields: {
        0: ['duration', 'active', 'owner', 'start_date'], 
        1: ['duration', 'active', 'owner', 'start_date'],  
        2: ['duration', 'active', 'owner', 'start_date'],             
    },
     columns: [
        {label: 'Назва', id: 'name', width: '1.3', type:"text" }, {label: 'Тривалість', id: 'duration', width: '0.3', type:"number"}, {label: 'Дата початку', id: 'start_date', width: '0.4', type:"date"}, {label: 'Відповідальний', id: 'owner', width: '0.7', type:"selectloader"},  {label: '', id: 'active', width: '0.3', type:"swicher"}
     ],
     tableData:[],
    taskTemplateChange:null,
    messages:[],
    mentionableLinks:[],
    amplyMention:false,
    newMessage:"",
    currentUserId: '',
    chatMessages:[],      
    filtersOptions: [  ],
    firstLoad:true,
    filters:null,
    activeFilters:{},    
    displayTaskOptions:[ 
      { text: 'Всі', value: 'all' },
      { text: 'Не розплановані', value: 'notSheduled' },
      { text: 'Не оцінені', value: 'notEvaluated' },
    ],
    groupTaskOptions:[       
      { text: 'По проектам', value: 'byproject' },
      { text: 'По користувачам', value: 'byuser' },
    ],
    editor: ClassicEditor,   
    editorConfig: {
        // The configuration of the editor.
         height: '200px'
    },
    formSample:{},
    form:{
      id:"",        
      text:"",
      owner:[],
      type:"task",
      start_date:"",        
      end_date:"",
      duration: 1,
      parent:"",
      level:"",
      description:"",
      descriptionHtml:"",
      mainTaskDescriptionHtml:"",
      $new: false,
      taskId:"",
      matchWorkTime:true,
      calendar_id:"",
      createdBy:[],
      stage:{},
      status:{},
      createdDate:"",
      hasSubtaskTemplate: false,
      files:[],
      permissions:{
        delete: true,
        read: true,
        update: true,
      }

    },
    taskAvailableStages:[],
    taskAction:"",
    taskFiles:[],
    formOption:{
      type:[ "project", "task"]
    },
    formChanges:false,
    sendCreateTaskRequest:false,
    formUserOptions:[],
    exitWithautSave:false,
    calendarSelectedDay:null,
    calendarOptions:{
      firstDayOfWeek:2
    },
    calendarAttrs:[
        {
          key: 'sart_date',
          highlight: "blue",
          dates: [],
      }         
    ],
      calendarObg:[],       
      calendarSelectAttribute: {
          highlight: 'true',
      },
     calendarDisabledDates:[
        { weekdays: [] },       
      ],
      calendarDisabledDatesTemp:[
        { weekdays: [] },       
      ],
      calendarLocale:"ua",
      ownerList:[{id:"", text:""}],    
      menuitems:MenuItems,
      vgModel:{},
      vgSchema: {
        fields: [         
        ]
      },
      tFormOptions: {
					hasCardView:true,
					validateAfterLoad: false,
					validateAfterChanged: true,
					validateAsync: false,
					validateBeforeSubmit: true
				},
      newFormOptions: {
					hasListView:true,
					validateAfterLoad: false,
					validateAfterChanged: true,
					validateAsync: false,
					validateBeforeSubmit: true
				},
      // tasks:Task, 
      tasks:{
        data:null,
        links: [],
      }, 
      noData:false, 
      testuser:[],
      testTasks:[],
      testObg : null,
      selectedTask: null,
      resources:[],
      taskDrag:false     
    }
  },
  filters: {  
     noUser (val){
       if (_.isEmpty(val)){ return "No user"}
       return val[0]
     },  
    toPercent (val) {
      if (!val){
        return '0'
      }
      return Math.round((+val) * 100)
    },
    niceDate (obj){
      return `${obj.getFullYear()} / ${obj.getMonth() + 1} / ${obj.getDate()}`
    }
  },
  sockets: {
    connect() {
      console.log('socket connected')
    },    
    task_update(val) { 
      this.$refs.gnt.updateTaskfromWs(val)     
      // console.log(val)
    },
    custom_event(val) { 
      console.log("custom_even")        
       console.log(val)
    },
    task_create(val) {
       this.$refs.gnt.createTaskfromWs(val) 
    },
    task_delete(val) {
       this.$refs.gnt.deleteTaskfromWs(val)
    }
  },
  methods: {
    

    ///tasks methods////
    getUserTasks(){        
      let DataRequest = {
        fromData:dayjs(),
        toData:dayjs().add(5, 'day'),
        range:"week",
        taskPlanning:true,
        filters:[],
        userOptions:{
           displayRelatedTask:this.displayOptionst.displayRelatedTask,
           showResoursPanel:this.displayOptionst.showResoursPanel,
           groupBy:this.displayOptionst.groupBy,
           displayTask:this.displayOptionst.displayTask,
           deadlineBvEndDate:this.displayOptionst.deadlineBvEndDate,
           showNotifications:this.displayOptionst.showNotifications
        }
      }
      if (this.activeFilters){
        DataRequest.filters = this.activeFilters
      }
      if (!DataRequest.filters.Period) {return}     
        this.makeRequest("/getusertasks", {DataRequest: DataRequest}, true)       
          .then(res => {            
            if(res != null && res.data != 0 && res.data.planningData){
              this.resources = res.data.planningData.users;
              this.ownerList = res.data.planningData.users;              
              this.tasks.links = res.data.planningData.links ;
              this.tasks.data = res.data.planningData.tasks ;
              this.ganttSetings.from = res.data.planningData.startDate
              this.ganttSetings.to = res.data.planningData.endDate
              this.ganttSetings.customCalendar = res.data.planningData.customCalendar
              if (!this.firstLoad){
                this.$refs.gnt.reload(this.tasks.data, this.ownerList );                
              }              
              this.firstLoad = false  
            }
            else {this.noData = true}        
          })
    },
    adduseroptions(){
      let DataRequest = {        
        filters:[],
        userOptions:{
           displayRelatedTask:this.displayOptionst.displayRelatedTask,
           showResoursPanel:this.displayOptionst.showResoursPanel,
           groupBy:this.displayOptionst.groupBy,
           displayTask:this.displayOptionst.displayTask,
           deadlineBvEndDate:this.displayOptionst.deadlineBvEndDate,
           showNotifications:this.displayOptionst.showNotifications
        }        
      }
      if (this.activeFilters){
        DataRequest.filters = this.activeFilters
      }
      this.makeRequest("/adduseroptions", {DataRequest: DataRequest}, false)       
          // .then(res => {   
          //   console.log(res)                       
          // })
    },
    onCreateTask(task){  
        this.form.text = task.text;
        this.form.start_date = task.start_date;
        this.form.end_date = task.end_date;
        this.form.id = task.id;
        this.form.parent = task.parent;
        this.form.duration = task.duration;
        this.form.level = task.level;
        this.form.$new = task.$new;
        this.form.owner[0] = this.currentUser;
        this.form.descriptionHtml ="";
        this.form.description ="";
        this.form.matchWorkTime=true,       
        this.getCalendarData();
        if (task.parent == 0 ){
          this.form.start_date = dayjs().format('YYYY-MM-DD HH:mm:ss');        
          //this.form.start_date = new Date();          
        }
        this.prepareUserFormOptions()
        this.modalShow('task-card')       
      },
    exitwisautSave(){
      this.modalClose('task-card');
      this.exitWithautSave=true; 
      this.clearTaskForm();
    },
    saveAndExit(){     
      this.modalClose('task-card')
    },
     onModalTaskFormClosed(){
       this.showEditMenu=false
       this.showCastomFields = false; 
       this.showTemplateBlock = false;
       let currentFormValue = JSON.parse(JSON.stringify(this.form)); 
       //_.isEqual(this.formSample, currentFormValue ) ? console.log("true") : console.log("false")            
        if (!this.exitWithautSave && this.taskAction != "delete" && this.form.text.length > 0 && !_.isEqual(this.formSample, currentFormValue ) ){this.taskUpdateLocal();} 
        if (this.form.$new && this.exitWithautSave   ) { this.$refs.gnt.dellLocalTask(this.form.id); }      
        this.exitWithautSave = false;
        this.form.$new = false;
        this.clearTaskForm();
        console.log("onModalTaskFormClosed");
      },
    taskUpdateLocal(){
      if (this.form.$new){
          this.form.$new = false
          this.createNewTaskRemote(this.form)
          return
        }
        let self = this;     
        let formUser= [];
        let start = dayjs(this.form.start_date);
        let task_id = this.form.id;
        if (_.isArray(this.form.owner)){
          formUser.push(this.form.owner[0].id);
        }
        else {
          formUser.push(this.form.owner.id) 
        }               
        let and = start.add(_.toInteger(this.form.duration), 'hour')      
        let DataRequest = { 
            id:this.selectedTask.id,
            fields:{
              id: this.selectedTask.id,
              duration:this.form.duration,
              start_date:start.format('YYYY-MM-DD HH:mm:ss'),
              end_date:and.format('YYYY-MM-DD HH:mm:ss'),                     
              unscheduled:false,            
              text:this.form.text,
              type:this.form.type,            
              user_id:formUser,
              description:this.form.descriptionHtml,
              descriptionHtml:this.form.descriptionHtml,
              planningID:this.selectedTask.planningID,
              scheduled:this.selectedTask.scheduled,
              calendar_id:this.form.matchWorkTime ? "":"99999999-9999-9999-9999-999999999999",
              matchWorkTime:this.form.matchWorkTime,
              // updateSourceWs:false
            },
            objectType:"task",       
        }                
        self.$refs.gnt.updateTaskById(task_id, DataRequest.fields); 
        this.formChanges = false;
        this.clearTaskForm();
        this.modalClose('task-card')
    },
    taskUpdateRemote(id, mode, task){
      if (this.sendCreateTaskRequest == true){
        this.sendCreateTaskRequest = false
        return
      }
      if(task.updateSourceWs){return}     
      let self = this;     
        function getStartTime(time){
          let day = dayjs(time, "YYYY-MM-DD HH:mm:ss");
          let res = day.add(8, 'hour');    
          return time;       
        }
        this.testObg = task;      
        var taskId = task.id
        let DataRequest = { 
            id:task.id,
            fields:{
              id: task.id,
              duration:task.duration,
              planDuration:parseInt(task.planDuration),
              start_date:getStartTime(task.start_date),
              end_date:task.end_date,
              group_id:task.group_id,
              level:task.level,
              unscheduled:false,
              open:task.open,
              parent:task.parent,
              text:task.text,
              type:task.type,            
              user_id:task.user_id,
              planningID:task.planningID,
              scheduled:task.scheduled,
              calendar_id:task.calendar_id,
              description:task.description,
              descriptionHtml:task.descriptionHtml,
              matchWorkTime:task.matchWorkTime,
            },
            objectType:"task",
            isDrag:this.taskDrag      
        }
        if (mode == "rowDrag") {
          this.makeRequest("/updateBxObject", {DataRequest: DataRequest})
            .then(res => {   
              if (res.data.status) {               
                console.log("update");
              } 
            }) 
        }
        if(task["!nativeeditor_status"] == "updated"){         
          this.makeRequest("/updateBxObject", {DataRequest: DataRequest})
            .then(res => {   
              if (res.data.status) {
                this.$refs.gnt.setTaskSheduled(taskId);
                self.tasks.data[taskId] = task;
                // this.$socket.client.emit('task_update', DataRequest.fields);
                // this.showMessage("Задача оновлена", "info")
              }
              if(this.displayOptionst.showNotifications && res.data.notification) {
                this.showNotification('custom-info','', res.data.notification);          
              }
            }) 
        }
        if(task["!nativeeditor_status"] == "inserted"){         
          this.makeRequest("/addBxObject", {DataRequest: DataRequest})
            .then(res => {   
              if (res.data.status) {
                this.$refs.gnt.setTaskId(taskId, res.data.id);
                DataRequest.fields.id = res.data.id;
                self.tasks.data.push(DataRequest.fields);
                this.showMessage("Задача створена", "info")
                //  this.$refs.gnt.reload();
              }              
            })
        }
        if(task["!nativeeditor_status"] == "deleted"){
          console.log("deleted")
          this.makeRequest("/delBxObject", {DataRequest: DataRequest})
            .then(res => {  
                if (res.data.status) {
                  this.showMessage("Задача видалена", "info")
                  // this.$refs.gnt.reload();
                }            
            })
        }
    },
    createNewTaskRemote(task){        
      let formUser= []; 
      if (_.isArray(task.owner)){
        formUser.push(task.owner[0].id);
      }
      else {
        formUser.push(task.owner.id) 
      }    
      function getStartTime(time){
        // console.log(`time = ${time}`)
        // let day = dayjs(time);
        // let res = day.add(8, 'hour');
        // console.log(`time = ${res}`)
        return dayjs(time).format('YYYY-MM-DD HH:mm:ss');       
      }
      function getEndTime(start, duration){        
        let res = dayjs(start);        
        return res.add(duration, 'hour').format('YYYY-MM-DD HH:mm:ss');       
      }
      let DataRequest = { 
          id:task.id,
          fields:{
            id: task.id,
            duration:task.duration,            
            start_date:getStartTime(task.start_date),
            end_date:getEndTime(task.start_date, task.duration),          
            unscheduled:false,
            open:true,
            parent:task.parent,
            text:task.text,
            type:task.type,            
            user_id:formUser,
            planningID:"",
            description:task.descriptionHtml,
            scheduled:true,
            matchWorkTime:task.matchWorkTime
          },
          objectType:"task",      
      }      
        this.formChanges = false
        this.sendCreateTaskRequest = true
        this.makeRequest("/addBxObject", {DataRequest: DataRequest}, true)
          .then(res => {
            if(this.displayOptionst.showNotifications && res.data.notification) {
                this.showNotification('custom-info','', res.data.notification);          
              }   
            if (res.data.status) {              
              this.$refs.gnt.setTaskId(task.id, res.data.planningData.task.id);
              this.$refs.gnt.updateNewTask(res.data.planningData.task);
              DataRequest.fields.id = res.data.planningData.task.id;
              this.tasks.data.push(DataRequest.fields);
              // this.showMessage("Задача створена", "info")
              this.form.$new = false             
              this.modalClose('task-card') 
              this.clearTaskForm();              
            }
            else {this.sendCreateTaskRequest = false}                   
          })
    },
    deleteTask(id){
        this.taskAction = "delete"; 
        this.$refs.gnt.dellLocalTask(id);
        this.$modals.hide("dell-task");        
        this.$modal.hide('task-card');
        this.clearTaskForm()
      },
      clearTaskForm(){
         this.form.description="";
         this.form.descriptionHtml="";
         this.form.mainTaskDescriptionHtml="";
         this.form.files = [];
         this.form.matchWorkTime = true;
         this.form.type = "task"
        this.chatMessages = [];       
        this.tableData=[];
      },
    getTaskCustomField(id){
        let DataRequest = {
          taskId:id,
          taskPlanning:true
        }
          this.makeRequest("/getusertasks", {DataRequest: DataRequest}, true)
            .then(res => {
              if (res && res.status == "200"){
                this.vgSchema.fields = res.data.planningData.customFields
                this.vgModel = res.data.planningData.task.customFields
                if (res.data.planningData.mainTaskDescription.length) {this.form.mainTaskDescriptionHtml = res.data.planningData.mainTaskDescription;}
                if (res.data.planningData.task.stage) {this.form.stage = res.data.planningData.task.stage;}
                if (res.data.planningData.task.status) {this.form.status = res.data.planningData.task.status;}
                if (res.data.planningData.task.taskStages) {this.taskAvailableStages = res.data.planningData.task.taskStages;}
                if (res.data.planningData.task.createdBy) {this.form.createdBy = res.data.planningData.task.createdBy;}
                if (res.data.planningData.task.createdDate) {this.form.createdDate = res.data.planningData.task.createdDate;}
                if (res.data.planningData.task.hasSubtaskTemplate) {this.form.hasSubtaskTemplate = res.data.planningData.task.hasSubtaskTemplate;}
                if (res.data.planningData.task.matchWorkTime) {this.form.matchWorkTime = res.data.planningData.task.matchWorkTime;}
                // if (res.data.planningData.task.files) {
                //   this.form.files = this.prepareFiles(res.data.planningData.task.files)
                //   // this.form.files = res.data.planningData.task.files;
                //   }
                  this.getTaskFiles(res.data.planningData.task)
                this.formSample = JSON.parse(JSON.stringify(this.form));              
              }    
            })
      },
      prepareFormValue(task){
        this.form.owner = task.user_id;
        let user = []
        if (_.isArray(task.user_id)){        
            task.user_id.forEach(element => {
              user.push(_.find(this.ownerList, function(o) { return o.id == element; }));
            });
        }
        else {
          user.push(_.find(this.ownerList, function(o) { return o.id ==task.user_id; }));
        }       
      this.form.id = task.id
      this.form.owner = user;
      this.form.text = task.text;
      this.form.type = task.type;
      this.form.description = task.description 
      this.form.start_date  = task.start_date ;
      this.form.end_date  = task.end_date;
      this.calendarAttrs[0].dates = task.start_date;
      this.form.duration = task.duration;
      this.form.taskId = task.taskId;
      this.form.permissions = task.permissions;
      if (task?.description){this.form.descriptionHtml = this.parseBBcode(task.description)};     
      this.form.calendar_id = task.calendar_id;
      this.form.matchWorkTime = task.matchWorkTime;
      this.form.msp_id = task.msp_id;
      this.onMatchWorkTimeChange()
      this.prepareUserFormOptions()
      //  console.log(this.parseBBcode(task.description))
      },
    
      onEditTask(task){        
        this.prepareFormValue(task);      
        this.getTaskCometns();
        this.getTaskCustomField(task.id);       
        this.getCalendarData();
        this.formChanges = false;
        this.modalShow('task-card')       
        // this.$store.getters.isAdmin ? this.modalShow('task-card') : this.modalShow('edit');
      },
    onEmptyClick(e){         
      this.showSettingsBar = false     
    },

    onTaskSelect(task) {     
      this.selectedTask = task
    },
    onTaskChange(task) {
      // console.log("on task Change 1") 
      this.selectedTask = task
    },
    onMatchWorkTimeChange(){
      if(!this.form.matchWorkTime){  
        this.calendarDisabledDates[0].weekdays = [1]     
      }
      else  {
         this.calendarDisabledDates[0].weekdays = [1,7]         
         }     
    },
    getTaskCometns(){    
      let DataRequest = { 
         taskID:null
         }
        if(this.selectedTask && this.selectedTask.taskId ){
          DataRequest.taskID = this.selectedTask.taskId 
        }     
        if(!_.isEmpty(DataRequest.taskID))  {
        this.makeRequest("/gettaskcomments", {DataRequest: DataRequest})
          .then(res => {  
            this.chatMessages = res.data.data                     
          })  
        }      
    },
    sendNewMessage(){
      if(this.amplyMention){
          this.amplyMention = false;
          return
        }
        let messages = "";
        let mention_arr = this.newMessage.match(/@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+/g);
        console.log(mention_arr)
        if (mention_arr && mention_arr.length) {
          mention_arr.forEach(element => {
          let  link = this.mentionableLinks.find(item => item.id == element.slice(1));
          if(link){
            this.newMessage = this.newMessage.replace(element, link.str)  
            }        
          });      
        }
      let DataRequest = {       
         taskID:null,
         comment:this.newMessage
         }
        if(this.selectedTask && this.selectedTask.taskId ){
          DataRequest.taskID = this.selectedTask.taskId 
        }
                
        if(!_.isEmpty(DataRequest.taskID))  {
          this.makeRequest("/addtaskcomments", {DataRequest: DataRequest})
            .then(res => {  
              // this.chatMessages = res.data.data  
              this.newMessage = "";         
              this.getTaskCometns();
              this.scrollTo('#main-content-footer','#task-main-content')                 
            })  
          }      
    } ,
     getMetUser(event){              
        if (event.length){                          
            let url = 'getmentionableuser';
            this.searchMetUser(event, url, this.form.taskId, this)                          
        }
    },
    search: _.debounce((search, url, taskid, vm) => {
        let DataRequest = {
            "name": search,
            "taskid": taskid                                      
          }             
        vm.makeRequest("/"+url, {"DataRequest": DataRequest})           
          .then(res => {
            vm.chatUser = res.data.users
            
            });
      }, 300),
     
    getDefaultMetUser(){
      let DataRequest = {
        taskId:this.form.taskId,
        name:"",       
      }
      this.makeRequest("/getmentionableuser", {DataRequest: DataRequest}, true)       
          .then(res => {  
            this.chatUser = res.data.users                         
          })
    },
    aplyMention(e){
      this.amplyMention = true;      
      let str = {
        id: e.value,
        str: `[USER=${e.id}]${e.name}[/USER]`
      };
      this.mentionableLinks.push(str);     
    },
    mentionOptionsClick(){
      let input = this.$refs.msgInput.focus();
      this.amplyMention = false;
    },
     showTaskTemplate(){
      if (!this.tableData.length){
        this.getTasTemplates()
      }
      this.showTemplateBlock = !this.showTemplateBlock;
    },
     getTasTemplates(){    
      let DataRequest = { 
         taskID:null
         }
        if(this.selectedTask && this.selectedTask.taskId ){
          DataRequest.taskID = this.selectedTask.taskId 
        }     
        if(!_.isEmpty(DataRequest.taskID))  {
        this.makeRequest("/gettasktemplates", {DataRequest: DataRequest}, true)
          .then(res => { 
            this.tableData = res.data.data                    
          })  
        }      
    },
    editTaskTemplates(row){ 
      // console.log(row);   
      let DataRequest = { 
         "taskID":null,
         "templateData":row,
         objectType:"TaskTemplate"
         }
        if(this.selectedTask && this.selectedTask.taskId){
          DataRequest.taskID = this.selectedTask.taskId 
        } 
        if(!_.isEmpty(DataRequest.taskID))  {
        this.makeRequest("/updateBxObject", {DataRequest: DataRequest}, true)
          .then(res => { 
            this.tableData = res.data.data; 
                                  
          })  
        }      
    },
    saveTaskTemplates(){    
      let DataRequest = {
         "taskID":null,
         "templateData":this.tableData,
         objectType:"TaskTemplate"
         }
        if(this.selectedTask && this.selectedTask.taskId){
          DataRequest.taskID = this.selectedTask.taskId 
        }     
        if(!_.isEmpty(DataRequest.taskID))  {
        this.makeRequest("/updateBxObject", {DataRequest: DataRequest})
          .then(res => { 
            console.log(res)                  
          })  
        }      
    },
    applyTaskTemplates(){    
      let DataRequest = { 
         "taskID":null,
         "templateData":this.tableData         
         }
        if(this.selectedTask && this.selectedTask.taskId){
          DataRequest.taskID = this.selectedTask.taskId 
        }     
        if(!_.isEmpty(DataRequest.taskID))  {
        this.makeRequest("/applytasktemplate", {DataRequest: DataRequest}, true)
          .then(res => { 
            this.tableData = res.data.data;                 
          })
          .then(res =>{
            this.getUserTasks();
          })  
        }      
    },
    onUserSelectead(){
      this.resources.push(this.form.owner)
      this.ownerList.push(this.form.owner)
      this.$refs.gnt.reload(this.tasks.data, this.ownerList ); 
      this.getCalendarData();
    },
    findUser(query){      
      this.search(query, this) 
    },
    search: _.debounce((search,  vm) => {
      console.log(`this = ${vm}`);
      let self = vm;            
      let DataRequest = {
        "ValueField": search                                 
      }           
      var p = {
        'catalog':'Bitrix.Справочник.Users',
        'planer':true
      }
    self.getFielfOptions("/getdatavaluefield", {"DataRequest": DataRequest, "parametrs": p})
    .then(res => {       
            if (res.data.Data && res.data.Data.length){
              self.formUserOptions = res.data.Data;
            }        
        });
        }, 1000)
    ,
    searchMetUser: _.debounce((search, url, taskid, vm) => {
        let DataRequest = {
            "name": search,
            "taskid": taskid                                      
          }             
        vm.makeRequest("/"+url, {"DataRequest": DataRequest})           
          .then(res => {
            vm.chatUser = res.data.users            
            });
      }, 300),
  prepareUserFormOptions(){
    let user = JSON.parse(JSON.stringify(this.ownerList));
    this.formUserOptions = _.filter(user, function(o) { return o.isUser;});     
  },
  changeMatchWorkTime(){
     if(!this.formChanges.matchWorkTime){
       this.calendarDisabledDates = [
        { weekdays: [] },       
      ]
     }
     else {
       this.calendarDisabledDates = [
        { weekdays: [1,7] },       
      ]
     }
  },
    getCalendarData(){    
      let DataRequest = { 
         user_id:this.form.owner.id,
         task_id:null
         }
        if(this.selectedTask && this.selectedTask.taskId ){
          DataRequest.task_id = this.selectedTask.taskId 
        }
        if (_.isArray(this.form.owner) && this.form.owner.length ){
        DataRequest.user_id = this.form.owner[0].id
      }    
        if(!_.isEmpty(DataRequest.user_id))  {
        this.makeRequest("/getPlannedCalendar", {DataRequest: DataRequest})
        .then(res => {       
         this.calendarDisabledDates  = res.data.data.disabledDates;
         this.calendarDisabledDatesTemp  = res.data.data.disabledDates;
         this.calendarObg = res.data.data.taskData;
        //  this.calendarMove(this.selectedTask.start_date);
          // let tmp = _.find(this.calendarAttrs, function(o) { return o.key == "sart_date" });
          //  this.calendarAttrs = _.concat(tmp, res.data.data)
          //  this.calendarAttrs = res.data.data                               
        })  
        }      
    }, 
    onBeforeTaskDrag(task){
      this.taskDrag = true
    },
    onAfterTaskDrag(task){
      this.taskDrag = false
    },
    calendarMove(day) {
        let calendar = this.$refs.cl;        
        calendar.move(day);
        calendar.focusDate(day);
    },
    calendarDayClicked(day){
      this.calendarSelectedDay = day;
      this.calendarAttrs[0].dates = this.form.start_date   
    },
    onFgModelUpdated(newVal, schema) {
      console.log("on update")
      let DataRequest = {
        "id":this.selectedTask.id,
        "params":"updateCustomField",
        "ValueField": newVal,
        "ID_Field": schema,
        "objectType":"customField"                           
      }         
      this.getFielfOptions("/updateBxObject", {"DataRequest": DataRequest})
        .then(res => {
            if(res.data != null){
              if (res.data.status){                
                this.vgModel = res.data.planningData.task.customFields
              }              
            }             
        });
    },   
    ///tasks methods////
    ///links methods/////
    linkUpdateRemote(id, mode, link){
      let DataRequest = { 
         data:{
           id:link.id,
           source:link.source,
           target:link.target,
           type:link.type,
         },
         objectType:"link",       
      }
      if (link["!nativeeditor_status"] == "inserted"){
        this.makeRequest("/addBxObject", {DataRequest: DataRequest})
          .then(res => {              
            console.log(res)
            // this.showMessage("Посилання створено", "info")  
          }) 
      }
      if (link["!nativeeditor_status"] == "deleted"){
        this.makeRequest("/delBxObject", {DataRequest: DataRequest})
          .then(res => {              
            console.log(res) 
            // this.showMessage("Посилання видалено", "info") 
          }) 
      }
    },
    /// and links///
    ///comon methods////
      getFilters() {
      let DataRequest={
        objectType: "task"
      }
      this.makeQuery("/getfilters", {DataRequest: DataRequest, token: this.$store.getters.getToken, user: this.$store.getters.getUser})
        .then(res => {
            if(res != null){
              let self = this;
              _.forEach(res.data.data , function(value) {
                self.activeFilters[value.id] = value.default
              })
              this.filtersOptions = res.data.data
              if(res.data.userOptions){
                this.displayOptionst.showResoursPanel = res.data.userOptions.showResoursPanel
                this.displayOptionst.displayRelatedTask = res.data.userOptions.displayRelatedTask 
                this.displayOptionst.displayTask = res.data.userOptions.displayTask 
                this.displayOptionst.deadlineBvEndDate = res.data.userOptions.deadlineBvEndDate 
                this.displayOptionst.showNotifications = res.data.userOptions.showNotifications 
                }            
              if(res.data.userRoles){
                  this.displayOptionst.userRoles = res.data.userRoles;                  
                  this.$store.dispatch('setUserRole', {
                    roles: res.data.userRoles
                  });
              }
              this.ganttSetings.globalDayOff = res.data.disabledDates;              
              this.getUserTasks();
            }
            });
    },
    parentTaskUrl(task){   
      console.log('parentTaskUrl', task);  
      if(task.msp_id){
        return `https://sp.modern-expo.com/crm/task/${task.msp_id}`
      }
      else return `https://bx.modern-expo.com/company/personal/user/${this.$store.getters.getCurrentUser.id}/tasks/task/view/${task.ID}/`
    
    },
     onChangeshowResoursPanel(){    
      localStorage.setItem('showResoursPanel', this.displayOptionst.showResoursPanel)
      localStorage.setItem('displayRelatedTask', this.displayOptionst.displayRelatedTask)  
      this.$refs.gnt.toogleResourcePanel(this.displayOptionst.showResoursPanel);
      this.getUserTasks();
    },
    onDfilterChange(data){      
      if(!_.isEqual(this.activeFilters[data.id], data.value)){
        let index = _.findIndex(this.filtersOptions, function(o) { return o.id == data.id; });
        if (index >=0) {this.filtersOptions[index].default = data.value;}        
        this.activeFilters[data.id] = data.value;
        this.getUserTasks(); 
      } 
    },
    contextMenuClick(nemuId, event, Task){
      let self = this;
      if (nemuId == "custom_field"){
        this.modalShow('customFieldModal') ; 
        this.getTaskCustomField(Task.id) 
      }      
      if (nemuId == "edit"){      
         this.getFormValue(Task); 
         this.getCalendarData();
         this.getTaskCometns();  
         this.modalShow('edit');            
      } 
    },
    groupTaskBy(){ 
      this.$store.commit('groupBy', this.displayOptionst.groupBy);
      this.$refs.gnt.groupTaskBy(this.displayOptionst.groupBy); 
       this.adduseroptions()         
    },
    filterTaskBy(){
      this.$store.commit('displayTask', this.displayOptionst.displayTask);
      this.adduseroptions()
    },
    ///comon methods////

    ///Filemanager/////
    getTaskFiles(task){
      let DataRequest={
        id: task.id,        
        TaskID: task.taskId,        
      }
      this.makeQuery("/gettaskfiles", {DataRequest: DataRequest, token: this.$store.getters.getToken, user: this.$store.getters.getUser})
        .then(res => {         
          this.taskFiles = this.prepareFiles(res.data.files)                   
            });
    },
     getFileData(taskId, fileId, e) {
       if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation()
      let DataRequest={
        TaskID: taskId,
        FILE_ID:fileId
      }
      this.makeQuery("/gettaskfiles", {DataRequest: DataRequest, token: this.$store.getters.getToken, user: this.$store.getters.getUser})
        .then(res => {
          let file = this.base64String(res.data.files[0].NAME, res.data.files[0].DATA);        
          fileDownload(file, res.data.files[0].NAME);       
                
            });
    },
    prepareFiles(files){
      let res = []
      files.forEach( item => {
        let file = {}
        file.attachment_id = item.ATTACHMENT_ID
        file.name = item.NAME
        file.size = item.SIZE
        file.file_id = item.FILE_ID
        file.format = item.FORMAT        
        file.data = `data:image/${file.format};base64, ${item.DATA}`        
        // this.getThumbnail(file.data, el=> file.thumbnail = el)
        res.push(file)
      })
      return res;
    },   
    getThumbnail(element, callback){      
      var img = new Image;
      img.onload = function() {
              //зжимання
              var w = img.width;
              var h = img.height;
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d'); // Создаем узел атрибута
              var anw = document.createAttribute("width"); 
              anw.nodeValue = w;
              var anh = document.createAttribute("height");
              anh.nodeValue = h;
              canvas.setAttributeNode(anw);
              canvas.setAttributeNode(anh);
              ctx.drawImage(img, 0, 0, w, h);
              // Чем меньше значение .7, тем более размытым будет нарисованное изображение
              var base64Img = canvas.toDataURL('image/jpeg' ,.7);
              callback(base64Img)
              //зжимання

          }
          img.src = element;
    },
    saveFile(){

    },
    base64String(file, data){
            let mediatype = '';
            
            const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
              const byteCharacters = atob(b64Data);
              const byteArrays = [];

              for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              const blob = new Blob(byteArrays, {type: contentType});
              return blob;
            }

            if (file.indexOf('.xlsx') > -1) {
              mediatype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            } else if (file.indexOf('.xls') > -1) {
              mediatype = 'application/vnd.ms-excel';
            } else if(file.indexOf('.txt') > -1) {
              mediatype = 'text/plain';
            } else if(file.indexOf('.xml') > -1) {
              mediatype = 'text/xml';
            } else if ((file.indexOf('.png') > -1)||(file.indexOf('.jpg') > -1)||(file.indexOf('.jpeg') > -1)||(file.indexOf('.png') > -1)) {
              mediatype = 'image/png';
            } else if (file.indexOf('.pdf') > -1) {
              mediatype = 'application/pdf';
            } else if (file.indexOf('.doc') > -1) {
              mediatype = 'application/msword';
            } else if (file.indexOf('.docx') > -1) {
              mediatype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            } else if (file.indexOf('.gz') > -1) {
              mediatype = 'application/gzip';
            } else if (file.indexOf('.rar') > -1) {
              mediatype = 'application/vnd.rar';
            } else if (file.indexOf('.tar') > -1) {
              mediatype = 'application/x-tar';
            } else if (file.indexOf('.ppt') > -1) {
              mediatype = 'application/vnd.ms-powerpoint';
            } else if (file.indexOf('.pptx') > -1) {
              mediatype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            } else if (file.indexOf('.vsd') > -1) {
              mediatype = 'application/vnd.visio';
            } else if (file.indexOf('.zip') > -1) {
              mediatype = 'application/zip';
            }

            if(mediatype != ''){
              return b64toBlob(data, mediatype)//'data:' + mediatype + ';base64,';
            } else {
              return null;
            }

          },
    getIcon(file){
      if ((file.indexOf('.xlsx') > -1) || (file.indexOf('.xls') > -1)) {
      return require('./../assets/icons/excell.png');
      } else if((file.indexOf('.txt') > -1) || (file.indexOf('.xml') > -1)) {
      return require('./../assets/icons/word.png');  
      } else if (file.indexOf('.pdf') > -1) {
      return require('./../assets/icons/pdf.png');
      } else if ((file.indexOf('.doc') > -1) || (file.indexOf('.docx') > -1)) {
      return require('./../assets/icons/word.png');
      } else if((file.indexOf('.zip') > -1) || (file.indexOf('.gz') > -1) || (file.indexOf('.rar') > -1) || (file.indexOf('.tar') > -1)) {
      return require('./../assets/icons/archive.png');
      } else if ((file.indexOf('.ppt') > -1) || (file.indexOf('.pptx') > -1)) {
      return require('../assets/icons/powerpoint.png');
      } else if (file.indexOf('.vsd') > -1) {
      return require('./../assets/icons/visio.png');
      }
      else {return require('./../assets/icons/archive.png');}
    },
    ///Helpers////
    customLabel ({ text }) {
      return `${text}`
    }, 
    showDellDialog(){  
      this.$modals.show("dell-task")
    },
    hasRole(role){
      let res = this.displayOptionst.userRoles.find(item => item.Title == role);     
      return res ? true : false; 
    },    
      canDeleteTask(){
      return this.form.permissions.delete ? true : false;
    },
    showMessage(text, type){
      this.$refs.gnt.showMessage(text, type);
    },    
    modalShow (name) {
      this.$modal.show(name);
    },    
    modalClose(name){
      this.$modal.hide(name);
    },
     parseBBcode(bbcode){
      const parser = new BBCodeParser({
          '\\\n': '<br>',
          '\\[br\\]': '<br>',
          '\\[b\\](.+?)\\[/b\\]': '<strong>$1</strong>',
          '\\[b\\](.+?)\\[/b\\]': '<strong>$1</strong>',
          '\\[i\\](.+?)\\[/i\\]': '<em>$1</em>',
          '\\[u\\](.+?)\\[/u\\]': '<u>$1</u>',

          '\\[h1\\](.+?)\\[/h1\\]': '<h1>$1</h1>',
          '\\[h2\\](.+?)\\[/h2\\]': '<h2>$1</h2>',
          '\\[h3\\](.+?)\\[/h3\\]': '<h3>$1</h3>',
          '\\[h4\\](.+?)\\[/h4\\]': '<h4>$1</h4>',
          '\\[h5\\](.+?)\\[/h5\\]': '<h5>$1</h5>',
          '\\[h6\\](.+?)\\[/h6\\]': '<h6>$1</h6>',

          '\\[p\\](.+?)\\[/p\\]': '<p>$1</p>',

          '\\[TABLE\\](.+?)\\[/TABLE\\]': '<table>$1</table>',
          '\\[TR\\](.+?)\\[/TR\\]': '<tr>$1</tr>',
          '\\[TD\\](.+?)\\[/TD\\]': '<td>$1</td>',

          '\\[color=(.+?)\\](.+?)\\[/color\\]':  '<span style="color:$1">$2</span>',
          '\\[size=([0-9]+)\\](.+?)\\[/size\\]': '<span style="font-size:$1px">$2</span>',

          '\\[img\\](.+?)\\[/img\\]': '<img src="$1">',
          '\\[img=(.+?)\\]':          '<img src="$1">',

          '\\[email\\](.+?)\\[/email\\]':       '<a href="mailto:$1">$1</a>',
          '\\[email=(.+?)\\](.+?)\\[/email\\]': '<a href="mailto:$1">$2</a>',

          '\\[url\\](.+?)\\[/url\\]':                      '<a href="$1">$1</a>',
          '\\[url=(.+?)\\|onclick\\](.+?)\\[/url\\]':      '<a onclick="$1">$2</a>',
          '\\[url=(.+?)\\starget=(.+?)\\](.+?)\\[/url\\]': '<a href="$1" target="$2">$3</a>',
          '\\[url=(.+?)\\](.+?)\\[/url\\]':                '<a href="$1">$2</a>',

          '\\[a=(.+?)\\](.+?)\\[/a\\]': '<a href="$1" name="$1">$2</a>',

          '\\[list\\](.+?)\\[/list\\]': '<ul>$1</ul>',
          '\\[list=1\\](.+?)\\[/list\\]': '<ol>$1</ol>',
          '\\[\\*\\]\\s?(.*?)\\r':   '<li>$1</li>',
          '\\[USER=(.+?)\\](.+?)\\[/USER\\]': '<a class="taget-user" target="_blank" href="https://bx.modern-expo.com/company/personal/user/$1/">$2</a>'
         
      });
      return parser.parse(bbcode)
    },  
     zoomIn(){
      this.$refs.gnt.zoomIn();
      if (this.zoomLevel > 0) this.zoomLevel--     
    },
    zoomOut(){
      this.$refs.gnt.zoomOut();
       if (this.zoomLevel < this.zoomOptions.length) this.zoomLevel++
    },
    setZoomLevel(){
       this.$refs.gnt.setZoomLevel(this.zoomLevel);
    },
    goTooday(){
      this.$refs.gnt.goTooday();
    },
    isKeyEditable(depth){
      const editableFields = this.editableFields[depth] || []        
      return (key) => editableFields.includes(key)
    },
    scrollTo(element, container){     
        let options={
          container: container,
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          x: false,
          y: true
        }
        var VueScrollTo = require('vue-scrollto');
        let duration = 500
         VueScrollTo.scrollTo(element, duration, options)
    },
    reload(){    
      this.$refs.gnt.reload();
    },
    ///Helpers////    
    closeModal(){
      this.$modals.hide("dell-task")
    },     
      closeMenu () {
     this.showEditMenu = false      
    }, 
    /////Socket////     
     sendSocketMessage(data){
      this.$socket.client.emit('task_apdate', data);
    },
    taskExternalLink(msp_id){
      if(msp_id){
        return `https://sp.modern-expo.com/crm/task/${msp_id}`
      }
      else return `https://bx.modern-expo.com/company/personal/user/${this.$store.getters.getCurrentUser.id}/tasks/task/view/${this.form.taskId}/`
    }
    
   
  },
  computed: {
    countCastomFields(){
      if (this.vgSchema.fields.length){
        return this.vgSchema.fields.length
      }
      else return 0;
    },
    svicherOptions(){
      let res = {
        isActive:this.displayOptionst.showResoursPanel
      }
      return res
    },   
    attributes() {
      return [        
        ...this.calendarObg.map(task => ({
          dates: task.dates,
          dot: {
            color: task.color,
            class: task.isComplete ? 'opacity-75' : '',
          },
          popover: {
            label: task.description,
          },         
          customData: task.description,
        })),
      ];
    },    
    currentUser(){
       return this.$store.getters.getCurrentUser;
    },
     durationOk(){
      return  this.form.duration <=0 ? true : false;
    },

    filterSectionHeight() {
      const filterSectionDOM = document.getElementById("gantDiv");
      return filterSectionDOM ? filterSectionDOM.offsetHeight : 0;   
    },
    // owners(){
    //   let user = JSON.parse(JSON.stringify(this.ownerList));
    //   let res = _.filter(user, function(o) { return o.isUser;});
    //   return res;
    // },   
    groupByText(){
      return this.groupByTask ? "по задачам" :"по користувачам"
    },
   
  },
  watch:{              
      form: {
      handler:  function(){ 
        this.formChanges = true
      },     
      deep: true
    },     
    },
  mounted () {     
    this.getFilters();
    this.displayOptionst.groupBy = this.$store.getters.groupBy    
    this.$root.$on('task-selected', request => this.onTaskSelect(request));
    this.$root.$on('task-change', request => this.onTaskChange(request));
    this.$root.$on('empty-click', request => this.onEmptyClick(request));
    this.$root.$on('before-taskdrag', request => this.onBeforeTaskDrag(request));
    this.$root.$on('after-taskdrag', request => this.onAfterTaskDrag(request));
    this.$root.$on('on_task_edit', request => this.onEditTask(request));
    this.$root.$on('on_task_create', request => this.onCreateTask(request));
    this.$root.$on('template-row-change', request => this.editTaskTemplates(request));
    this.$root.$on('on_dfilter_change', request => this.onDfilterChange(request)); 
  },
  beforeDestroy(){ 
      this.$root.$off()
    },
}
</script>

<style >
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Inter, sans-serif !important;
    font-size: 14px;
  }
  .container {
    height: 100%;
    width: 100%;
  }
  .left-container {
    overflow: hidden;
    position: relative;
    /* height: 100%; */
    min-height: 95vh;
  }
  .right-container {
    border-right: 1px solid #cecece;
    float: right;
    height: 100%;
    width: 240px;
    box-shadow: 0 0 5px 2px #aaa;
    position: relative;
    z-index:2;
  }
  .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 0.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}
.custom-control-inline {
    display: inline-flex;
    margin-right: 0.5rem;
    line-height: 1.2rem;
}
.custom-control-inline label {
  padding-left: 5px;
}
  .gantt-messages {
    list-style-type: none;
    height: 50%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 5px;
  }
  .gantt-messages > .gantt-message {
    background-color: #f4f4f4;
    box-shadow:inset 5px 0 #d69000;
    font-family: Geneva, Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 5px 0;
    padding: 8px 0 8px 10px;
  }
  .gantt-selected-info {
    border-bottom: 1px solid #cecece;
    box-sizing: border-box;
    font-family: Geneva, Arial, Helvetica, sans-serif;
    height: 50%;
    line-height: 28px;
    padding: 10px;
  }
  .gantt-selected-info h2 {
    border-bottom: 1px solid #cecece;
  }
  .select-task-prompt h2{
    color: #d9d9d9;
  }
  .form-group {
    padding-left: 10px;
    padding-right: 10px;
  }
  .multiselect__single {
    padding-left: 5px;
    margin-bottom: 8px;
}
.multiselect__input, .multiselect__single {
    position: relative;
    display: inline-block;
    min-height: 15px;
    line-height: 15px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0 0 0 5px;
    width: 100%;
    transition: border .1s ease;
    box-sizing: border-box;
    margin-bottom: 8px;
    vertical-align: top;
    font-size: 13px;
}
.option__desc {
  padding-top: 7px;
}
.option__desc, .option__image {
    display: inline-block;
    vertical-align: middle;
}
.option__image {
    max-height:45px;
    margin-right: 10px;
}
.card {
  border-bottom: none;
  margin-right: 30px;
}
.card-body {
  min-height: 50vh;
}
.navigation label span {
  margin-left: 8px;
}
.ml-2 {
  margin-left: 10px;
}
.mr-2 {
  margin-right: 10px;
}
.pr-1 {
  padding-right:5px;
}
.pr-2 {
  padding-right:10px;
}
.calendar-duration {
  margin-left: 5px;
  color: #000;
  background-color: #fff;
}
.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    }
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
/* editor */
#g-description-input .ql-editor {
    min-height: 110px;
    font-size: 13px;
}
#g-description-input .ql-snow .ql-toolbar button svg, .quillWrapper .ql-snow.ql-toolbar button svg {
    width: 16px;
    height: 16px;
}
#g-description-input .ql-snow .ql-toolbar button, .ql-snow.ql-toolbar button {
    background: 0 0;
    border: none;
    cursor: pointer;
    display: inline-block;
    /* float: left; */
    height: 24px;
    padding: 3px 5px;
    width: 20px;
}


#form-row {
  padding-top: 5px;
  padding-bottom: 5px;
}
.tab-pane {
   height: 80vh;
}
.customizer {
    width: 400px;
    z-index: 1051;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh)*100);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.settings-bar {
  background-color: #f7f7f7;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
 .cursor-pointer {
    cursor: pointer;
}
.mr-2 {
  margin-right: 10px;
}
.spinner {
    -webkit-animation: spin-ltr 1s linear infinite;
    animation: spin-ltr 1s linear infinite;
}
.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}
.scroll {
  overflow:scroll; height:80vh;
  padding: 10px 10px;
}
.close-icon {
  background: #cecece;
  border:1px solid #fff;
  width: 34px;
  height: 34px;
  cursor: pointer;
  z-index:999;
  position: absolute;
  top:-16px;
  right: -20px;
}
.close-icon svg{
  margin-left: 6px;
}
.vm--modal  {
  overflow:initial !important;
}
.ex-but {
  padding: 5px 5px;
  /* font-size: 16px; */
  cursor:pointer;
}
.ex-but:hover {
  color:#999;
}
.label-small {
  font-size: 12px;
  margin-top:20px;
  font-weight: normal;

}
.decs-title {   
   font-weight: bold;
    color: #999;
    margin-bottom: 10px;
}
#main-task-description .card-body {
  min-height: 10vh;
}
.dellTask {
  z-index: 100500;
}

/* ////////new form */
.task-card a {
  color: rgb(5, 177, 250) !important;
}
.task-view{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.task-view-header__container {
  padding: 5px 16px 5px 5px;
}
.task-view-header__main {
  flex-wrap: nowrap;
  /* align-items: flex-start; */
}
.task-title-wrapper {
  font-size: 14px;
  font-weight: bold; 
  padding-right: 10px;
  width: 100%;
}


.active-action-bar {
  display: flex;
}
.button-style-block {
  display: flex;
  border: 0px;
  background-color: #fff;

}
.button-style-block:hover {
  color: #12c6fd;

}
.task-date-input {
    border-radius: 0px;
    border: 0px;

}
.task-current-status {
  display: block;
  width: 150px;
}
.task-status .multiselect::before {
  content: "";
  flex-shrink: 0;
  display: block;
  width: 0;
  height: calc(100% + 2px);
  margin-right: 16px;
  border-left: 8px solid;

}
.task-date-input {
  font-size: 13px;
}
.task-type-wraper {
  padding-top: 5px;
  width: 150px;
}
.task-owner-wraper{
  width: 200px;
}
.task-owner .multiselect__tags, 
.task-type .multiselect__tags, 
.task-status .multiselect__tags, 
.task-temlate-wraper .multiselect__tags{
  border: 0px;
  border-radius: 0px;
}
.task-owner .option__image, .task-temlate-wraper .option__image {
    /* max-height: 30px;
    margin-right: 8px; */
    /* width: 32px; */
    height: 32px;
    position: relative;
    z-index: 2;
    border-radius: 50%;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    cursor: pointer;
}
.task-owner .multiselect__option, 
.task-temlate-wraper .multiselect__option, 
.task-status .multiselect__option  
.task-type .multiselect__option  {
  font-size: 13px;
  padding: 7px;
}
.task-owner .multiselect__input, 
.task-owner .multiselect__single, 
.task-temlate-wraper .multiselect__input, 
.task-temlate-wraper .multiselect__single {
    position: relative;
    display: inline-block;
    min-height: 12px;
    line-height: 12px;
    border: none;
    border-radius: 3px;
    background: #fff;
    padding: 0 0 0 3px;
    width: 100%;
    transition: border .1s ease;
    box-sizing: border-box;
    margin-bottom: 5px;
    vertical-align: top;
    font-size: 12px;
}
/* .task-owner .multiselect__element {

} */
.castom-field-swicher-container {
  padding-top: 16px;
  margin-left: 20px;
}
.template-swicher-container {
  padding-top: 13px;
}
.task-temlate-wraper {
  font-size: 12px!important;
}
.task-temlate-wraper .row {
  margin: 0;
}

.task-status .multiselect__tags{
  padding-top: 13px;
}
.task-status .multiselect__input,
.task-type .multiselect__input,
.task-type .multiselect__single,
.task-status .multiselect__single
{
 position: relative;
    display: inline-block;
    min-height: 12px;
    line-height: 12px;
    border: none;
    border-radius: 3px;
    background: #fff;
    padding: 0 0 0 3px;
    width: 100%;
    transition: border .1s ease;
    box-sizing: border-box;
    margin-bottom: 5px;
    vertical-align: top;
    font-size: 12px;
    margin-top: 6px;
}
 
.task-status .multiselect__element,
.task-type .multiselect__element {
  font-size: 12px;
}

.custom-field-list-item{
		padding-right: 10px!important;
		padding-left: 10px!important;
    font-size: 12px;
	}
	.custom-field-list-item .multiselect__tags {
		font-size: 12px;
	}
	.custom-field-list-item .multiselect__content-wrapper .multiselect__element{
		font-size: 12px;
	}
	.custom-field-list-item .multiselect__option {
		padding: 7px;
		min-height: 30px;
		line-height: 10px;
	}
  .custom-field-list-item .multiselect__tags input{
    font-size: 12px;
  }

.custom-calendar-wraper {
  padding-top: 17px;
  padding-right: 12px;
}



.inline-calendar {
  min-width: 140px;
  width: 100px;
}
.castom-field-swicher {
  color: #999;
  cursor: pointer;
}
.calendar-input-wraper {
  margin-top: 4px;
}
.calendar-input-img {    
    display: flex;
}
.calendar-input-img svg{ 
    height: 16px;
    width: 16px;
    margin-top: 7px;
    }
.task-duration input{
margin-top: 4px;
  
}
/* .main-task-description  {

} */
.task-id {
  color: #999;
}
.rigth-column {
  font-size: 12px;
}
/* .task-autor {
  
} */
.devider {
  display: flex;
  justify-content: space-between;
  min-height: 24px;
}
.divider-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.divider-section::before {
  content: "";
  flex-grow: 1;
  flex-shrink: 0;
  border-top: 1px solid rgba(0,0,0,.16);
}
.divider-section::after {
  content: "";
  flex-grow: 1;
  flex-shrink: 0;
  border-top: 1px solid rgba(0,0,0,.16);
}
.divider-content {
  flex-shrink: 0;
    margin: 0 8px;
}
.divider-section-left{
    flex-grow: 0;
    width: 20%;
}
.divider-section-center{
    flex-grow: 0;
    width: 60%;
}
.divider-section-rigth{
    flex-grow: 0;
    width: 20%;
}
.task-duration input{
  width: 50px;
  border-color: transparent;
  font-size: 13px ;
}
.task-duration input:hover{
  border-color: rgba(0,0,0,.7);
}
.task-duration span {
  font-size: 12px ;
  color: #999;
}
.task-description {
  border-top: 1px solid rgba(0,0,0,.16);
}
.chatmessages .message-title {
  font-weight: bold;
}
.task-comment .message-date{
  font-size: 11px;
  font-weight: normal;
  color: #aaa;
  margin-top: 4px;
  margin-left: 10px;
}
.message-user-image {
  display: block;
    width: 32px !important;
    height:32px !important;
    position: relative;
    z-index: 2;
    border-radius: 50%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.message-user-image img{
    width: 32px !important;
    height: 32px !important;
    max-width: 32px;
    border-radius: 50%;
}
.task-footer .input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid rgba(0,0,0,.16);
  border-radius: 4px;
  color: #4c4c4c;
  font-size: 13px;
  min-height: 48px;
  width: 100%;
  padding-right: 4px;
  padding-left: 12px;

}
.task-footer .msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  /* position: absolute; */
  right: 0;
  top: 11px;
  width: 33px;
}
/* .task-footer .write_msg {

} */

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
}
.taget-user-department {
    font-size: 11px;
    color: #aaa;
}
.taget-user-name {
    display: block;
    font-weight: bold;
}
.missingStatus {
    font-size: 11px;
}
.taget-user-avatar .option__image {
    display: inline-block;
    vertical-align: middle;
    max-height:45px;
    margin-right: 10px;
    width: 32px;
    /* height: 32px; */
    position: relative;
    z-index: 2;
    border-radius: 50%;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    cursor: pointer;
} 
.task-main-content, .task-temlate-wraper {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.v-popper__popper {
  opacity: initial !important;
  /* visibility:initial !important; */
}
/* // dropdown-menu */
 .dropdown__menu {
  top: 15px;
  right: 0;
  position: absolute;
  z-index: 10;
  height: 12rem;
  min-width: 300px;
  margin-top: 1rem;
  overflow-y: auto;
  padding: 2rem 1rem 2rem 0rem;
  border-radius: 12px;
  background-color: white;
  border: 1px solid var(--color-gray);
  background-clip: padding-box;}
  .dropdown__menu-nav {
    list-style: none;
    padding-left: 0;
  }
  .dropdown__menu-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);
    padding: 0.8rem 0 0.8rem 2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    border-radius: 0 50px 50px 0;
  }
   .dropdown__menu-link:hover{
     color: #17bf63;
     background-color: rgba(79, 192, 141, 0.1);
   }
   .dropdown__menu-svg {
     width: 1.5rem;
    height: 1.5rem;
   }
   .task-menu-button__svg {
    width: 1.2rem;
   }
   .dropdown__menu-text {
     font-weight: 300;
    margin-left: 1rem;
    margin-right: 1rem;
   }
   .slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.6s;
}
.slide-fade-enter,
.slide-fade-leave-active {
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(31px);
}
.slide-fade-leave-active {
  transform: translateX(-31px);
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.image-vs-icon {
  /* width: 100%;
  height: 100%; */
  /* background: rgba(0, 0, 0, 0.048); */
  margin: 0 10px;
  /* border: 1px solid #000; */
  /* border-radius: 5px; */
}
.image-vs-icon a {
  cursor: pointer;
}
.image-vs-icon img {
  margin-right: 10px;
  width:20px;
  float: left; 
  /* padding: 20px 20px ; */
  
}
.image-vs-icon span {
  /* padding-left: 20px; */
}
/* .icon {
  width: 40px;
} */
.action-container {
  position: absolute;
  width:118px;
  padding-top: 5px;
}
.action-container-img {
  width:148px;
}
.download-link {
  font-size: 18px;
  float: right;
  cursor: pointer;
  color: #4c4c4c94;
}
.download-link:hover {
  color: #000;
}
 .ck .ck-reset .ck-editor .ck-rounded-corners {
    min-height: 200px !important;
   
 }
 .ck-editor__editable {
    min-height: 200px !important;
    
 }

.ck-editor__editable_inline, .ck.ck-editor__editable_inline[dir=ltr] {
   min-height: 200px !important;
  
}

:host ::ng-deep .ck-editor__editable_inline {
   min-height: 200px !important;
  
}

</style>

