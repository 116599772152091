<template>
  <div id="cont_main" style="position: absolute; min-height: 98vh; max-height: 100vh; min-width: 98vw; max-width: 100vw; left: 0; background-color: #F1F1F1;">    
      <div style="display: flex; align-items: center; min-width: 100%; max-width: 100%; min-height: 100vh;">
          <div v-if="IsBitrix" class="col-md-5 offset-md-3 col-xs-12  p-0">
            <h2>{{$t('errors.erpTokenError')}}</h2>
        </div>        
        <div v-else class="col-md-6 offset-md-3 p-0" style="background-color: #fffefe;">
          <!-- <img :src="require('../assets/pict/401Unauthorized.svg')" style="width: 100%"/> -->
          <div class=" border p-2 w-100 hand" @click="signIn">
            <div class="row">

              <div class="col-md-12 d-flex justify-content-center">
                <img style="float:left; width: 80%;" :src="require('../assets/pict/me_logo.png')">
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <img style="float:left;  height: 2em" :src="require('../assets/gLogo.png')" alt="Login vs Google">
                <h4>{{$t('button.signIn')}}</h4>
              </div>

            </div>
          </div>
          
        </div>
      </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';

    export default {
      name: "Auth",
      components: {},
      data() {
          return {         
            resalt:null
          };
        },
        methods: {
          signBitrix(){
            this.gLogin()
                   .then(res => {
                     console.log(`res = ${res}`)
                    if(res != null && this.$store.getters.getTokenERP && this.$store.getters.getTokenERP !== "null"){                       
                      this.$router.push(sessionStorage.getItem('redirectPath') || '/');
                      sessionStorage.removeItem('redirectPath');  
                    } 
                    else return
                       
                  })          
          },
          signIn(){
            const provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth()
              .signInWithPopup(provider)
              .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;          
                this.resalt = result;
                this.$store.commit('setLang',"ua");
                this.$store.commit('setUser',result.user.uid);    
                this.$store.commit('setGoogleAuth', true);
                this.$store.commit('setUserEmail', result.user.email);
                localStorage.setItem('user_id',result.user.uid);                
                localStorage.setItem('user_email',result.user.email);  
                localStorage.setItem('is_google_auth',true);  
               // this.$router.push(sessionStorage.getItem('redirectPath') || '/');
               // sessionStorage.removeItem('redirectPath');
                           
              }).catch((error) => {               
                var errorCode = error.code;
                var errorMessage = error.message;              
                var email = error.email;              
                var credential = error.credential;
                console.log(errorCode, errorMessage ,email ,credential )              
              })
              .then(()=>{ 
                  let vm = this              
                  firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                  vm.$store.commit('setToken', idToken);
                  localStorage.setItem('token',idToken);   
                  vm.gLogin()
                   .then(res => {
                    if(res != null && vm.$store.getters.getTokenERP){                       
                      vm.$router.push(sessionStorage.getItem('redirectPath') || '/');
                      sessionStorage.removeItem('redirectPath');  
                    }         
                  })             
                  // console.log(idToken)
                }) 
                // .then(()=>{
                //   this.$router.push(sessionStorage.getItem('redirectPath') || '/');
                //   sessionStorage.removeItem('redirectPath');
                // })   
              })
          }     

        },
        computed:{
          IsBitrix(){        
            return this.$store.getters.getIsBitrix;
          },
        },
        watch:{},
        mounted () {          
          if (this.$store.getters.getIsBitrix){this.signBitrix()}
        },
        created(){
         
        },
    }
</script>

<style scoped>
</style>