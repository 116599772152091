<template lang="pug">
div.my-2
	div.col-md-12(v-if='options.hastablesView')
		div.vue-form-generator
			table.table(v-if="schema.fields")
				tbody
					tr(v-if="schema.fields")
						td(v-for='field in fields' :class='getTdClass(schema.fields)')
							form-tables(v-if='fieldVisible(field) && fieldInvisible(field)', :vfg="vfg", :field="field", :errors="errors", :model="model", :options="options", @validated="onFieldValidated", @model-updated="onModelUpdated")
	div.col-md-12(v-else-if='options.hasCardView')
		div.vue-form-generator
			div.container-fluid
				div.row
					div.col-md-6.card-el(v-for='field in fields')
						form-card(v-if='fieldVisible(field) && fieldInvisible(field)', :vfg="vfg", :field="field", :errors="errors", :model="model", :options="options", @validated="onFieldValidated", @model-updated="onModelUpdated")
	div(v-else-if='options.hasListView')
		div.vue-form-generator
			div.row.justify-content-start.custom-field-wraper
				div.col-md-4.mt-2.custom-field-list-item(v-for='field in fields')
					div.item-wrap
						form-list(v-if='fieldVisible(field) && fieldInvisible(field)', :vfg="vfg", :field="field", :errors="errors", :model="model", :options="options", @validated="onFieldValidated", @model-updated="onModelUpdated")
	div.col-md-6(v-else)
		div.vue-form-generator(v-if='schema != null')
			fieldset(v-if="schema.fields", :is='tag')
				template(v-for='field in fields')
					form-group(v-if='fieldVisible(field) && fieldInvisible(field)', :vfg="vfg", :field="field", :errors="errors", :model="model", :options="options", @validated="onFieldValidated", @model-updated="onModelUpdated")

			template(v-for='group in groups')
				fieldset(:is='tag', :class='getFieldRowClasses(group)')
					legend(v-if='group.legend') {{ group.legend }}
					template(v-for='field in group.fields')
						form-group(v-if='fieldVisible(field) && fieldInvisible(field)', :vfg="vfg", :field="field", :errors="errors", :model="model", :options="options", @validated="onFieldValidated", @model-updated="onModelUpdated")
</template>

<script>
import { get as objGet, forEach, isFunction, isNil, isArray } from "lodash";
import formMixin from "./formMixin.js";
import formGroup from "./formGroup.vue";
import formTables from "./formTables.vue";
import formCard from "./formCard.vue";
import formList from "./formList.vue";

export default {
	name: "formGenerator",
	components: { formGroup, formTables, formCard, formList },
	mixins: [formMixin],
	props: {
		schema: Object,

		model: Object,
		parrentModel: Object,

		options: {
			type: Object,
			default() {
				return {
					hastablesView:false,
					validateAfterLoad: false,
					validateAfterChanged: false,
					fieldIdPrefix: "",
					validateAsync: false,
					validationErrorClass: "error",
					validationSuccessClass: ""
				};
			}
		},

		multiple: {
			type: Boolean,
			default: false
		},

		isNewModel: {
			type: Boolean,
			default: false
		},

		tag: {
			type: String,
			default: "fieldset",
			validator: function(value) {
				return value.length > 0;
			}
		}
	},

	data() {
		return {
			vfg: this,
			errors: [] // Validation errors
		};
	},

	computed: {
		pModel(){
			return this.parrentModel;
		},
		fields() {
			let res = [];
			if (this.schema && this.schema.fields) {
				forEach(this.schema.fields, field => {
					if (!this.multiple || field.multi === true) res.push(field);
				});
			}

			return res;
		},
		groups() {
			let res = [];
			if (this.schema && this.schema.groups) {
				forEach(this.schema.groups.slice(0), group => {
					res.push(group);
				});
			}

			return res;
		}
	},

	watch: {
		// new model loaded
		model: function(newModel, oldModel) {
			if (oldModel === newModel)
				// model property changed, skip
				return;

			if (newModel != null) {
				this.$nextTick(() => {
					// Model changed!
					if (this.options.validateAfterLoad === true && this.isNewModel !== true) {
						this.validate();
					} else {
						this.clearValidationErrors();
					}
				});
			}
		}
	},

	mounted() {
		this.$nextTick(() => {
			if (this.model) {
				// First load, running validation if neccessary
				if (this.options.validateAfterLoad === true && this.isNewModel !== true) {
					this.validate();
				} else {
					this.clearValidationErrors();
				}
			}
		});
	},

	methods: {
		getTdClass(shema){
			let size =_.size(shema);
			return `item-${_.size(shema)}`

		},
		showfield(field){
			this.fieldVisible(field) ? true : false;
			
		},
		fieldInvisible(field){
			let self = this;
							
			if (!isNil(field.invisible) && field.invisible[0] == 'r'){	
					console.log("this.invisible = " + field.invisible)		
				let fun = new Function("model", field.invisible);				
				let res = fun.call(self, self.model, field, self);
				return res ? false : true;				
				}	
			if (_.isString(field.invisible) & !_.has(this.model, field.invisible )) {return true  } 
			if (isNil(field.invisible)) return true;
			return field.invisible;
		
		},
		// Get visible prop of field
		fieldVisible(field) {
				let self = this;
				let cModel = null
			if(_.isArray(self.model[field.model])){
				cModel = self.model[field.model];
				// console.log(cModel)
				// m.forEach(function(item, i, arr) {					
				// 	console.warn("visible model is array" +item.name)
				// }, self);
			}	
			if (!isNil(field.visible) && field.visible[0] == 'r'){
				let fun = new Function("model", field.visible);				
				return fun.call(self, self.model, field, self);
				}				
			
			if (_.isString(field.visible) & !_.has(this.model, field.visible )) {return false  } 
			if (isFunction(field.visible)) return field.visible.call(this, this.model, field, this);
			if (isNil(field.visible)) return true;
			return field.visible;
		},

		// Child field executed validation
		onFieldValidated(res, errors, field) {
			// Remove old errors for this field
			this.errors = this.errors.filter(e => e.field !== field.schema);

			if (!res && errors && errors.length > 0) {
				// Add errors with this field
				forEach(errors, err => {
					this.errors.push({
						field: field.schema,
						error: err
					});
				});
			}

			let isValid = this.errors.length === 0;
			this.$emit("validated", isValid, this.errors, this);
		},

		onModelUpdated(newVal, schema) {
			this.$emit("model-updated", newVal, schema);
		},

		// Validating the model properties
		validate(isAsync = null) {
			if (isAsync === null) {
				isAsync = objGet(this.options, "validateAsync", false);
			}
			this.clearValidationErrors();

			let fields = [];
			let results = [];

			forEach(this.$children, child => {
				if (isFunction(child.validate)) {
					fields.push(child.$refs.child); // keep track of validated children
					results.push(child.validate(true));
				}
			});

			let handleErrors = errors => {
				let formErrors = [];
				forEach(errors, (err, i) => {
					if (isArray(err) && err.length > 0) {
						forEach(err, error => {
							formErrors.push({
								field: fields[i].schema,
								error: error
							});
						});
					}
				});
				this.errors = formErrors;
				let isValid = formErrors.length === 0;
				this.$emit("validated", isValid, formErrors, this);
				return isAsync ? formErrors : isValid;
			};

			if (!isAsync) {
				return handleErrors(results);
			}

			return Promise.all(results).then(handleErrors);
		},

		// Clear validation errors
		clearValidationErrors() {
			this.errors.splice(0);

			forEach(this.$children, child => {
				child.clearValidationErrors();
			});
		},
	}
};
</script>

<style lang="scss">

.vue-form-generator {
	* {
		box-sizing: border-box;
	}	
	.item-2 {
	width:49%;
	}
	.item-3 {
	width:33%;
	}
	.item-4 {
	width:24%;
	}
	.card-el {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}
	span.help {
		margin-left: 0.3em;
		position: relative;

		.icon {
			display: inline-block;
			width: 16px;
			height: 14px;
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAA+UlEQVQ4ja3TS0oDQRAG4C8+lq7ceICICoLGK7iXuNBbeAMJuPVOIm7cqmDiIncIggg+cMZFaqCnZyYKWtB0df31V1VXdfNH6S2wD9CP8xT3KH8T9BiTcE7XBMOfyBcogvCFO9ziLWwFRosyV+QxthNsA9dJkEYlvazsQdi3sBv6Ol6TBLX+HWT3fcQZ3vGM5fBLk+ynAU41m1biCXvhs4OPBDuBpa6GxF0P8YAj3GA1d1qJfdoS4DOIcIm1DK9x8iaWeDF/SP3QU6zRROpjLDFLsFlibx1jJaMkSIGrWKntvItcyTBKzCcybsvc9ZmYz3kz9Ooz/b98A8yvW13B3ch6AAAAAElFTkSuQmCC");
			background-repeat: no-repeat;
			background-position: center center;
		} 
		.helpText {
			background-color: #fafafa;
			bottom: 30px;
			color: rgba(22, 22, 22, 0.671);
			display: block;
			left: 0px;			
			opacity: 0;
			padding: 20px;
			pointer-events: none;
			position: absolute;
			text-align: justify;
			min-width: 220px;			
			transition: all 0.25s ease-out;
			box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
			border-radius: 6px;

			a {
				font-weight: bold;
				text-decoration: underline;
			}
		} 
		.helpText:before {
			bottom: -20px;
			content: " ";
			display: block;
			height: 20px;
			left: 0;
			position: absolute;
			width: 100%;
		}

		&:hover .helpText {
			opacity: 1;
			pointer-events: auto;
			transform: translateY(0px);
		}
	}

	.field-wrap {


		.buttons {
			white-space: nowrap;
			margin-left: 4px;
		}

		button,
		input[type="submit"] {			
			display: inline-block;		

			&:not(:last-child) {
				margin-right: 4px;
			}
			&:disabled {
				opacity: 0.6;
				cursor: not-allowed;
			}
		} 
	} 

	.hint {
		font-style: italic;
		font-size: 0.8em;
	} 
	.custom-field-wraper{
		padding: 10px 20px;
	}
	.item-wrap {
		padding-right: 10px!important;
		padding-left: 10px!important;
	}
} 
</style>
