var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal',{attrs:{"classes":['modaltable'],"name":_vm.modalPropsid,"draggable":false,"adaptive":true,"scrollable":true,"minWidth":_vm.modalProps.minWidth,"resizable":true,"height":_vm.modalProps.hide}},[_c('div',{staticClass:"window-header p-1"},[_c('h2',[_vm._v(_vm._s(_vm.schema.label))]),_c('a',{staticClass:"btn",staticStyle:{"position":"absolute","right":"10px","top":"0.5em"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"icon icon-cross",staticStyle:{"font-size":"18px"}})])]),(_vm.schema.selectOptions.multiple)?_c('div',{staticClass:"container",attrs:{"id":"modalSelect"}},[_c('v-select',{staticClass:"loader ",attrs:{"options":_vm.options,"label":"Title","multiple":_vm.schema.selectOptions.multiple},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" "+_vm._s(_vm.$t('request.errors.noResalt'))+" ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.$t('request.errors.startSeach')))])]}},{key:"option",fn:function(){return [_c('h5',{staticStyle:{"margin":"0"}})]},proxy:true}],null,false,3226417149),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):_vm._e(),_c('vue-good-table',{staticClass:"p-1",attrs:{"columns":_vm.columns,"rows":_vm.options,"fixed-header":true,"compactMode":"","search-options":{
                enabled: true,
                placeholder: _vm.$t('request.loader.searchByTables'),
            },"pagination-options":{
                enabled: true,
                perPageDropdownEnabled: false,
                nextLabel: _vm.$t('request.loader.next'),
                prevLabel: _vm.$t('request.loader.prev')
            }},on:{"on-row-click":_vm.onRowClick,"on-row-dblclick":_vm.onRowDoubleClick}})],1),_c('v-select',{staticClass:"loader ",attrs:{"options":_vm.options,"label":"Title","multiple":_vm.schema.selectOptions.multiple,"id":_vm.getFieldID(_vm.schema)},on:{"search":_vm.onSearch,"option:selected":_vm.onSelectead},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
            var searching = ref.searching;
return [(searching)?[_vm._v(" "+_vm._s(_vm.$t('request.errors.noResalt'))+" ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.$t('request.errors.startSeach')))])]}},{key:"option",fn:function(options){return [_c('h5',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(options.Title))]),(options.Label)?_c('em',[_vm._v(_vm._s(options.Label))]):_vm._e(),(options.description)?_c('em',[_vm._v(_vm._s(options.description))]):_vm._e(),_c('br')]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_c('h5',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(option.Title))]),(_vm.options.Label)?_c('em',[_vm._v(_vm._s(_vm.options.Label))]):_vm._e(),(option.description)?_c('em',[_vm._v(_vm._s(option.description))]):_vm._e()])]}},(_vm.schema.selectOptions.showTables)?{key:"open-indicator",fn:function(ref){
            var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('a',{staticClass:"btn",on:{"click":_vm.showModal}},[_c('i',{staticClass:"icon icon-books",staticStyle:{"font-size":"14px"}})])])]}}:null],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }