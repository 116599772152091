<template>
  <div v-if="isVisible">
    <div class="loader-overlay" id="point-loader" style="position: fixed !important;">
      <div style="height:100%;
                  display:flex;
                  justify-content: center;
                  align-items:center;">
        <half-circle-spinner :animation-duration="1000" :size="60" :color="'#e20101'" />
      </div>
    </div>
  </div>
</template>

<script>
  import { HalfCircleSpinner } from "epic-spinners";
  export default {
    name: "Loader",
    components: { HalfCircleSpinner },
    props: {
      isVisible: {type: Boolean, required: true},
      text: {type: String, required: false, default: ""},
    }
  }
</script>

<style scoped>
  .loader-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    background: #000000;
    z-index: 50000;
  }
</style>