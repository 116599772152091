<template>
    <div>
        <modal :classes="['modaltable']" :name="modalPropsid" :draggable="false" :adaptive="true" :scrollable="true" :minWidth="modalProps.minWidth" :resizable="true" :height="modalProps.hide">
            <div class="window-header p-1">
                <h2>{{schema.label}}</h2>
                <a  @click="closeModal" class="btn" style="position: absolute; right: 10px; top:0.5em;"><i class="icon icon-cross" style="font-size:18px"></i></a>
            </div>
            <div class="container" id="modalSelect" v-if="schema.selectOptions.multiple">
                <v-select :options="options" label="Title" :multiple="schema.selectOptions.multiple" v-model="value"  @search="onSearch"   class="loader " >
                    <template v-slot:no-options="{searching }">
                        <template v-if="searching">
                           {{$t('request.errors.noResalt')}}
                        </template>
                        <em style="opacity: 0.5;" v-else>{{$t('request.errors.startSeach')}}</em>
                    </template>
                    <template #option="">
                        <h5 style="margin: 0"></h5>            
                    </template>                    
                </v-select> 
            </div>
                <vue-good-table :columns="columns" :rows="options" :fixed-header="true" compactMode  @on-row-click="onRowClick"  @on-row-dblclick="onRowDoubleClick"
                :search-options="{
                    enabled: true,
                    placeholder: $t('request.loader.searchByTables'),
                }"
                :pagination-options="{
                    enabled: true,
                    perPageDropdownEnabled: false,
                    nextLabel: $t('request.loader.next'),
                    prevLabel: $t('request.loader.prev')
                }"            
                class="p-1">   
                </vue-good-table>
        </modal>
        <v-select :options="options" label="Title" :multiple="schema.selectOptions.multiple" v-model="value"  @search="onSearch" @option:selected="onSelectead" :id="getFieldID(schema)"   class="loader " >
            <template v-slot:no-options="{searching }">
                <template v-if="searching">
                    {{$t('request.errors.noResalt')}}
                </template>
                <em style="opacity: 0.5;" v-else>{{$t('request.errors.startSeach')}}</em>
            </template>
            <template #option="options">
                <h5 style="margin: 0">{{options.Title}}</h5>
                <em v-if="options.Label">{{options.Label}}</em>
                <em v-if="options.description">{{options.description}}</em><br/>
                
            </template>
            <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                <h5 style="margin: 0">{{option.Title}}</h5>
                <em v-if="options.Label">{{options.Label}}</em>
                <em v-if="option.description">{{option.description}}</em>
                
                </div>
            </template>
            <template #open-indicator="{ attributes }" v-if="schema.selectOptions.showTables">
                <span v-bind="attributes">
                    <a @click="showModal" class="btn"><i class="icon icon-books" style="font-size:14px"></i></a>
                </span>
            </template>        
        </v-select> 
    </div>
   
</template>

<script>
import abstractField from "../abstractField";

export default {
    props: {        
    },
	mixins: [
        abstractField
    ],    
    data() {
        return {
            searchValue:null,
            selecteadOptions:false,  
            localData:false,                   
            options:[ ],           
            modalProps:{
                hide:"auto",
                minWidth:300,
                id:this.schema.id                
            }, 
            selecteadRowInTables:null,           
            onSearchParams: this.schema.onSearchParams,
            attributes: {
                'ref': 'openIndicators',
                'role': 'presentation',
                'class': 'vs__open-indicator',
                },           
            columns: [                    
                    {
                    label: this.$t('request.loader.title'),
                    field: 'Title',          
                    }                    
                ],
            
            }
        },    
    methods: {
        onRowDoubleClick(params){
            // params.row - row object 
            // params.pageIndex - index of this row on the current page.
            // params.selected - if selection is enabled this argument 
            // indicates selected or not
            // params.event - click event
            // this.selecteadRowInTables = params.row
            // console.log("clik ")
        },
        onRowClick(params){
                // console.log(params.row)
            if (!this.schema.selectOptions.multiple){
                this.value = params.row;          
                this.$modal.hide(this.modalPropsid)
                this.selecteadOptions = true;               
            }
            else {
                let v = this.value;
                v.push(params.row);
                this.value = v; 
                this.selecteadOptions = true;              
            } 
        },
        checkError(){
             let parrantFieldId =this.schema.parrantFieldId;
            // error
             if(this.schema.parrantFieldId && !this.model[parrantFieldId] || this.schema.parrantFieldId && this.model[parrantFieldId] && !this.model[parrantFieldId]["ID"]){
                // console.log(`parantFieldID = ${this.schema.parrantFieldId}`)
                let fieldSchema = this.searchElement(parrantFieldId, this);
                let o = {};
                    o.error = this.$t('request.errors.fieldIsRequired');
                    o.field = fieldSchema;  
                if(fieldSchema){
                    this.vfg.errors[0] = o;
                    this.validate();    
                    return;
                }                                   
                }
                if(this.vfg.pModel && this.schema.parrantFieldId && !this.vfg.pModel[parrantFieldId] || this.vfg.pModel && this.schema.parrantFieldId && this.vfg.pModel[parrantFieldId] && !this.vfg.pModel[parrantFieldId]["ID"] ){
                    console.log("has parrent parametrs" +parrantFieldId);                    
                     this.$root.$emit('ErrorInChild', parrantFieldId);            
                    return;
                } 
            // and error 

        },
        showModal(){
            let parrantFieldId =this.schema.parrantFieldId;            
            // error
             if(this.schema.parrantFieldId && !this.model[parrantFieldId] || this.schema.parrantFieldId && this.model[parrantFieldId] && !this.model[parrantFieldId]["ID"]){ 
                let fieldSchema = this.searchElement(parrantFieldId, this);
                let o = {};
                    o.error = this.$t('request.errors.fieldIsRequired');
                    o.field = fieldSchema;  
                if(fieldSchema){
                    this.vfg.errors[0] = o;
                    this.validate();    
                    return;
                }                                   
                }
                if(this.vfg.pModel && this.schema.parrantFieldId && !this.vfg.pModel[parrantFieldId] || this.vfg.pModel && this.schema.parrantFieldId && this.vfg.pModel[parrantFieldId] && !this.vfg.pModel[parrantFieldId]["ID"] ){      
                    this.$root.$emit('ErrorInChild', parrantFieldId);               
                    return;
                } 
            // and error    
            //  if (this.options.length <= 0  ){
                this.options = [];
                let url = this.schema.onSearch;                            
                let DataRequest = {
                    "ValueField": "#DATA",
                    "ID_Field": this.schema.model                    
                 }
                // let parrantFieldId = this.schema.parrantFieldId;
                
                if (this.schema.parrantFieldId) {DataRequest.parrantFieldId = this.schema.parrantFieldId};                
                if (this.schema.childFieldId) {DataRequest.childFieldId = this.schema.childFieldId};
                if (this.model[parrantFieldId]) {DataRequest.parrantValue = this.model[parrantFieldId]};
                if (this.vfg.pModel && this.vfg.pModel[parrantFieldId]) {DataRequest.parrantValue = this.vfg.pModel[parrantFieldId]};

                 var p = {}
                  _.forEach(this.schema.onSearchParams, function(value) {
                        _.forIn(value,function(el, key){
                        p[key] = el
                    })
                      })                
                this.$store.commit('setLoading',true);
                this.getFielfOptions("/"+url, {"DataRequest": DataRequest, "parametrs": p})
                .then(res => { 
                this.options = res.data.Data   
                this.$modal.show(this.modalPropsid) 
                this.$store.commit('setLoading',false);     
            });
            //}
            // else {this.$modal.show(this.modalPropsid)}
        },
        closeModal(){
            this.$modal.hide(this.modalPropsid)
        },

        getData(){
            function send() {
                if (!_.isEmpty(this.functions)  ){
                console.log("function  " + this.functions +"  colead vith values  " + this.values)
            }
            _.debounce(send, 300)()
            }
            
        },
        onSelectead(selectedOption){
            this.selecteadOptions = true;
            if (!this.schema.selectOptions.multiple){
                this.options = []
            }
            // this.$emit("option:selected", selectedOption);
            // console.log("Opions = " + selectedOption)
            // console.log("schema = " + this.schema.id)
        },
        searchElement(id,vm){   
            let el;        
              _.forEach(vm.vfg.fields, function(value) {
                        if (value.model == id) {
                            el = value;
                        }
                      }) 
            return el;                
        },
        onSearch(search, loading) { 
            this.searchValue = search;
            console.log("search = " + search)          
            let parrantFieldId =this.schema.parrantFieldId;
            if(this.schema.parrantFieldId && !this.model[parrantFieldId] || this.schema.parrantFieldId && this.model[parrantFieldId] && !this.model[parrantFieldId]["ID"]){ 
                let fieldSchema = this.searchElement(parrantFieldId, this)
                let o = {};
                o.error = this.$t('request.errors.fieldIsRequired');
                o.field = fieldSchema;
                this.vfg.errors[0] = o;
                //this.showNotification('custom-error','Error',`Заповніть поле "${fieldSchema.label}"`);
                //self.schema.onValidationError(this.model, this.schema, this.vfg.errors, 'change');
                this.validate();                                       
                return;                
            }
            if(this.vfg.pModel && this.schema.parrantFieldId && !this.vfg.pModel[parrantFieldId] || this.vfg.pModel && this.schema.parrantFieldId && this.vfg.pModel[parrantFieldId] && !this.vfg.pModel[parrantFieldId]["ID"] ){      
                    this.$root.$emit('ErrorInChild', parrantFieldId);               
                    return;
                } 
            if (!_.isNil(this.schema.onSearch) & search.length){
                let url = this.schema.onSearch
                let params = this.schema.onSearchParams
                loading(true);                
                 this.search(loading, search, url, params, this)                          
            } 
                   
           },            
            search: _.debounce((loading, search, url, params, vm) => {                
                let DataRequest = {
                    "ValueField": vm.searchValue,
                    "ID_Field": vm.schema.model,                           
                 } 
                  console.log("DataRequest = " + DataRequest.ValueField)
                let parrantFieldId =vm.schema.parrantFieldId;
                if (vm.schema.parrantFieldId) {DataRequest.parrantFieldId = vm.schema.parrantFieldId};
                if (vm.model[parrantFieldId]) {DataRequest.parrantValue = vm.model[parrantFieldId]};
                if (vm.schema.childFieldId) {DataRequest.childFieldId = vm.schema.childFieldId};               
                 var p = {}
                  _.forEach(params, function(value) {
                        _.forIn(value,function(el, key){
                        p[key] = el
                    })
                      })             
                
                vm.getFielfOptions("/"+url, {"DataRequest": DataRequest, "parametrs": p})
                .then(res => { 
                    vm.options = res.data.Data   
                    loading(false);        
                    //    console.log(res.data.Data)
                    });
                    }, 2000)
    },
    watch: {
        selecteadOptions: function (val) {
            if (!this.schema.selectOptions.multiple && val && this.schema.updateField) {
                console.log("send request")
                this.$root.$emit('updateFieldsById', [this.schema.updateField, this.schema.id, this.schema.childFieldId]);
                this.selecteadOptions = false
            }
            }
    },
    computed: {
        modalPropsid(){
            return _.toString(this.schema.id)
        }
        
        // options() {
		// 	let values = this.schema.values;
		// 	if (typeof values == "function") {
		// 		return values.apply(this, [this.model, this.schema]);
		// 	} else {
		// 		return values;
		// 	}
		// },
    },
    mounted(){
        
    },


};
</script>

<style lang="scss">
.modaltable tbody tr:first-child{
margin-top: 40px;
}
.vs__dropdown-toggle{
    min-height: 47px;
}
.loader {
    padding-bottom: 20px;
    min-width: 100%;
}
 #modalSelect .vs__dropdown-menu{
    display: none;
}
#modalSelect .loader {
    padding-bottom: 4px;
}
table.vgt-table td, table.vgt-table th  {
    padding: .50em .50em .50em .50em;
    
}
.vgt-wrap__footer {    
    padding: .50em;
   
}
</style>