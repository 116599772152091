<template>
  <div ref="gantt" ></div>
</template>
<script>
import dayjs from 'dayjs'
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
// import { ContextMenu as MenuDHX, TreeCollection } from "dhx-suite-package";
import 'dhtmlx-gantt'
gantt.plugins({
		grouping: true,
		auto_scheduling: true,
        tooltip: true,
        critical_path: true,
		marker: true,
		fullscreen: true,
		drag_timeline: true,
		keyboard_navigation: false,	
	}); 
export default {
  name: 'gantt',
  props: {
    tasks: {
      type: Object,
      default () {
        return {data: [], links: []}
      }
    },
    resources:{
        type: Array,
        default () {
            return []
        }
    },
    setings:{
        type: Object,
        default () {
            return []
        }
    },
	menuitems:{
		type:Object,
		default () {
        return {}
      }
	},
	displaytask:"",
	showResorsPanel:"",
	groupby:""
  },
  data() {
	  return {
		  testobg:null,
		  menu:null,
		  selectedTask:null,
		//   filterTask:this.$props.displaytask,
		//   groupBy:this.$props.groupby,		 
	  }
  },
  methods: {	
	  initGanttConfig(){
		  // gantt.setWorkTime({hours: [8, 13, 14, 17]});
		gantt.setWorkTime({hours: [8, 16]});	
		// gantt.setWorkTime({date: new Date(2021, 11, 27), hours: false});//specific working day		
		//gantt.setWorkTime({date:dayjs("12-27-2021", "MM-DD-YYYY").$d, hours: false});//specific working day	
		function setDayOff(self){
			self.$props.setings.globalDayOff.forEach(element => {
            	gantt.setWorkTime({date:dayjs(element, "YYYY-MM-DD").$d, hours: false});
          });
		}
		function setCastomCalendar(calendars){
			let cl  = {
						id:'99999999-9999-9999-9999-999999999999',
						worktime: {
							hours: ["00:00-24:00"],
							days: [1, 1, 1, 1, 1, 1 ,1]
						}
					}
			gantt.addCalendar(cl);			
			// console.log(typeof(calendars))			
			if (!_.isNil(calendars)) {
				for (let key in calendars) {
					let calendarObj = {
						id:calendars[key].id,
						worktime: {
							hours: ["8:00-16:00"],
							days: [0, 1, 1, 1, 1, 1 ,0]
						}
					}
					gantt.addCalendar(calendarObj);
            		let calendarInstans = gantt.getCalendar(calendars[key].id);
					calendars[key].days.forEach(el=>{
						calendarInstans.setWorkTime({date : dayjs(el.day, "YYYY.MM.DD").$d,hours : ["0:01-23:59"]});					
					})			
				}				
			}
		}
		setCastomCalendar(this.$props.setings.customCalendar)
		setDayOff(this);	
		var dateToStr = gantt.date.date_to_str(gantt.config.task_date);
		var today = new Date();
		gantt.addMarker({
			start_date: today,
			css: "today",
			text: "Today",
			title: "Today: " + dateToStr(today)
		});
		// console.log(`users = ${gantt.serverList('users')}`);
		gantt.i18n.setLocale("ua");
		gantt.i18n.setLocale({
			labels: {
				new_task: "Нова задача"
			}
		});		
		gantt.config.scales = [
			{unit: "month", step: 1, format:"%M %Y"},
			{unit: "day", step: 1, format: "%d, %l"},
			{unit: "hour", step: 1, format: "%H"},
		];
		gantt.config.scale_height = 20 * 3;
		gantt.config.min_column_width = 18;
        gantt.config.date_format = "%Y-%m-%d %H:%i:%s"; 
        // gantt.config.date_format = "%d.%m.%y %H:%i:%s"; 
        gantt.config.duration_unit = "hour";
		gantt.config.duration_step = 1; 
		gantt.config.round_dnd_dates = false;  
		gantt.config.show_tasks_outside_timescale = true;
        gantt.locale.labels.section_owner = "Owner";
		// gantt.config.fit_tasks = true;
        gantt.config.show_unscheduled = true;
		gantt.config.row_height = 24;
		gantt.config.drag_project = false;		
	gantt.config.smart_rendering = true;		
	gantt.config.auto_scheduling = true;
	gantt.config.auto_scheduling_strict = true;
	gantt.config.auto_scheduling_initial = false; //
	gantt.config.work_time = true; // removes non-working time from calculations 
	gantt.config.skip_off_time = false; // hides non-working time in the chart
	gantt.config.correct_work_time = true;	
	var dateEditor = {type: "date", map_to: "start_date", min: new Date(), max: new Date(2023, 0, 1)};
	var durationEditor = {type: "number", map_to: "duration", min:0, max: 500};
	var textFilter = "<span class='search_label'>search</span><input class='search' data-text-filter type='text' oninput='gantt.$doFilter(this.value)' onclick='gantt.$filterOnClick(event)'>"
    gantt.config.columns = [
		{name: "text", tree: true, width: 250, resize: true, label: textFilter, align:"left"},
		{name: "start_date", align: "center", width: 90, label: "Початок", resize: true, editor: dateEditor,
		 template: function (task) {					
			return dayjs(task.start_date).format('DD.MM.YY');}
		},
		{name: "owner", align: "center", width: 80, label: "Відповідальний", resize: true,
			template: function (task) {
				if (task.type == gantt.config.types.project) {
					return "";
				}
				let result = "";
				let store = gantt.getDatastore("resource");
				let owners = task[gantt.config.resource_property];
				// console.log(task.text);
				// console.log(owners);
				if (!owners || !owners.length) {
					return "Не розподілені";
				}
				if(owners.length == 1 ){
					let owner = store.getItem(owners[0]).text;
					let ownerLastname = _.split(owner, ' ')
					// console.log(ownerLastname[1]);
					return ownerLastname[1]
				}
				if (owners.length > 1){
					owners.forEach(function(ownerId) {
						let owner = store.getItem(ownerId);
						if (!owner)
							return;
						result += "<div class='owner-label' title='" + owner.text + "'>" + owner.text.substr(0, 1) + "</div>";
					});
					return result;
				}
			}
		},
		{name: "duration", width: 40, align: "center", label: "Тривалість", resize: true, editor: durationEditor},
		{name: "and_date", align: "center", width: 60, label: "Кінець", resize: true,
		 template: function (task) {					
			return dayjs(task.end_date).format('DD.MM.YY');}
		},
		{name: "add", width: 44}
	];
    gantt.config.resource_store = "resource";
	gantt.config.resource_property = "user_id";
	gantt.config.order_branch = "marker"; // "marker" - for bes perfomanse
	gantt.config.order_branch_free = true;
	gantt.config.open_tree_initially = true;
	gantt.config.sort = true;
		
	gantt.config.drag_timeline = {
			ignore:".gantt_task_line, .gantt_task_link",
			useKey: false
		};
      },
	initResourcPanel(){
		function shouldHighlightResource(resource){
		var selectedTaskId = gantt.getState().selected_task;
		if(gantt.isTaskExists(selectedTaskId)){
			var selectedTask = gantt.getTask(selectedTaskId),
				selectedResource = selectedTask[gantt.config.resource_property];
			if(resource.id == selectedResource){
				return true;
			}else if(gantt.$resourcesStore.isChildOf(selectedResource, resource.id)){
				return true;
			}
		}
		return false;
	}
	function getResourceTasks(resourceId) {
		var store = gantt.getDatastore(gantt.config.resource_store),
			field = gantt.config.resource_property,
			tasks;
		if (store.hasChild(resourceId)) {
			tasks = gantt.getTaskBy(field, store.getChildren(resourceId));
		} else{
			tasks = gantt.getTaskBy(field, resourceId);
		}
		return tasks;
	}
		var resourceTemplates = {
		grid_row_class: function(start, end, resource){
			var css = [];
			if(gantt.$resourcesStore.hasChild(resource.id)){
				css.push("folder_row");
				css.push("group_row");
			}
			if(shouldHighlightResource(resource)){
				css.push("highlighted_resource");
			}
			return css.join(" ");
		},
		task_row_class: function(start, end, resource){
			var css = [];
			if(shouldHighlightResource(resource)){
				css.push("highlighted_resource");
			}
			if(gantt.$resourcesStore.hasChild(resource.id)){
				css.push("group_row");
			}
			return css.join(" ");
		}
	};
		var resourceConfig = {
		scale_height: 15,
		scales: [
			{unit: "day", step: 1, date: "%d %D"}
		],
		columns: [
			{
				name: "name", label: "Name", tree:true, width:250, template: function (resource) {
					return resource.text;
				}, resize: true
			},			
			{
				name: "workload", label: "Workload", align:"center", template: function (resource) {
					var tasks = getResourceTasks(resource.id);					
					let shedukedTask = _.filter(tasks, { 'type': "tasks", 'scheduled': true })					
					var totalDuration = 0;
					shedukedTask.forEach(function(task){
						totalDuration += task.duration;
					});				
					return totalDuration + "h";
				}, resize: true
			},
		]
	};

	gantt.config.layout = {
		css: "gantt_container",
		rows: [
			{
				gravity: 2,
				cols: [
					{view: "grid", group:"grids", scrollY: "scrollVer"},
					{resizer: true, width: 1},
					{view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
					{view: "scrollbar", id: "scrollVer", group:"vertical"}
				]
			},			
			{ resizer: true, width: 1, next: "resources"},
			{
				height: 30,
				cols: [
					// { html: "<label>Користовачі<select class='resource-select'></select>", css :"resource-select-panel", group: "grids"},
					{ html:"", group:"grids"},
					{ resizer: true, width: 1},
					{ html:"<label class='active' >Hours per day <input checked type='radio' name='resource-mode' value='hours'></label>" +
					"<label>Tasks per day <input type='radio' name='resource-mode' value='tasks'></label>", css:"resource-controls"}
				]
			},
			{
				gravity:1,
				id: "resources",
				config: resourceConfig,
				templates: resourceTemplates,
				cols: [
					{ view: "resourceGrid", group:"grids", scrollY: "resourceVScroll" },
					{ resizer: true, width: 1},
					{ view: "resourceTimeline", scrollX: "scrollHor", scrollY: "resourceVScroll"},
					{ view: "scrollbar", id: "resourceVScroll", group:"vertical"}
				]
			},
			{view: "scrollbar", id: "scrollHor"}
		]
	};
	},
	// initContextMenu(){
	// 	let self = this;
	// 	var menu = [			
	// 		{
	// 			value: "Змінити",
	// 			id:"edit",
	// 			countColor: "success",
				
	// 		},											
	// 	]		
	// 	this.menu = new MenuDHX(null, {css: "dhx_widget--bg_gray"});
	// 	this.menu.data.parse(menu);
	// 	this.menu.events.on("Click", function(id,e){			
	// 		self.$emit(`context-menu-click`, id, e, self.selectedTask);
	// 	})
	// },
	initZooming(){
		var zoomConfig = {
				levels: [
				{
				name:"hour",
				scale_height: 20 * 2,
				min_column_width:18,
				scales:[
					{unit: "day", step: 1, format: "%d, %l"},
					{unit: "hour", step: 1, format: "%H"},
				]
				},
				{
					name:"day",
					scale_height: 33,
					min_column_width:80,
					scales:[						
						{unit: "month", step: 1, format: "%M"},
						{unit: "day", step: 1, format: "%d %D"}
					]
				},
				{
					name:"week",
					scale_height: 50,
					min_column_width:50,
					scales:[
					{unit: "week", step: 1, format: function (date) {
					var dateToStr = gantt.date.date_to_str("%d %M");
					var endDate = gantt.date.add(date, 6, "day");					
					return  dateToStr(date) + " - " + dateToStr(endDate);
					}},
					{unit: "day", step: 1, format: "%j %D"}
					]
				},
				{
					name:"month",
					scale_height: 50,
					min_column_width:120,
					scales:[
						{unit: "month", format: "%F, %Y"},
						{unit: "week", format: "Week #%W"}
					]
					},
					{
					name:"quarter",
					height: 50,
					min_column_width:90,
					scales:[
					{unit: "month", step: 1, format: "%M"},
					{
					unit: "quarter", step: 1, format: function (date) {
						var dateToStr = gantt.date.date_to_str("%M");
						var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
						return dateToStr(date) + " - " + dateToStr(endDate);
					}
					}
					]},
					{
					name:"year",
					scale_height: 50,
					min_column_width: 30,
					scales:[
						{unit: "year", step: 1, format: "%Y"}
					]}
				],
				useKey: "ctrlKey",
				trigger: "wheel",
				activeLevelIndex:1,
				element: function(){
					return gantt.$root.querySelector(".gantt_task");
				}
			};
		gantt.ext.zoom.init(zoomConfig);		
	},
	initGanttTemplates(){
		function getResourseDayLoad(today, task_start, task_and, task_duration){
				function calculateLoadForDay(taskStart, taskDuration, andOfDay){	
					let dayFreeTime = andOfDay - taskStart.hour() ;	
					return dayFreeTime > taskDuration ? taskDuration : dayFreeTime					 
				}
				let workHour = 8;
				let andOfkDay = 16;				
				let forDay = dayjs(today);
				let taskStart = dayjs(task_start)
				let dayStartHour = taskStart.hour(8)	
				let taskAnd = dayjs(task_and)				
				var res = 0;				
				if(forDay.isSame(taskStart , 'day')){					
					if (task_duration <= workHour && taskStart.isSameOrBefore(dayStartHour, 'day')) {
						res = calculateLoadForDay(taskStart, task_duration, andOfkDay)
						// console.log('variant1')
					}
					else if (task_duration > workHour && taskStart.isSameOrBefore(dayStartHour, 'day')) {
						res = calculateLoadForDay(taskStart, task_duration, andOfkDay)
						// console.log('variant2')
					}
					else  {
						// console.log('variant3')
						let andOfworkDay = taskStart.hour(andOfkDay)
						let st = andOfworkDay.format('HH')
						let end = taskStart.format('HH')
						let brekf = taskStart.hour(13)
						taskStart.isBefore(brekf, 'hour') ? res += st -end -1 :	res += st -end								
						 
					}
				}
				else if(forDay.isSame(taskAnd , 'day')){
					if (taskStart.isSameOrBefore(dayStartHour, 'hour')){
						// console.log('variant4')
						// res = task_duration - (parseInt(task_duration/workHour)*workHour)
						let t = task_duration - (parseInt(task_duration/workHour)*workHour)
						  t>0? res=t :res=workHour;						
					}
					else {	
						// console.log('variant 5')					
						let andOfworkDay = taskStart.hour(andOfkDay)
						let st = andOfworkDay.format('HH')
						let end = taskStart.format('HH')											
						let firstDayTime = st-end					
						// let brekf = taskStart.hour(13)
						// if( taskStart.isSameOrBefore(brekf, 'hour')) {
						// 	firstDayTime = st-end -1
						// }
						let tmpDuration = task_duration - firstDayTime
						tmpDuration%workHour !=0 ? res = tmpDuration%workHour :	res = workHour;							
						// res = tmpDuration%workHour
					}
				}
				else { 
					// console.log('variant 6')
					res = workHour
					}
				return res;
			}
		var resourceMode = "hours";
		gantt.templates.tooltip_date_format = gantt.date.date_to_str("%F %j, %Y");		
		function shouldHighlightTask(task){
			var store = gantt.$resourcesStore;
			var taskResource = task[gantt.config.resource_property],
				selectedResource = store.getSelectedId();
			if(taskResource == selectedResource || store.isChildOf(taskResource, selectedResource)){
				return true;
				}
		}
		gantt.templates.task_class = function (start, end, task) {
			if (task.type == 'project' && task.displayByStatus){				
				switch (task.statusID) {
					case "-1":
						return "project_expired";
						break;
					case "1":
						return "project_new";
						break;
					case "2":
						return "project_pending";
						break;
					case "3":
						return "project_in_progres";
						break;
					case "5":
						return "project_completed";
						break;
					case "6":
						return "project_deferred";
						break;
					case "7":
						return "project_declined";
						break;
				}
			}
			
			switch (task.statusID) {
				case "-1":
					return "expired";
					break;
				case "1":
					return "new";
					break;
				case "2":
					return "pending";
					break;
				case "3":
					return "in_progres";
					break;
				case "5":
					return "completed";
					break;
				case "6":
					return "deferred";
					break;
				case "7":
					return "declined";
					break;
			}
		};
        gantt.templates.grid_row_class = function(start, end, task){			
            var css = [];
            if(gantt.hasChild(task.id)){
                css.push("folder_row");
            }
            if(task.$virtual){
                css.push("group_row")
            }
            if(shouldHighlightTask(task)){
                css.push("highlighted_resource");
            }
            return css.join(" ");
        };
        gantt.templates.task_row_class = function(start, end, task){
            if(shouldHighlightTask(task)){
                return "highlighted_resource";
            }
            return "";
        };
		var dateToStr = gantt.date.date_to_str("%j %F %H");

	gantt.templates.leftside_text = function (start, end, task) {
		var state = gantt.getState(),
			modes = gantt.config.drag_mode;

		if (state.drag_id == task.id) {
			if (state.drag_mode == modes.move || (state.drag_mode == modes.resize && state.drag_from_start)) {
				return dateToStr(start);
			}
		}
		return "";
	};
	gantt.templates.rightside_text = function (start, end, task) {
		var state = gantt.getState(),
			modes = gantt.config.drag_mode;

		if (state.drag_id == task.id) {
			if (state.drag_mode == modes.move || (state.drag_mode == modes.resize && !state.drag_from_start)) {
				// return dateToStr(gantt.roundDate(end));
				return dateToStr(end);
			}
		}
		return "";
	};
	
	gantt.addCalendar({
		id: "fullday",
		worktime: {
			hours: ["0:00-23:59"],
			days: [1, 1, 1, 1, 1, 1, 1]
		}
	});
        gantt.templates.timeline_cell_class = function (task, date) {			
            if (!gantt.isWorkTime({date: date, unit:  "day"}))
                return "week_end";
            return "";
        };
        gantt.templates.resource_cell_class = function(start_date, end_date, resource, tasks){
			let css = [];
			let shedukedTask = _.filter(tasks, { 'type': "tasks", 'scheduled': true })
            css.push("resource_marker");
            let n = 0 ;
            _.forEach(shedukedTask, function(value) {					
				n += getResourseDayLoad(start_date, value.start_date, value.end_date, value.duration )               
			});
            if (n == 8) {
                css.push("workday_ok");
            } 
			else if ( 0 < n && n < 8) {
                css.push("workday_not_ful");
            }			
			else if ( n > 8){
                css.push("workday_over");
            }
            return css.join(" ");
        };
        gantt.templates.resource_cell_value = function(start_date, end_date, resource, tasks, assignments){
            let html = ""
			let tasksIds = "data-recource-tasks='" + JSON.stringify(tasks.map(function (task) {
				return task.id
			})) + "'";
			let resourceId = "data-resource-id='" + resource.id + "'";
			let dateAttr = "data-cell-date='" + gantt.templates.format_date(start_date) + "'";
            if(resourceMode == "hours"){
				let shedukedTask = _.filter(tasks, 'scheduled')
                var n = 0 ;				
                _.forEach(shedukedTask, function(value) {					
				   n += getResourseDayLoad(start_date, value.start_date, value.end_date, value.duration )               
                });	
				if (n > 0) {html += n;}	
                
            } else{
                html = tasks.length;
            }           
            return "<div " + tasksIds + " " + resourceId + " " + dateAttr + ">" + html + "</div>";
        };
		gantt.attachEvent("onGanttReady", function() {
		let radios = [].slice.call(gantt.$container.querySelectorAll("[name='resource-mode']"));
		radios.forEach(function(radio) {
			gantt.event(radio, "change", function(e) {
				radios.forEach(function(item) {
					item.parentNode.classList.toggle("active", e.target === item || e.target.value === item.value);
				});
				if (this.checked) {
					resourceMode = this.value;
					gantt.getDatastore(gantt.config.resource_store).refresh();
				}
			});
		});
	});
    },
    // and Gantt Templates
	
    $_initGanttEvents: function() {		
		let self = this;
		gantt.templates.tooltip_date_format = gantt.date.date_to_str("%j.%m.%Y %H:00");	
		gantt.attachEvent("onGanttReady", function () {
			var tooltips = gantt.ext.tooltips;
			function getResourseDayLoad(today, task_start, task_and, task_duration){
				function calculateLoadForDay(taskStart, taskDuration, andOfDay){	
					let dayFreeTime = andOfDay - taskStart.hour() ;	
					return dayFreeTime > taskDuration ? taskDuration : dayFreeTime					 
				}
				let workHour = 8;
				let andOfkDay = 16;				
				let forDay = dayjs(today);
				let taskStart = dayjs(task_start)
				let dayStartHour = taskStart.hour(8)	
				let taskAnd = dayjs(task_and)				
				var res = 0;				
				if(forDay.isSame(taskStart , 'day')){					
					if (task_duration <= workHour && taskStart.isSameOrBefore(dayStartHour, 'day')) {
						// console.log("v1");
						res = calculateLoadForDay(taskStart, task_duration, andOfkDay)						
					}
					else if (task_duration > workHour && taskStart.isSameOrBefore(dayStartHour, 'day')) {						
						res = calculateLoadForDay(taskStart, task_duration, andOfkDay)
						// console.log("v2");
					}
					else  {
						// console.log("v3");
						let andOfworkDay = taskStart.hour(andOfkDay)
						let st = andOfworkDay.format('HH')
						let end = taskStart.format('HH')
						let brekf = taskStart.hour(13)
						taskStart.isBefore(brekf, 'hour') ? res += st -end -1 :	res += st -end	 
					}
				}
				else if(forDay.isSame(taskAnd , 'day')){
					if (taskStart.isSameOrBefore(dayStartHour, 'hour')){
						let t = task_duration - (parseInt(task_duration/workHour)*workHour)
						  t>0? res=t :res=workHour;
						// console.log("v4");					
					}
					else {	
						// console.log("v5");					
						let andOfworkDay = taskStart.hour(andOfkDay);
						let st = andOfworkDay.format('HH');
						let end = taskStart.format('HH');											
						let firstDayTime = st-end;
						let tmpDuration = task_duration - firstDayTime;	
						tmpDuration%workHour !=0 ? res = tmpDuration%workHour :	res = workHour;
					}
				}
				else {
					// console.log("v6");
					res = workHour}
				return res;
			}			
			gantt.templates.tooltip_text = function (start, end, task) {
				var store = gantt.getDatastore("resource");				
				var assignments = task[gantt.config.resource_property] || [];				
				var owners = [];
				assignments.forEach(function (assignment) {
					 var owner = store.getItem(assignment)					
					owners.push(owner.text);
				});				
				return "<b> " + task.text + "</b><br/>" +
					"<b>Відповід.: </b>" + owners.join(",") + "<br/>" +
					"<b>Початок:</b> " + gantt.templates.tooltip_date_format(start)  + "<br/>" +
					"<b>Кінець:</b> " + gantt.templates.tooltip_date_format(end);
			};
			
			tooltips.tooltipFor({
				selector: ".gantt_resource_marker",
				html: function (event, node) {
					var dataElement = node.querySelector("[data-recource-tasks]");
					var ids = JSON.parse(dataElement.getAttribute("data-recource-tasks"));
					var date = gantt.templates.parse_date(dataElement.getAttribute("data-cell-date"));
					var resourceId = dataElement.getAttribute("data-resource-id");
					var relativePosition = gantt.utils.dom.getRelativeEventPosition(event, gantt.$task_scale);
					var store = gantt.getDatastore("resource");
					var html = [
						"<b>" + store.getItem(resourceId).text + "</b>" + ", " + gantt.templates.tooltip_date_format(date),
						"",
						ids.map(function (id, index) {							
							var task = gantt.getTask(id);
							var assignenment = gantt.getResourceAssignments(resourceId, task.id);
							// console.log(assignenment);												
							var worktime = getResourseDayLoad(date, assignenment[0].start_date, assignenment[0].end_date, assignenment[0].duration );				
							var amount = "";
							var taskIndex = (index + 1);
							if (assignenment[0]) {
								amount = " (" + worktime + " h) ";
							}
							return taskIndex + ": " + amount + task.text;
						}).join("<br>")
					].join("<br>");
					return html;
				}
			});			
			function getResourceAssignments(resource, store) {
			var assignments = [];
			if (store.hasChild(resource.id)) {
				store.eachItem(function (res) {
					assignments = assignments.concat(gantt.getResourceAssignments(res.id));
				}, resource.id)
			} else {
				assignments = gantt.getResourceAssignments(resource.id)
			}
			return assignments;
		}
		})
	gantt.attachEvent("onBeforeGanttRender", function(){
		// console.log("onBeforeGanttRender"  )
		let range = gantt.getSubtaskDates();
		let scaleUnit = gantt.getState().scale_unit;
		if(range.start_date && range.end_date){
			gantt.config.start_date = gantt.calculateEndDate(range.start_date, -4, scaleUnit);
			gantt.config.end_date = gantt.calculateEndDate(range.end_date, 5, scaleUnit);
		}
	});	
	gantt.attachEvent("onBeforeTaskUpdate", function(id, task){			
			return true;
	});		
	gantt.attachEvent("onAfterTaskUpdate", function(id, task){			
		task.updateSourceWs = false;					
	});	
	var filterValue = "";
	var delay;	
	gantt.$filterOnClick = function(e){
		let el = gantt.$root.querySelector("[data-text-filter]");
		el.focus();		
	}
	gantt.$doFilter = function(value){
		filterValue = value;
		clearTimeout(delay);
		delay = setTimeout(function(){
			gantt.render();
			gantt.$root.querySelector("[data-text-filter]").focus();
		}, 200)	
	}
		gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
			function filterByText(task){				
				if(!filterValue) {return true;}
				else {
					let findInId = false;
					var normalizedText = task.text.toLowerCase();					
					var normalizedValue = filterValue.toLowerCase();
					let findInText = normalizedText.indexOf(normalizedValue) > -1;
					if (task.taskId){findInId = task.taskId.indexOf(normalizedValue) > -1;}
					return findInText || findInId ? true : false;					 
				}
			}	
			if(self.$props.displaytask == "all"){
				return filterByText(task)
			}
			if(self.$props.displaytask == "notSheduled"){
				if(!task.scheduled && filterByText(task))
				{return true}
			}
			if(self.$props.displaytask == "notEvaluated"){
				if(!task.filledCustomFields && filterByText(task))
				{return true}
			}
			return false;			
		});		
		gantt.attachEvent("onGanttRender", function(){
			gantt.$root.querySelector("[data-text-filter]").value = filterValue;		
			
		})		
		gantt.attachEvent("onEmptyClick", function (e){
			self.$root.$emit('empty-click', e);
			//any custom logic here
		});  	
		gantt.attachEvent("onRowDragEnd", function(id, target){			
			console.log("onRowDragEnd")
			if (id.length && target.length ) {
				let task  = JSON.parse(JSON.stringify(gantt.getTask(id)));
				task.start_date = dayjs(task.start_date).format('YYYY-MM-DD HH:mm:ss'); 
				task.end_date = dayjs(task.end_date).format('YYYY-MM-DD HH:mm:ss'); 								
				self.$emit(`task-updated`, id, "rowDrag", task);
			}			
		});
		gantt.attachEvent("onBeforeTaskDrag", function(id, mode, e){
			console.log("onBeforeTaskDrag")	
			let task = gantt.getTask(id);		
			self.$root.$emit('before-taskdrag', task);
			return true;
		});
		gantt.attachEvent("onAfterTaskDrag", function(id, mode, e){
			console.log("onAfterTaskDrag")
			// let task = gantt.getTask(id);
			self.$root.$emit('after-taskdrag', task);
		});
      if (!gantt.$_eventsInitialized) {
        gantt.attachEvent('onTaskSelected', (id) => {
          let task = gantt.getTask(id);
          this.$root.$emit('task-selected', task);
        });
        gantt.attachEvent('onTaskIdChange', (id, new_id) => {			
          if (gantt.getSelectedId() == new_id) {
            let task = gantt.getTask(new_id);
            this.$root.$emit('task-change', task);			
          }
		  gantt.refreshTask(new_id);
		  gantt.selectTask(new_id);
        });
		// gantt.attachEvent("onContextMenu", function (taskId, linkId, event) {	
		// 	if (taskId) {
		// 		self.selectedTask = gantt.getTask(taskId);
		// 		self.menu.showAt(event);

		// 	} else if (linkId) {
		// 		self.menu.showAt(event);
		// 	}
		// 	if (taskId || linkId) {
		// 		return false;
		// 	}
		// 	return true;
		// });
		gantt.attachEvent("onBeforeLightbox", function(id) {
			let task = gantt.getTask(id);			
			if(task.$new){				
				self.$root.$emit('on_task_create', task);
				return false;
			}
			else {
				self.$root.$emit('on_task_edit', task);				
				return false;
			}	
		});
        gantt.$_eventsInitialized = true;
      }
	  gantt.$resourcesStore.attachEvent("onParse", function() {
			var people = [];
			gantt.$resourcesStore.eachItem(function(res) {
				if (!gantt.$resourcesStore.hasChild(res.id)) {
					var copy = gantt.copy(res);
					copy.key = res.id;
					copy.label = res.text;
					copy.unit = "hours";
					people.push(copy);
				}
			});
			gantt.updateCollection("people", people);	
		});
    },
	groupTaskBy: function(option){		
		if (option == "byuser") {			
			let groups = gantt.$resourcesStore.getItems().map(function(item){
				let group = gantt.copy(item);
				group.group_id = group.id;
				group.id = gantt.uid();
				return group;
			});			
			gantt.groupBy({
				groups: groups,
				relation_property: gantt.config.resource_property,
				group_id: "group_id",
				group_text: "text",
				delimiter: ", ",
				default_group_label: "Не розподілені"
			});
		} else {		
			gantt.groupBy(false);
		}
    },
	sortTaskBy(field){
		gantt.sort(field, false);
	},
	initResourcesStore(){
		gantt.$resourcesStore = gantt.createDatastore({
			name: gantt.config.resource_store,
			// fetchTasks: true, 
			type: "treeDatastore",
			initItem: function (item) {
				item.parent = item.parent || gantt.config.root_id;
				item[gantt.config.resource_property] = item.parent;
				item.open = true;
				return item;
			}
		});
		gantt.$resourcesStore.attachEvent("onAfterSelect", function(id) {
			console.log("onAfterSelect")
			gantt.refreshData();
		});
	},
	$_initDataProcessor: function() {
      if (!gantt.$_dataProcessorInitialized) {
        gantt.createDataProcessor((entity, action, data, id) => { 
          this.$emit(`${entity}-updated`, id, action, data);
        });
        gantt.$_dataProcessorInitialized = true;
      }
    },
	//start custom metods
	reload:function(newTask, newResors){			
		gantt.$resourcesStore.clearAll();		
		gantt.clearAll();
		gantt.$resourcesStore.parse(newResors);
		gantt.parse(this.$props.tasks);	
		 this.groupTaskBy(this.$props.groupby);	
	},
	refreshTask:function(id){	
		// gantt.refreshTask(id);
		// gantt.parse(this.$props.tasks);
		// gantt.refreshData();
	},
	refreshGantt(){
		gantt.refreshData();
	},
	toogteFilter(){	
		// gantt.parse(this.$props.tasks);
		gantt.refreshData();
	},
	toogleResourcePanel(option){		
		if (option){
			this.initResourcPanel()
			gantt.resetLayout();
			console.log("CH")
		}
		else {
			gantt.config.layout = {
				css: "gantt_container",
				rows:[
					{
					cols: [
						{
						// the default grid view  
						view: "grid",  
						scrollX:"scrollHor", 
						scrollY:"scrollVer"
						},
						{ resizer: true, width: 1 },
						{
						// the default timeline view
						view: "timeline", 
						scrollX:"scrollHor", 
						scrollY:"scrollVer"
						},
						{
						view: "scrollbar", 
						id:"scrollVer"
						}
					]},
					{
						view: "scrollbar", 
						id:"scrollHor"
					}
				]
			}					
			gantt.resetLayout();
			gantt.init(this.$refs.gantt,dayjs(this.setings.from), dayjs(this.setings.to));	
		}	
	},
	updateTaskById: function(id, newdata){			
		let task = gantt.getTask(id);
		if (!newdata.matchWorkTime) {			
			task.calendar_id = "99999999-9999-9999-9999-999999999999";
		}
		else {task.calendar_id = "";}		
		let start_date = new Date(newdata.start_date)	
		let endDate = gantt.calculateEndDate({start_date: start_date, duration: newdata.duration, task:task});	
		task.start_date = start_date;
		task.duration = newdata.duration;
		task.planDuration = newdata.planDuration;
		task.end_date = endDate;
		task.user_id = newdata.user_id;
		task.type = newdata.type;
		task.text = newdata.text;
		task.description = newdata.description;
		task.descriptionHtml = newdata.descriptionHtml;
		task.matchWorkTime = newdata.matchWorkTime;			
		gantt.updateTask(id,task);
		gantt.autoSchedule(id);
		gantt.refreshTask(id);
		
	},
	setTaskSheduled:function (id){
		let task = gantt.getTask(id);
		task.unscheduled = false;
		task.scheduled = true;
		gantt.refreshTask(id);
		// console.log(task);
		// this.reload();
	},
	setTaskId(id, newId){		
		gantt.changeTaskId(id, newId);
	},
	updateNewTask(newTask){		
		let id = newTask.id;
		let task = gantt.getTask(id);
		let start_date = new Date(newTask.start_date)
		let endDate = gantt.calculateEndDate({start_date: start_date, duration: newTask.duration, task:task});
		task.$new = false;
		task.duration = newTask.duration;
		task.$new = false;
		task.text = newTask.text;
		task.user_id = newTask.user_id;
		task.planningID= newTask.planningID;
		task.start_date = start_date;
		task.type = newTask.type;
		task.end_date = endDate;
		task.planDuration = newTask.planDuration;
		task.calendar_id = newTask.calendar_id; 
		task.matchWorkTime = newTask.matchWorkTime
		task.description = newTask.description
		// console.log(task)
		gantt.updateTask(id,task);
		gantt.updateTaskAssignments(id);
		gantt.refreshTask(id);
		gantt.refreshData();
		gantt.render()
		gantt.showTask(id);
	},
	updateTaskfromWs(newTask){		
		const taskId = newTask.id;		
		let task;
		if(gantt.isTaskExists(taskId)){
			task = gantt.getTask(taskId);
			console.log(task);
		}	
		if(task && newTask.version != task.version){
			let start_date = new Date(newTask.start_date)
			let endDate = gantt.calculateEndDate({start_date: start_date, duration: newTask.duration, task:task});
			let parent = newTask.parent.length > 0 ? newTask.parent : 0;			
			task.start_date = start_date;			
			task.end_date = endDate;
			task.duration = newTask.duration;
			task.version=newTask.version;
			task.type = newTask.type;
			task.parent=parent;
			task.text = newTask.text;
			task.description = newTask.description;
			task.user_id = newTask.user_id;	
			task.calendar_id = newTask.calendar_id; 
			task.matchWorkTime = newTask.matchWorkTime;	
			task.updateSourceWs=true;			
			gantt.updateTask(taskId,task);
			gantt.updateTaskAssignments(taskId);
			gantt.refreshTask(taskId);				
			console.log("update from WS");
		}
        
    },
	createTaskfromWs(newTask){
		// createTask(object task,string parent, [number index] );
		let start_date = new Date(newTask.start_date)
		let endDate = gantt.calculateEndDate({start_date: start_date, duration: newTask.duration, task:task});
		let taskId = gantt.addTask({
			id:newTask.id,
			text:newTask.text,
			start_date:start_date,
			end_date:endDate,
			duration:newTask.duration,
			type:newTask.type,
			description:newTask.description,
			user_id:newTask.user_id,
			calendar_id:newTask.calendar_id,
			matchWorkTime:newTask.matchWorkTime,
			version:newTask.version,
			updateSourceWs:true,
			parent:newTask.parent
		}, newTask.parent, 0);
		
	},
	deleteTaskfromWs(id){
		gantt.deleteTask(id);

	},
	dellLocalTask(id){		
		gantt.deleteTask(id);
		gantt.refreshData();

	},    
	showMessage(text, type){
		gantt.message({
		text:text,
		type:type,
		expire:1500
		});
	},
	zoomIn(){
		gantt.ext.zoom.zoomIn();
	},
	zoomOut(){
		gantt.ext.zoom.zoomOut();
    },
	setZoomLevel(level){
		gantt.ext.zoom.setLevel(level);
    },
	goTooday(){
		// let tooday = dayjs();
		gantt.showDate(new Date());
	},
	collapseTask(){
		gantt.eachTask(function(task){
			task.$open = false;
		});
		gantt.render();
	},
	expandTask(){
		gantt.eachTask(function(task){
			task.$open = true;
		});
		gantt.render();
	},
	setCustomCalendar(id){
		let task = gantt.getTask(id);
		task.calendar_id = "custom";
		gantt.updateTask(id,task);
	}  
  },
   watch:{              
       displaytask:function (val) {
      	gantt.refreshData();	  	
    	},
    },
	computed:{
		
	},
  mounted: function () {  
	// this.initContextMenu();  
	this.initGanttConfig();
	if(this.showResorsPanel){
		this.initResourcPanel()
	}		
	this.initGanttTemplates();
	this.initZooming();
	this.initResourcesStore();	
	this.$_initDataProcessor();
	this.$_initGanttEvents(); 
	// gantt.init(this.$refs.gantt);
	gantt.init(this.$refs.gantt, dayjs(this.setings.from), dayjs(this.setings.to));
    gantt.$resourcesStore.parse(this.$props.resources);
	gantt.parse(this.$props.tasks);
	this.groupTaskBy(this.$props.groupby);	
	this.sortTaskBy("start_date");
  },
  destroyed:function (){
	  var myGantt = Gantt.getGanttInstance(); 
	  myGantt.destructor();
  }
}
</script>

<style>
    @import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
    /* @import "~dhx-suite-package/codebase/suite.css"; */
	.expired {
			border: 2px solid #EC7063;
			color: #EC7063;
			background: #EC7063;
		}
	.expired .gantt_task_progress {
		background: #EC7063;
	}
	.gantt_project {
		border: 2px solid  #ffc107!important;
		color:  #0c0c0c!important;
		background:  #ffc107!important;
	}
	.gantt_project .gantt_task_progress {
		background: #ffc107!important;
	}
	.gantt_project .gantt_task_content {
		color: #0c0c0c!important;
	}
	.new {
			border: 2px solid  #8FCFF9;
			color:  #8FCFF9;
			background:  #8FCFF9;
		}
	.new .gantt_task_progress {
		background: #8FCFF9;
	}
	.pending {
			border: 2px solid#1E9FF2;
			color: #1E9FF2;
			background: #1E9FF2;
		}
	.pending .gantt_task_progress {
		background: #1E9FF2;
	}
	.in_progres {
			border: 2px solid #1E9FF2;
			color: #1E9FF2;
			background: #1E9FF2;
		}
	.in_progres .gantt_task_progress {
		background: #1E9FF2;
	}
	.completed {
			border: 2px solid #28B463;
			color: #28B463;
			background: #28B463;
		}
	.completed .gantt_task_progress {
		background: #28B463;
	}
	.deferred {
			border: 2px solid #BB8FCE;
			color: #BB8FCE;
			background: #BB8FCE;
		}
	.deferred .gantt_task_progress {
		background: #BB8FCE;
	}
	.declined {
			border: 2px solid #F5CBA7;
			color: #F5CBA7;
			background: #F5CBA7;
		}
	.declined .gantt_task_progress {
		background: #F5CBA7;
	}
	.project_expired {
			border: 2px solid #EC7063!important;
			color: #EC7063!important;
			background: #EC7063!important;
		}
	.project_expired .gantt_task_progress {
		background: #EC7063!important;
	}	
	.project_new {
			border: 2px solid  #8FCFF9!important;
			color:  #8FCFF9!important;
			background:  #8FCFF9!important;
		}
	.project_new .gantt_task_progress {
		background: #8FCFF9!important;
	}
	.project_pending {
			border: 2px solid#1E9FF2!important;
			color: #1E9FF2!important;
			background: #1E9FF2!important;
		}
	.project_pending .gantt_task_progress {
		background: #1E9FF2!important;
	}
	.project_in_progres {
			border: 2px solid #1E9FF2!important;
			color: #1E9FF2!important;
			background: #1E9FF2!important;
		}
	.project_in_progres .gantt_task_progress {
		background: #1E9FF2!important;
	}
	.project_completed {
			border: 2px solid #28B463!important;
			color: #28B463!important;
			background: #28B463;
		}
	.project_completed .gantt_task_progress {
		background: #28B463!important;
	}
	.project_deferred {
			border: 2px solid #BB8FCE!important;
			color: #BB8FCE!important;
			background: #BB8FCE!important;
		}
	.project_deferred .gantt_task_progress {
		background: #BB8FCE!important;
	}
	.project_declined {
			border: 2px solid #F5CBA7!important;
			color: #F5CBA7!important;
			background: #F5CBA7!important;
		}
	.project_declined .gantt_task_progress {
		background: #F5CBA7!important;
	}
    .gantt_grid_scale .gantt_grid_head_cell,
		.gantt_task .gantt_task_scale .gantt_scale_cell {
			/* font-weight: bold; */
			font-size: 12px;
			color: rgba(0, 0, 0, 0.7);
			/* border-right: 1px solid #000; */
		}

		.resource_marker{
			text-align: center;
		}
		.resource_marker div{
			width: 20px;
			height: 20px;
			line-height: 20px;
			display: inline-block;
			/* background:rgb(85, 83, 83); */
			color: #FFF;
			margin: 3px;
		}
		.resource_marker.workday_ok div {
			border-radius: 15px;
			background: #51c185;
		}
		.resource_marker.workday_not_ful div {
			border-radius: 15px;
			background: #f19e04;
		}

		.resource_marker.workday_over div{
			border-radius: 3px;
			background: #ff8686;
		}

		.folder_row {
			font-weight: bold;
		}

		.highlighted_resource,
		.highlighted_resource.odd{
	
			background-color: rgba(255, 251, 224, 0.6);
		}
	
		.resource-controls .gantt_layout_content{
			padding: 7px;
			overflow: hidden;
		}
		.resource-controls label{
			margin: 0 10px;
			vertical-align: bottom;
			display: inline-block;
			color: #3e3e3e;
			padding: 2px;
			transition: box-shadow 0.2s;
		}

		.resource-controls label:hover{
			box-shadow: 0 2px rgba(84, 147, 255, 0.42);
		}

		.resource-controls label.active,
		.resource-controls label.active:hover
		{
			box-shadow: 0 2px #5493ffae;
			color: #1f1f1f;
		}

		.resource-controls input{
			vertical-align: top;
		}

		.gantt_task_cell.week_end {
			background-color: #e8e8e87d;
		}

		.gantt_task_row.gantt_selected .gantt_task_cell.week_end {
			background-color: #e8e8e87d !important;
		}


		.group_row,
		.group_row.odd,
		.gantt_task_row.group_row{
			background-color: rgba(232, 232, 232, 0.6);
		}

		.owner-label{
			width: 20px;
			height: 20px;
			line-height: 20px;
			font-size: 12px;
			display: inline-block;
			border: 1px solid #cccccc;
			border-radius: 25px;
			background: #e6e6e6;
			color: #6f6f6f;
			margin: 0 3px;
			font-weight: bold;
		}
		.red .gantt_cell, .odd.red .gantt_cell,
		.red .gantt_task_cell, .odd.red .gantt_task_cell {
			background-color: #f3f2e2;
		}

		.green .gantt_cell, .odd.green .gantt_cell,
		.green .gantt_task_cell, .odd.green .gantt_task_cell {
			background-color: #BEE4BE;
		}
		.search_label{
			padding-right: 5px;
		}
		.search {
			height: 28px;
		}
		.gantt_grid_editor_placeholder>div, .gantt_grid_editor_placeholder input, .gantt_grid_editor_placeholder select {
			width: 80px;
			font-size: 12px;
		}
		
		
</style>