import Vue from 'vue'
import store from '../store/index';
import Router from 'vue-router'
import Auth from '../pages/Auth';
import Konstruktor from '../pages/Konstruktor';
import testWS from '../pages/testWS';
import test from '../pages/test';
// import 'firebase/auth';
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';




Vue.use(Router)
function storeRouteParametr(to){
  if (to.query.token) {
    store.commit('setToken',to.query.token);
  }
  if (to.query.bitrix) {
    store.commit('setIsBitrix',Boolean(to.query.bitrix));
  }
  if (to.query.dev) {
    store.commit('setIsDev',to.query.dev);
  }
  if (to.query.lang) {
    store.commit('setLang',to.query.lang);
  }
  if (to.query.user_id) {
    store.commit('setUser',to.query.user_id);
  }
  if (to.query.entity_type) {
    store.commit('setEntityType',to.query.entity_type);
  }
  if (to.query.entity_type) {
    store.commit('setEntityID',to.query.entity_id);
  }
  return checkToken()  
  // store.commit('setPathTo',to);
  // console.log(`query = ${to}, from = ${from} `); 
}
function checkToken (){
  return store.getters.getTokenERP ? true : false;
  // return true
}


export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/auth",//:user?/:token?/:bitrix?
            name: "auth",
            component: Auth,
            meta: { title: 'Authorization' },
        },
        {
            path: "/",
            name: "home",
            component: Konstruktor,
            meta: { title: 'Create request' },
            beforeEnter:(to, from, next) => {
              if (to.name !== 'auth' && !storeRouteParametr(to, from)){
                sessionStorage.setItem('redirectPath', to.path);
                console.log(to);
                console.log(from);
                next({name: 'auth'})
              }
              else {
                //const socket = io('http://127.0.0.1:3000', {
                //const socket = io('http://172.20.200.17:8080', {
                // const socket = io('https://nj1.modern-expo.com.ua', {
                //     auth: {
                //       token: store.getters.getTokenERP
                //     }
                //   }); 
                               
                //   Vue.use(VueSocketIOExt, socket);
                next()
              }
            }
        },
        {
            path: "/test",
            name: "test",
            component: test,
            meta: { title: 'Create request' },
            beforeEnter:(to, from, next) => {
              if (to.name !== 'auth' && !storeRouteParametr(to, from)){
                sessionStorage.setItem('redirectPath', to.path);                
                next({name: 'auth'})
              }
              
              else {
                const socket = io('http://127.0.0.1:3000', {
                  //const socket = io('http://172.20.200.17:8080', {
                    auth: {
                      token: store.getters.getTokenERP
                    }
                  }); 
                               
                  Vue.use(VueSocketIOExt, socket);
                next()
              }
            }
        },
        {
            path: "/ws",
            name: "testWS",
            component: testWS,
            meta: { title: 'Create request' },
            beforeEnter:(to, from, next) => {
              if (to.name !== 'auth' && !storeRouteParametr(to, from)){
                sessionStorage.setItem('redirectPath', to.path);
                console.log(to);
                console.log(from);                
                next({name: 'auth'})
              }
              
              else {
                console.log("try conect to WS");  
                const socket = io('http://127.0.0.1:3000', {
                //const socket = io('http://172.20.200.17:8080', {
                  auth: {
                    token: store.getters.getTokenERP
                  }
                }); 
                             
                Vue.use(VueSocketIOExt, socket);
                next()
              } 
            }
        },
        
        
    ],
    //linkActiveClass: "active",
})

