<template>
  <div id="main" >
    <div v-if="this.$store.getters.getIsDev " id="devBanner"> <span @click="goProd()">Dev = {{this.$store.getters.getIsDev}}</span> </div>
    <loader :isVisible="$store.getters.getLoading"></loader>
    
    <router-view></router-view>

    <notifications group="custom-info" position="top right">
      <template slot="body" slot-scope="props">
        <div class="alert alert-primary mb-2">
        <a class="close" @click="props.close"><i class="icon-cross"></i></a>
        <strong>{{props.item.title}}</strong>
        <br>{{props.item.text}}
      </div>
      </template>
    </notifications>

    <notifications group="custom-success" position="top right">
      <template slot="body" slot-scope="props">
        <div class="alert alert-success">
        <a class="close" @click="props.close"><i class="icon-cross"></i></a>
        <strong>{{props.item.title}}</strong>
        <br>{{props.item.text}}
      </div>
      </template>
    </notifications>

    <notifications group="custom-error" position="top right">
      <template slot="body" slot-scope="props">
        <div class="alert alert-warning mb-2">
        <a class="close" @click="props.close"><i class="icon-cross"></i></a>
        <strong>{{props.item.title}}</strong>
        <br>{{props.item.text}}
      </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import Loader from "./pages/Loader.vue";

export default {
  name: 'App',
  data () {
    return {}
  },
  components: {
    Loader,
  },
  created() {
    
  },
  methods:{
    goProd(){
      this.$store.commit('setIsDev',null);
      localStorage.removeItem('dev'); 
      this.logout()
    }
  },
  mounted () {
          localStorage.removeItem('loginError')
        },
}
</script>

<style>

  #devBanner span {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1000;
    background-color: chartreuse;
    font-size: large;
    cursor: pointer;
}

</style>
