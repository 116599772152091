<template lang="pug">
	.input-group.date
		.input-group-prepend
			span.input-group-addon
				span.icon-calendar
		input.form-control.col-md-4(type="text", v-model="value", :autocomplete="schema.autocomplete", :disabled="disabled", :placeholder="schema.placeholder", :readonly="schema.readonly", :name="schema.inputName", :id="getFieldID(schema)")
</template>

<script>
/* global $ */
import abstractField from "../abstractField";
import { defaults } from "lodash";
import dateFieldHelper from "../../utils/dateFieldHelper";

let inputFormat = "DD.MM.YYYY HH:mm:ss";

export default {
	mixins: [abstractField],
	data() {
            return{
				options:{
					format: "DD.MM.YYYY",
					locale:"uk",
					icons: {
						time: "icon-android-time",
						date: "icon-data",
						up: "icon-trending_up",
						down: "icon-trending_down",
						previous: "icon-previous",
						next: "icon-next",
						today: "icon-birthday-cake",
						clear: "icon-clear_all",
						close: "icon-android-close"
					}
				}
			}
		},
	methods: {
		getDateFormat() {
			if (this.options && this.options.format) return this.options.format;
			else return inputFormat;
		},

		...dateFieldHelper
	},

	mounted() {
		this.$nextTick(function() {
			if (window.$ && window.$.fn.datetimepicker) {
				let input = this.$el.querySelector(".form-control");
				$(this.$el)
					.datetimepicker(
						defaults(this.options || {}, {
							format: inputFormat
						})
					)
					.on("dp.change", () => {
						this.value = input.value;
					});
			} else {
				console.warn(
					"Bootstrap datetimepicker "
				);
			}
		});
	},

	beforeDestroy() {
		if (window.$ && window.$.fn.datetimepicker) {
			$(this.$el)
				.data("DateTimePicker")
				.destroy();
		}
	}
};
</script>


<style lang="scss">
.input-group.date {
	.input-group-addon {
		margin: 5px;
		background-color: #fff;
		border:none;
	}
	input {
		z-index: 0;
		min-width: 120px;
	}
	// span.input-group-addon {
	// 	position: absolute;		
	// 	right: 15px;
	// 	top: 7px;
	// 	background: none;}
	span.icon-calendar {
		font-size:24px ;
		
	}
}
</style>
