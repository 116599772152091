<template >
	<div>	
		<a class="label-link" v-if="schema.link" :id="getFieldID(schema)" :href="`${schema.link}`" :class="schema.fieldClasses" v-attributes="'label'" target="_blank">{{schema.link_text}}</a> 
		<span :id="getFieldID(schema)" :class="schema.fieldClasses" v-attributes="'label'">{{ value }}</span> 
	</div>
	
</template>

<script>
import abstractField from "../abstractField";

export default {
	mixins: [abstractField]
};
</script>

<style lang="scss">
.vue-form-generator .field-label span {
	display: block;
	width: 100%;
	// margin-left: 12px;
	font-weight: bold;
}
.label-link {
	font-size: 1.1rem;
	font-weight: 600;
	color: #2d79ca;
}
a.label-link:hover{
	color: #9dc3ec;
}
.field-labelHtml a {
	color: #2d79ca;
}
</style>
