<template>
    <div class="chat-container container" ref="chat">      
      <div class="messaging">
         <!-- <button @click="goto('bt')">Div 1</button> -->
            <div class="inbox_msg">
              <!-- <div class="inbox_people">                
                <div class="inbox_chat">
                  <div class="chat_list active_chat">
                    <div class="chat_people">
                      <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                      <div class="chat_ib">
                        <h5>Sunil Rajput <span class="chat_date">Dec 25</span></h5>
                        <p>Test, which is a new approach to have all solutions 
                          astrology under one roof.</p>
                      </div>
                    </div>
                  </div>
                  <div class="chat_list">
                    <div class="chat_people">
                      <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                      <div class="chat_ib">
                        <h5>Sunil Rajput <span class="chat_date">Dec 25</span></h5>
                        <p>Test, which is a new approach to have all solutions 
                          astrology under one roof.</p>
                      </div>
                    </div>
                  </div>
                  <div class="chat_list">
                    <div class="chat_people">
                      <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                      <div class="chat_ib">
                        <h5>Sunil Rajput <span class="chat_date">Dec 25</span></h5>
                        <p>Test, which is a new approach to have all solutions 
                          astrology under one roof.</p>
                      </div>
                    </div>
                  </div>
                  <div class="chat_list">
                    <div class="chat_people">
                      <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                      <div class="chat_ib">
                        <h5>Sunil Rajput <span class="chat_date">Dec 25</span></h5>
                        <p>Test, which is a new approach to have all solutions 
                          astrology under one roof.</p>
                      </div>
                    </div>
                  </div>
                  <div class="chat_list">
                    <div class="chat_people">
                      <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                      <div class="chat_ib">
                        <h5>Sunil Rajput <span class="chat_date">Dec 25</span></h5>
                        <p>Test, which is a new approach to have all solutions 
                          astrology under one roof.</p>
                      </div>
                    </div>
                  </div>
                  <div class="chat_list">
                    <div class="chat_people">
                      <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                      <div class="chat_ib">
                        <h5>Sunil Rajput <span class="chat_date">Dec 25</span></h5>
                        <p>Test, which is a new approach to have all solutions 
                          astrology under one roof.</p>
                      </div>
                    </div>
                  </div>
                  <div class="chat_list">
                    <div class="chat_people">
                      <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                      <div class="chat_ib">
                        <h5>Sunil Rajput <span class="chat_date">Dec 25</span></h5>
                        <p>Test, which is a new approach to have all solutions 
                          astrology under one roof.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="mesgs">
                <div class="msg_history">
                    
                  <div   :class="getPostClass(item)" v-for="item, index in messages" :key="index">
                    <div :class="getPostImgClass(item)"> <img :src="item.AUTHOR_AVATAR" alt="sunil"> </div>
                    <div  :class="getPostMsgClass(item)">
                      <div  :class="getPostMsgWidthClass(item)">
                        <p v-html="item.POST_MESSAGE"></p>
                        <span class="time_date">{{item.POST_DATE}}</span></div>
                    </div>
                  </div>

                 
                  <div ref="bt" class="dd"></div>
                </div>
                <div class="type_msg">
                  <div class="input_msg_write">
                    <input type="text" class="write_msg" v-model="newMessage" v-on:keyup.enter="sendMessage" placeholder="Введіть повідомлення" />
                    <button class="msg_send_btn" type="button"  v-on:click="sendMessage"><i class="icon-checkmark2" aria-hidden="true"></i></button>
                  </div>
                </div>
              </div>
            </div>
                      
          
            
          </div>

    </div> 
</template>

<script>

   

export default {
  name: 'chatbt',
  props: {
    messages: {
      type: Array,
      default () {
        return []
      }
    },
    curentUser:{
        type: String,
        default () {
            return ""
        }

    }	
  },
  data() {
	  return {
      newMessage:"",
		  testobg:null,
		  	 
	  }
  },
  methods: {
    goto(refName) {
     
      var element = this.$refs[refName];
      var top = element.offsetTop;
      console.log(element)
      window.scrollTo(0, top);
    },
    sendMessage(){
      // console.log(`msg = ${this.newMessage}`)
      this.$emit(`newmessag`, this.newMessage);
      this.newMessage = "";
    },
	  getPostClass(post){
      if (post.AUTHOR_ID !== this.curentUser){
        return "incoming_msg"
      }
      else return "outgoing_msg"
    },
    getPostMsgClass(post){
      if (post.AUTHOR_ID !== this.curentUser){
        return "received_msg"
      }
      else return "sent_msg"
    },
    getPostMsgWidthClass(post){
      if (post.AUTHOR_ID !== this.curentUser){
        return "received_withd_msg"
      }
      else return "ssmclass"
    },
    getPostImgClass(post){
      if (post.AUTHOR_ID !== this.curentUser){
        return "incoming_msg_img"
      }
      else return "d-none"
    }



	
  },
   watch:{              
      newMessage:function (val){
        this.goto('bt')
      }
    },
  mounted: function () {  
	
	
	
  }
}
</script>

<style>
img{ max-width:100%;}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac{ margin: 20px 0 0;}


.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
 }
 .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg { width: 57%;}
.mesgs {
  float: right;
  padding: 8px 15px 0 5px;
  width: 100%;
}

 .sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {border-top: 1px solid #c4c4c4;position: relative;}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging { padding: 0 0 50px 0;}
.msg_history {
  height: 516px;
  overflow-y: auto;
}
.dd {height: 1px;}
		
</style>