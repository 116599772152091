<template>
  <div class="container-fluid">
       <b-form-group
            id="duration-lable"
            label="newText"
            label-for="duration"
            description=""
            >
            <b-form-input
            id="duration" 
            type="text"
            v-model="newMessages"
            >
            </b-form-input>
                                                
        </b-form-group> 
       <button class="ml-2" @click="sendMessage()">Надіслати</button>
       <ul v-if="messages.length">
           <li v-for="item, index in messages"  :key="index">{{item.time}}:{{item.text}}</li>
       </ul>
<br>
<br>
<br>
       <Mentionable
    :keys="['@']"
    :items="tributeOptions"
    offset="6"
    insert-space
    @open="getDefaultMetUser"
    @search="getMetUser($event)"
   
  >
  <input type="text" class="write_msg w-100 mt-4" v-model="newMessages"  placeholder="Введіть повідомлення" />
        <template #item-@="{ item }">
          <div class="taget-user">
            {{` @${item.name}` }}
            <span class="dim">
              ({{ item.name }})
              <span class="danger" v-if="item.department.length">{{item.department}}</span>

            </span>
          </div>
        </template>
        </Mentionable>

     
  </div>
</template>

<script>
import TreeTable from 'vue-tree-table-component'
import TableHeader from '../components/tree-table/TableHeader.vue'
import Node from '../components/tree-table/Node.vue'
import Leaf from '../components/tree-table/Leaf.vue'
import { Mentionable } from 'vue-mention'
export default {
  name: 'testWS',
  components: {
       TreeTable, TableHeader, Node, Leaf,Mentionable
    },
  data () {
    return {
      tributeOptions:[
        // {
        //   value: 'akryum',
        //   name: 'Guillaume',
        //   missing:'',
        //   avatar:"https://dfdfd"

        // },
        // {
        //   value: 'Eduardo778',
        //   firstName: 'Eduardo',
        //   missing:'20.02.2022'
        // },
        // {
        //   value: 'atinux',
        //   firstName: 'Sébastien',
        //   missing:''
        // },
      ],
      newMessages:"",     
      messages:[],
       editableFields: {
                    0: ['duration', 'active', 'owner', 'start_date'], 
                    1: ['duration', 'active', 'owner', 'start_date'],              
                },
      columns: [
        {label: 'Назва', id: 'name', width: '1.6', type:"text" }, {label: 'Тривалість', id: 'duration', width: '0.1', type:"number"}, {label: 'Дата початку', id: 'start_date', width: '0.4', type:"date"}, {label: 'Відповідальний', id: 'owner', width: '0.8', type:"selectloader"},  {label: '', id: 'active', width: '0.3', type:"swicher"}
        ],
      // columns: ['name', 'owner', 'start_date', 'duration', 'active'],
      data: [
        {
        id: 10,
        name: "Перша задача",       
        start_date: "2021-11-08",
        owner: {value:{text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},       
        duration:10,
        active:true,
        parrent:"",
        children:[
          {
          id: 101,
          name: "Перша підзадача",        
          start_date: "2021-11-08",
          owner: {value:{text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},        
          duration:5,
          active:true,
          parrent:10
        },
        {
          id: 102,
          name: "Друга підзадача",        
          start_date: "2021-11-08",
          owner: {value:{text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},       
          duration:5,
          active:true,
          parrent:10
        }
        ]
      }, 
      
       {
        id: 20,
        name: "Друга підзадача",        
        start_date: "2021-11-08",
        owner: {value:{text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},      
        duration:5,
        active:true,
        parrent:"",
        children:[
          {
          id: 101,
          name: "Перша підзадача",        
          start_date: "2021-11-08",
          owner: {value:{text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},      
          duration:5,
          active:true,
          parrent:10
        },
        {
          id: 102,
          name: "Друга підзадача",        
          start_date: "2021-11-08",
          owner: {value:{text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},       
          duration:5,
          active:true,
          parrent:10
        }
        ]
      },
       {
        id: 30,
        name: "Третя задача",        
        start_date: "2021-11-08",
        owner: {value:{text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},        
        duration:5,
        active:false,
        parrent:""
        },
         {
        id: 40,
        name: "Четверта задача",        
        start_date: "2021-11-21",
        owner: {value:{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},        
        duration:10,
        active:true,
        parrent:""
        }, 
        {
        id: 50,
        name: "Перша задача555",       
        start_date: "2021-11-08",
        owner: {value:{text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},       
        duration:10,
        active:true,
        parrent:"",
        children:[
          {
          id: 501,
          name: "Перша підзадача555",        
          start_date: "2021-11-08",
          owner: {value:{text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},        
          duration:5,
          active:true,
          parrent:10
        },
        {
          id: 502,
          name: "Друга підзадача55",        
          start_date: "2021-11-08",
          owner: {value:{text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg"},  options:[{text:"Ivanov II", id:"5", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/b4d/logo.png" }, {text:"Petrov US", id:"6", avatar:"https://upload-9c546cf127edb4299f039732f799244c.commondatastorage.googleapis.com/main/1c8/igor.jpg" }, ]},       
          duration:5,
          active:true,
          parrent:10
        }
        ]
      }, 
      
      ],
      options: {
        headings: {
          name: 'Назва',         
          start_date: 'Дата початку',
          duration: 'Тривалість',
          active:'',
          owner:''
       },
      editableColumns:['name'],
      groupBy:'parrent',
      // sortable: ['name'],
      // filterable: ['name']
    }

    }
  },
  filters: {  
    
  },
  sockets: {
    connect() {
      console.log('socket connected')
    },
    customEmit(val) {
      console.log(`custom emit ${val}`)
    },
    new_messages(val) {
       this.messages.push(val);
       this.newMessages = "";
    }
  },
  methods: {
    getMetUser(event){
        console.log(event);
        let search = event;
        let taskid = "5084";
        if (search.length){                          
            let url = 'getmentionableuser';
            this.search(search, url, taskid, this)                          
        } 
        
      
      // this.makeRequest("/getusertasks", {DataRequest: DataRequest}, true)       
      //   .then(res => {  
      //     console.log(res);                 
      //   })
    
    },
    search: _.debounce((search, url, taskid, vm) => {
        let DataRequest = {
            "text": search,
            "taskid": taskid                                      
          }        
        vm.makeRequest("/"+url, {"DataRequest": DataRequest})           
          .then(res => { 
            console.log(res);
          // vm.options = res.data.Data  
            });
      }, 700),
     
    getDefaultMetUser(){
      console.log(this.newMessages)
      let DataRequest = {
        taskId:"5084",
        text:this.newMessages,       
      }
      this.makeRequest("/getmentionableuser", {DataRequest: DataRequest}, true)       
          .then(res => {  
            console.log(res);
            this.tributeOptions = res.data.users                 
          })
    },
    sendMessage(){
      this.$socket.client.emit('new_messages', this.newMessages);
    },
    editMessage(req){
      console.log(req)
    },
     isKeyEditable(depth){

        const editableFields = this.editableFields[depth] || []
        // console.log(editableFields)
        return (key) => editableFields.includes(key)
    },
   
   
  },
  created(){
    
  },
  computed: {
  

  },
  // watch:{              
  //      dispalayTask:function (val) {
  //     this.$refs.gnt.toogteFilter();
  //   },
  //   },
  mounted () { 

    
  }

}
</script>

<style>
.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
}
</style>