<template>
    <div class="dfilters">
        <!-- <label>{{label}}</label> -->
        <treeselect ref="treeselect" v-model="value" :multiple="multiple" :options="options" @open="expandNodeBySelectedItem" @close="filterClose" :placeholder="label" :clearable="false" :searchable="searchable" :instanceId="id" :value-consists-of="valueConsistsOf" :limit="limit" :limitText="limitText">
            <div slot="value-label" slot-scope="{ node }">
              <span  v-if="node.raw.shortLabel"><span v-if="node.raw.color" class="icons test" :style="getItemStyle(node.raw)"></span> {{ node.raw.shortLabel }} </span><span v-else><span v-if="node.raw.color" class="icons test" :style="getItemStyle(node.raw)"></span> {{ node.raw.label }} </span>
            </div>
            <div slot="option-label" slot-scope="{ node }">
              <span class="option-element"  ><span v-if="node.raw.color" class="icons test" :style="getItemStyle(node.raw)"></span> {{ node.raw.label }} </span>
              </div>          
        </treeselect>
    </div> 
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: 'Dfilters',
    components: { Treeselect },
  props: { 
      id:{
        type: String,
      },
      label:{
        type: String,
      },
       options: {
        type: Array,
        default () {
            return []
        }
    },  
       filterdefault: {       
        default () {
            return []
        }
    },
    searchable:{
        type: Boolean,
      },
       
       multiple: {
        type: Boolean,
        default () {
            return true
        }
    },  
  },
  data() {
	  return {
        value:[],
        valueConsistsOf:"LEAF_PRIORITY",
        limit:3,
        limitText:function (count){
         return `і ще ${count}`
        }
        
	  }
  },
  methods: {
  getItemStyle(value){
    if (value.color){
      let styleObj = {
				'color' : value.color,
        'background-color': value.color,
        'border-radius': "0px"			
			};
			return styleObj;
    }
    else return true
  }	,
	filterClose(value, instanceId){
        let self = this
        let rez = {
            "id": instanceId, 
            "value":value
        } 
        // _.delay(function(text) {
        //  self.$root.$emit('on_dfilter_change', text);
        // }, 1000, rez);      
        this.$root.$emit('on_dfilter_change', rez);
     
        
    },
    expandNodeBySelectedItem () {
      // console.log("open menu");
      const { treeselect } = this.$refs
      function chechId(id, obg){
       
        let res = _.findIndex(obg, function(o) { return o == id; });
        // console.log(res)
        return res > -1 ? true : false;
      }
      if (treeselect && this.value) {
        treeselect.traverseAllNodesByIndex(node => {
          //  console.log(node);
          if (node.isBranch) {
            const shouldExpand = (node.children || []).some(child => chechId(child.id, this.value))
            node.isExpanded = shouldExpand || node.isExpanded
          }
        })
     }
}

     
  },
 watch:{
//    filterdefault: function (val) {
//        this.value = JSON.parse(JSON.stringify(this.props.filterdefault)); 
//    }
},
computed: {

},
mounted () {
    this.value =JSON.parse(JSON.stringify(this.filterdefault)); 
}
}
</script>

<style> 
  .icons {
    height: 15px;
    width: 15px;
    display: inline-block;
    margin-top:5px;
}
.icons-in-options {  
    display: block; 
    margin-top:5px;
    margin-left: 5px;
    padding-left: 10px;
}
.option-element {
  margin-right: 5px;
  padding-right: 10px;
}		
</style>