<template>		
		<div class="cascading-dropdown">
            <div class="form-group">                
                <select v-model="dataselectedLevel1"  class="form-control form-control-lg input-lg">
                    <option value="">{{ selectOptions.noneSelectedText || "&lt;Виберіть значення&gt;" }}</option>
                    <option v-for="(item_obj, item) in inputData" :value="item">{{item}}</option>
                </select>
            </div>
            <div class="form-group">             
                <select :disabled="Level2.length == 0" v-model="dataselectedLevel2"  class="form-control form-control-lg input-lg">
                    <option value="">{{ selectOptions.noneSelectedText || "&lt;Виберіть значення&gt;" }}</option>
                    <option v-for="(item_obj, item) in Level2">{{item}}</option>
                </select>
            </div>
            <div class="form-group"  v-if="nesting > 2">               
                <select :disabled="Level3.length == 0" v-model="dataselectedLevel3"  class="form-control form-control-lg input-lg">
                    <option value="">{{ selectOptions.noneSelectedText || "&lt;Виберіть значення&gt;" }}</option>
                    <option v-for="item in Level3">{{item}}</option>
                </select>
            </div>
			<!-- {{value}}         -->
        </div>
		<!-- v-if="Level3[0].length > 0"     -->
</template>

<script>
import { isObject, isNil, find } from "lodash";
import abstractField from "../abstractField";

export default {
	mixins: [abstractField],
	data() {
		return{
			 data: {
                // "Asia": {
                //     "China": ["Beijing", "Shanghai", "Guangzhou", "Tianjin"],
                //     "India": ["New Delhi", "Mumbai", "Bangalore", "Chennai"],
                //     "Japan": ["Tokyo", "Kyoto", "Nagoya", "Hiroshima"],
                //     "Singapore": ["Singapore"],
                //     "Malaysia": ["Kuala Lumpur", "Johor Bahru", "George Town", "Kota Kinabalu"]
                // },
                // "Europe": {
                //     "Germany": ["Berlin", "Hamburg", "Munich", "Cologne", "Frankfurt", "Stuttgart"],
                //     "UK": ["London", "Birmingham", "Liverpool", "Bristol"],
                //     "France": ["Paris", "Marseille", "Bordeaux", "Toulouse"]
                // }
            },			
            Level2: [],
            Level3: [],
            dataselectedLevel1: "",
            dataselectedLevel2: "",
            dataselectedLevel3: "",
			nesting:null,
			resalt:{},
			sendData:null
			
        	}
		
	},
	computed: {
				
		selectOptions() {
			return this.schema.selectOptions || {};
		},
		inputData() {
			let values = this.schema.values;
			this.data = values;
			return values;
			},
		depth(){
			function traverse(o) {
				var i;
				for (var k in o) {
					i = o[k];
					if (typeof i === 'string') {
					console.log(i);
					} 
					else if (typeof i === 'array') {
					console.log(array);
					}
					else if (typeof i === 'object') {
					traverse(i);
					}
				}
			}		
			let tr = traverse(this.schema.values);		
			Object.keys(this.schema.values).forEach(function(prop) {
				console.log(prop);
			// `prop` is the property name
			// `data[prop]` is the property value
			});
			// return typeof(this.schema.values);
		},	
		// 	if (typeof values == "function") {
		// 		return this.groupValues(values.apply(this, [this.model, this.schema]));
		// 	} else return this.groupValues(values);
		// }
	},
	 mounted () {
		console.log(this.depth);		  
	 },
	watch: {
		
		nesting: function(){
			if (this.nesting > 1){
				this.sendRequest(this.schema.function, this.value);
				this.nesting = 0
			}
		},
       dataselectedLevel1: function() {
            // Clear previously selected values
            this.Level2 = [];
            this.Level3 = [];
            this.dataselectedLevel2 = "";
            this.dataselectedLevel3 = "";
            // Populate list of Level2 in the second dropdown
            if (this.dataselectedLevel1.length > 0) {
                this.Level2 = this.data[this.dataselectedLevel1]
				this.nesting = 1
				// this.resalt.push('level1: ' + this.dataselectedLevel1)			
				// this.value = this.resalt
				 Object.assign (this.resalt, {level1:this.dataselectedLevel1} );
				 this.value = this.resalt
            }
        },
        dataselectedLevel2: function() {
            // Clear previously selected values
            this.Level3 = [];
            this.dataselectedLevel3 = "";
            // Now we have a continent and item. Populate list of Level3 in the third dropdown
            if (this.dataselectedLevel2.length > 0) {
                this.Level3 = this.data[this.dataselectedLevel1][this.dataselectedLevel2]
				// this.resalt.push('level2: ' + this.dataselectedLevel2)	
				this.nesting = 2
				Object.assign (this.resalt, {level2:this.dataselectedLevel2} );		
				this.value = this.resalt
				
            }
        }
    },

	methods: {
		sendRequest(func, data){
			if (func.length > 0)  {
				let send = {func, data}
				this.$root.$emit('cascadeSelect', send);
				console.log(send);
				
			}
		},
		formatValueToField(value) {
			if (isNil(value)) {
				return null;
			}
			return value;
		},

		groupValues(values) {
			let array = [];
			let arrayElement = {};

			values.forEach(item => {
				arrayElement = null;

				if (item.group && isObject(item)) {
					// There is in a group.

					// Find element with this group.
					arrayElement = find(array, i => i.group === item.group);

					if (arrayElement) {
						// There is such a group.

						arrayElement.ops.push({
							id: item.id,
							name: item.name
						});
					} else {
						// There is not such a group.

						// Initialising.
						arrayElement = {
							group: "",
							ops: []
						};

						// Set group.
						arrayElement.group = item.group;

						// Set Group element.
						arrayElement.ops.push({
							id: item.id,
							name: item.name
						});

						// Add array.
						array.push(arrayElement);
					}
				} else {
					// There is not in a group.
					array.push(item);
				}
			});

			// With Groups.
			return array;
		},

		getGroupName(item) {
			if (item && item.group) {
				return item.group;
			}

			throw "Group name is missing! ";
		},

		getItemValue(item) {
			if (isObject(item)) {
				if (typeof this.schema["selectOptions"] !== "undefined" && typeof this.schema["selectOptions"]["value"] !== "undefined") {
					return item[this.schema.selectOptions.value];
				} else {
					// Use 'id' instead of 'value' cause of backward compatibility
					if (typeof item["id"] !== "undefined") {
						return item.id;
					} else {
						throw "`id` is not defined. If you want to use another key name, add a `value` property under `selectOptions` in the schema. ";
					}
				}
			} else {
				return item;
			}
		},

		getItemName(item) {
			if (isObject(item)) {
				if (typeof this.schema["selectOptions"] !== "undefined" && typeof this.schema["selectOptions"]["name"] !== "undefined") {
					return item[this.schema.selectOptions.name];
				} else {
					if (typeof item["name"] !== "undefined") {
						return item.name;
					} else {
						throw "`name` is not defined. If you want to use another key name, add a `name` property under `selectOptions` in the schema. ";
					}
				}
			} else {
				return item;
			}
		}
	}
};
</script>


<style lang="sass">
</style>
