<template lang="pug">
.wrapper(v-attributes="'wrapper'")
	input.form-control.upload(
		:id="getFieldID(schema)",
		type="file",
		:name="schema.inputName",
		@change="onUpload",
		:accept="schema.accept",
		:multiple="schema.multiple",
		:placeholder="schema.placeholder",
		:readonly="schema.readonly",
		:required="schema.required",
		:disabled="disabled", 
		:value="wrappedValue",
		v-attributes="'input'")
	<div class="d-flex justify-content-center" v-if="display">
		<ul class="list-group list-group-horizontal" >
			<li v-for="(file,index) in upload" :key="index" class="list-group-item"  style="width:100%">{{file.name}} </li>
		</ul>
		.remove(title="Видалити", @click="remove" )
	</div>

</template>

<script>
import abstractField from "../abstractField";
import { isFunction } from "lodash";

export default {
	mixins: [abstractField],
	data() {
            return{
				display:false,
				wrappedValue:"",
				upload:[],
				upload1:[]
			}
		},
	methods: {
		onChange($event){
			if (isFunction(this.schema.onChanged)) {
				// Schema has defined onChange method.
				this.schema.onChanged.call(this, this.model, this.schema, $event, this);
			}
		},
		remove() {
			this.upload = [];
			this.value = [];
			this.wrappedValue = "";
			this.display = false;
		},
		onUpload(e){
                let tt = null;
                if (e.target.files.length > 0){
                    for (let i = 0; i < e.target.files.length; i++) {
                        const element = e.target.files[i];
                        const reader = new FileReader();
                        reader.readAsDataURL(element);
                        reader.onload = e => {
								// console.log(element.name)
                            tt = (e.target.result).split(',');                            
                                this.upload.push({name: element.name, data: e.target.result/*tt[1]*/});
                                this.upload1.push({name: element.name, data: tt[1]});
								this.display = true
								this.value = this.upload
                            
                        };                        
                    }
                }
            },
	}
};
</script>

<style lang="scss">
.vue-form-generator .field-upload {
	.wrapper {
		width: 100%;

		input {
		padding-bottom: 35px;
	}
		
	}
	
	
}
.remove {
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAXUlEQVR42u2SwQoAIAhD88vVLy8KBlaS0i1oJwP3piGVg0Skmpq8HjqZrWl9uwCbGAmwKYGZs/6iqgMyAdJuM8W2QmYKpLt/0AG9ASCv/oAnANd3AEjmAlFT1BypAV+PnRH5YehvAAAAAElFTkSuQmCC");
			width: 16px;
			height: 16px;
			margin:20px 0 0 20px;
			font-size: 1.2em;

			// position: absolute;
			right: 0.2em;
			bottom: 0.2em;
			opacity: 0.8;

			&:hover {
				opacity: 1;
				cursor: pointer;
			}
		}
</style>
