<template>
    <div class="row" :class="getRowClass()">
       <div class="cell" style="flex-grow: 0.05;">
         <div  ></div>
       </div>
        <div
            v-for="key in order"
            :key="key"
            :style="{ 'flex-grow': getWidth(key) }"
            class="cell"
        >
          <div class="cel-data d-flex justify-content-center" v-if="isKeyEditable(key)">            
             <input type="text"
                v-if="getDataType(key) == 'text'"
                class="input"                    
                v-model="rowData[key]"
                disabled="disab"
                @change="dataChange"
            >
             <input type="number"
              v-if="getDataType(key) == 'number'"
              class="input"
              :disabled="disab"                    
              v-model.number="rowData[key]"
              @change="dataChange"
            >            
            <vc-date-picker mode="dateTime" 
            :disabled="disab"
            is24hr :minute-increment="30" 
            @input="dataChange" 
            :model-config="modelConfig" 
            :attributes='attributes'
            :disabled-dates='calendarDisabledDates'
            class="inline-block h-full" 
            v-model="rowData[key]" 
            v-if="getDataType(key) == 'date'" >
              <template #default="{ inputValue, inputEvents }">
                <input class="px-3 py-1 border rounded" :value="inputValue" v-on="inputEvents" />
            </template>
            <template #day-popover="{attributes}">
              <div>                                                                                  
                <popover-row
                  v-for="attr, key in attributes[0].customData"
                  :key="key"
                  :attribute="attr">
                  {{ attr.text}} <b-badge pill variant="warning" class="ml-2">{{ attr.duration}}</b-badge>
                </popover-row>
                </div>
              </template>             
            </vc-date-picker>
            <toggle-button
            :disabled="disabled"
            v-if="getDataType(key) == 'swicher'"
            v-model="rowData[key]" 
             @change="dataChange" 
            />

            <multiselect
              v-if="getDataType(key) == 'selectloader' && rowData[key].selectloader" 
              v-model="rowData[key].value"            
              :options="userOptions" 
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :disabled="disab"
              label="text"
              track-by="id"
              :option-height="20"
              :custom-label="customLabel" 
              :show-labels="false"
              @input="dataChange"
              @search-change="findUser"
              >
              <template slot="singleLabel" slot-scope="props"><img class="option__image" :src="props.option.avatar" alt="No photo"><span class="option__desc"><span class="option__title">{{ props.option.text }}</span></span></template>
              <template slot="option" slot-scope="props"><img class="option__image" :src="props.option.avatar" alt="No photo">
                  <div class="option__desc"><span class="option__title">{{ props.option.text }}</span></div>
              </template>
            </multiselect>         
            <multiselect
              v-else-if="getDataType(key) == 'selectloader' && !rowData[key].selectloader" 
              v-model="rowData[key].value"            
              :options="rowData[key].options" 
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :disabled="disab"
              label="text"
              track-by="id"
              :option-height="20"
              :custom-label="customLabel" 
              :show-labels="false"
              @input="dataChange"
              >
              <template slot="singleLabel" slot-scope="props"><img class="option__image" :src="props.option.avatar" alt="No photo"><span class="option__desc"><span class="option__title">{{ props.option.text }}</span></span></template>
              <template slot="option" slot-scope="props"><img class="option__image" :src="props.option.avatar" alt="No photo">
                  <div class="option__desc"><span class="option__title">{{ props.option.text }}</span></div>
              </template>
            </multiselect>
          </div>           
            <div class="text" v-else> 
              <span v-if="descIsLink(rowData)"><a :href="`https://bx.modern-expo.com/company/personal/user/${$store.getters.getCurrentUser.id}/tasks/task/view/${rowData.taskId}/`" target="_blank" rel="noopener noreferrer">{{ rowData[key] }}</a></span>
              <span v-else>{{ rowData[key] }}</span>           
            </div>
        </div>
        <!-- <div @click="deleteNode(rowData.uuid)" class="cell red" :style="{ 'flex-grow': getWidth('actions') }">✖</div> -->
    </div>
</template>

<script>
import { debounce } from "lodash";
import { ToggleButton } from 'vue-js-toggle-button'
import PopoverRow from 'v-calendar/lib/components/popover-row.umd.min'
  export default {
    name: 'Leaf',
    components: {
      PopoverRow,
      ToggleButton
    },
    props: {
      rowData: {
        type: Object,
        default: () => { return {} }
      },
      defaultOrder: {
        type: Array,
        default: () => { return [] }
      },
      isKeyEditable:{
        type: Function,
        default: () => {}
      },
      deleteNode:{
        type: Function,
        default: () => {}
      },
      columns: {
        type: Array,
        default: () => { return [] }
      }
    },
    data() {
      return {
        modelConfig: {
          type: 'string',
          mask: 'YYYY-MM-DD HH:mm:ss', // Uses 'iso' if missing        
        },
        disabled:false,
        userOptions:[],
        calendarDisabledDates:null,
        calendarObg:[]
      }
    },
    computed: {
      order: function(){
        // console.log(this.defaultOrder.slice(0,9))
        return this.defaultOrder.slice(0,9)
      },
      disab: function(){
        return !this.$props.rowData.active;
      },
      selectOptionst(){

      },
      attributes() {
        return [        
          ...this.calendarObg.map(task => ({
            dates: task.dates,
            dot: {
              color: task.color,
              class: task.isComplete ? 'opacity-75' : '',
            },
            popover: {
              label: task.description,
            },         
            customData: task.description,
          })),
        ];
      },
    },
    methods: {
      getDataType(colName){
        let el = _.findIndex(this.columns, function(o) { return o.id == colName; });
        return this.columns[el].type;
      },
      getWidth(colName){
        let el = _.findIndex(this.columns, function(o) { return o.id == colName; });
        
        // return this.columns.find((col) => col.id == columnId ).width
        return this.columns[el].width;
      },
      customLabel ({ text }) {
      return `${text}`
    },
    descIsLink(row){      
      return row.taskId != "" ? true : false;
    },
    getRowClass(){
      let cl= "";
      if (this.$attrs.depth > 0){cl += ` child-${this.$attrs.depth}`}
      if (!this.rowData.active) {cl += ' not-active' }
      return cl;
    },
    dataChange(){
      this.getCalendarData()
      this.$root.$emit('template-row-change', this.rowData);
    },
    getCalendarData(){
      if (!this.disab)  {  
        let DataRequest = { 
          user_id:this.$props.rowData.owner.value.id,
          task_id:null
          }
          this.makeRequest("/getPlannedCalendar", {DataRequest: DataRequest})
          .then(res => {       
            this.calendarDisabledDates  = res.data.data.disabledDates;    
            this.calendarObg = res.data.data.taskData;
                                    
          })  
      }
            
    },
    findUser(query){
      this.search(query, this) 
    },
    search: debounce((search,  vm) => { 
      let self = vm
      let DataRequest = {
        "ValueField": search                                 
      }           
      var p = {
        'catalog':'Bitrix.Справочник.Users',
        'planer':true
      }
    self.getFielfOptions("/getdatavaluefield", {"DataRequest": DataRequest, "parametrs": p})
    .then(res => {       
              if (res.data.Data && res.data.Data.length){
                self.userOptions = res.data.Data;
                
              }        
        });
        }, 1000)
    ,
      
    },
    watch:{
      //  rowData: {
      //     handler:  function(){ 
      //       this.$root.$emit('template-row-change', this.rowData);
      //       // console.log(this.rowData)
      //     },     
      //     deep: true
      // },
    },
    mounted(){      
        if (this.$props.rowData){
          for (let el in this.$props.rowData )
            if (el == 'owner')            
              {this.userOptions = this.$props.rowData[el].options}
        }        
        this.getCalendarData()
    },
    beforeMount() {         
    if(this.$props.rowData.disabled){
      this.disabled = this.$props.rowData.disabled 
    }

  }
  }
</script>

<style scoped>
    input {
        width: 100%;
        display: inline-flex;
        border: none
    }

    .red{
        color: red;
        text-align: right;
        padding-right: 0.9rem;
    }
    .calendar-btn {
      border: none;
      background: none;
      width: 32px;
    }
    .cel-data .multiselect__tags {
      border: 0px;
    }
    .child-1{
      border: 0px;
      padding-left: 10px;
    }
    .child-1 .text{     
      padding-left: 30px;
    }
    .not-active {
      background-color: rgb(212, 205, 205);
    }
    /* .cell {
      padding: 1px 5px;
    } */

 .table > :not(caption) > * > * {
   padding: 0px;
 }
</style>
